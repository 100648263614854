import React, { useState, useEffect } from "react";

import { useFonts } from "../../context/FontsContext";
import { useAuth } from "../../context/AuthContext";

import FontList from "./components/FontList";
import Slider from "./components/Slider";
import DownloadBar from "./components/DownloadBar";

const Fonts = () => {
  const {
    fonts,
    fontsSelected,
    setFontsSelected,
    fontsOwned,
    tokens,
    isCollecting,
    setIsCollecting,
    redeemFonts,
    removeFont,
  } = useFonts();
  const { isNew, isPremium } = useAuth();

  const [theseFonts, setTheseFonts] = useState(fonts);

  useEffect(() => {
    const adjustedFonts = fonts.map((font) => {
      font.selected = fontsSelected.indexOf(font._id) > -1;

      // if the font is already owned by the user
      font.owned = fontsOwned.indexOf(font._id) > -1;

      return font;
    });

    setTheseFonts(adjustedFonts);
  }, [fonts, fontsSelected, fontsOwned]);

  const toggleFont = (fontId) => {
    if (isPremium) {
      toggleActivation(fontId);
    } else {
      // toggle the collecting mode
      setIsCollecting(true);

      // toggle the selection of this font
      toggleSelection(fontId);
    }
  };

  const toggleActivation = (fontId) => {
    // either activate or deactivate this font for premium user

    // if the font is already owned
    if (fontsOwned.indexOf(fontId) > -1) {
      // deactivate it
      removeFont(fontId);
    } else {
      // font is not owned, so activate it
      redeemFonts(fontId);
    }
  };

  const toggleSelection = (fontId) => {
    // if the font was already selected
    if (fontsSelected.indexOf(fontId) > -1) {
      // deselect it
      const newFontsSelected = fontsSelected.filter(
        (selectedFont) => selectedFont !== fontId,
      );
      setFontsSelected(newFontsSelected);
    } else if (tokens - fontsSelected.length > -1) {
      // select the font, as long as there are sufficient tokens

      // use Set to prevent duplicates
      const newFontsSelected = Array.from(new Set([...fontsSelected, fontId]));
      setFontsSelected(newFontsSelected);
    }
  };

  return (
    <>
      <Slider />
      {isNew ? <DownloadBar /> : null}
      <FontList
        fonts={theseFonts}
        fontAction={toggleFont}
        disabled={tokens - fontsSelected.length === 0 && isCollecting}
        isPremium={isPremium}
      ></FontList>
    </>
  );
};

export default Fonts;
