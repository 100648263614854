import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";

import { useAuth } from "./AuthContext";

import { getAllFonts, redeemFont, deactivateFont } from "../utils/api";

const FontsContext = React.createContext();

const FontsProvider = (props) => {
  const { user, isPremium, invite, refreshUser } = useAuth();

  const [fonts, setFonts] = useState([]);
  const [fontsSelected, setFontsSelected] = useState([]); // the fonts in the drawer in the sidebar
  const [fontsOwned, setFontsOwned] = useState([]); // the fonts the user already owns
  const [tokens, setTokens] = useState(0);

  const [isCollecting, setIsCollecting] = useState(false);

  const [referrals, setReferrals] = useState(0);
  // total referrals needed for free unlimited membership
  const referralGoal = 10;

  const [emailsToInvite, setEmailsToInvite] = useState([]);
  const [inviteSuccess, setInviteSuccess] = useState(false);

  useEffect(() => {
    getFonts();
  }, []);

  useEffect(() => {
    // user logged in
    if (user) {
      setFontsOwned(user.fontsOwned);
      setTokens(user.tokensAvailable);

      // check if the referral is active and is a premium user
      if (user.referrals) {
        setReferrals(
          user.referrals.filter(
            (referral) =>
              referral.invitee &&
              referral.invitee.isActive &&
              referral.invitee.subscription === "PREMIUM",
          ).length,
        );
      }
    }
    // user logged out
    else {
      setFontsOwned([]);
      setFontsSelected([]);
      setTokens(0);
      setReferrals(0);
    }
  }, [user]);

  const getFonts = async () => {
    // get all the fonts from the API
    const { data } = await getAllFonts();

    setFonts(data.msg);
  };

  const redeemFonts = async (fontsToRedeem) => {
    // array of font IDs
    if (!Array.isArray(fontsToRedeem)) {
      fontsToRedeem = [fontsToRedeem];
    }

    // if not a premium user
    if (!isPremium) {
      // optimistically update the user's fontsOwned
      const newFontsOwned = Array.from(
        new Set([...fontsOwned, ...fontsToRedeem]),
      );
      setFontsOwned(newFontsOwned);

      // optimistically update the user's tokens
      setTokens(tokens - fontsToRedeem.length);

      // deselect the fonts
      const newFontsSelected = fontsSelected.filter(
        (selectedFont) => fontsToRedeem.indexOf(selectedFont) === -1,
      );
      setFontsSelected(newFontsSelected);
    }

    // add all the requests to an array
    const fontRequests = [];

    fontsToRedeem.forEach((font) => {
      fontRequests.push(redeemFont(font));
    });

    try {
      // run all the requests
      // eslint-disable-next-line
      const results = await Promise.all(fontRequests);
    } catch (err) {
      console.error(err);
    }

    // Updates user info from server regardless of if it succeeds
    refreshUser();
  };

  const removeFont = async (fontId) => {
    // optimistically remove from fontsOwned
    const newFontsOwned = fontsOwned.filter(
      (ownedFont) => ownedFont !== fontId,
    );
    setFontsOwned(newFontsOwned);

    try {
      // eslint-disable-next-line
      const results = await deactivateFont(fontId);
    } catch (err) {
      // if there was an error, maybe add the font back into fontsOwned
    }

    // Updates user info from server regardless of if it succeeds
    refreshUser();
  };

  const inviteEmails = async () => {
    const invites = [];

    emailsToInvite.forEach((email) => {
      invites.push(invite(email));
    });

    // wait for all the invites to finish
    // eslint-disable-next-line
    const results = await Promise.all(invites);

    // reset the emails to invite
    setEmailsToInvite([]);

    setInviteSuccess(true);
  };

  return (
    <FontsContext.Provider
      value={{
        fonts,
        fontsOwned,

        fontsSelected,
        setFontsSelected,

        tokens,
        setTokens,

        isCollecting,
        setIsCollecting,

        redeemFonts,
        removeFont,

        referrals,
        referralGoal,
        emailsToInvite,
        setEmailsToInvite,
        inviteEmails,
        inviteSuccess,
        setInviteSuccess,
      }}
      {...props}
    >
      {props.children}
    </FontsContext.Provider>
  );
};

const useFonts = () => useContext(FontsContext);
const Provider = withRouter(FontsProvider);
export { Provider as FontsProvider, useFonts, FontsContext };
