import styled from "styled-components";
import posed from "react-pose";

const PosedExpandable = posed.div({
  closed: {
    height: "0px",
    // delay it 1sec so its in sync with the rest of the drawer things
    transition: { duration: 300, delay: 1000 },
    applyAtStart: { overflow: "hidden" },
    applyAtEnd: { visibility: "hidden", pointerEvents: "none" },
  },
  open: {
    height: "auto",
    transition: { duration: 300, delay: 1000 },
    applyAtStart: { visibility: "visible", pointerEvents: "all" },
    applyAtEnd: { overflow: "visible" },
  },
});

const Expandable = styled(PosedExpandable)`
  overflow: hidden;
  visibility: hidden;
  pointer-events: none;

  display: flex;
  flex-direction: column;

  > * {
    flex-grow: 1;
  }
`;

export default Expandable;
