import React, { useState, useEffect } from "react";
import styled from "styled-components";
import posed from "react-pose";

import Facebook from "./icons/Facebook";
import Instagram from "./icons/Instagram";
import Twitter from "./icons/Twitter";
import Modals from "./Modals";
import { respondTo } from "../styles/styleHelpers";
import { useAuth } from "../context/AuthContext";

const Footer = () => {
  const { setPolicyCallback, setEulaCallback } = useAuth();
  const [modal, setModal] = useState(null);

  useEffect(() => {
    // Passes the ability to fire the modal to AuthContext, so we can use it in the sidebar signup form
    // (for the terms checkbox)
    setPolicyCallback(() => () => {
      changeModal("privacy");
    });

    setEulaCallback(() => () => {
      changeModal("eula");
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (modal) {
      document.addEventListener("keydown", escFunction, false);
    } else {
      document.removeEventListener("keydown", escFunction);
    }
  }, [modal]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeModal = (selectedModal) => {
    setModal(selectedModal);
  };

  const closeModal = () => {
    setModal(null);
  };

  const escFunction = (event) => {
    if (event.keyCode === 27 && modal) {
      closeModal();
    }
  };

  return (
    <FooterContainer>
      <FooterInner>
        <Social>
          <SocialLink
            href="https://www.facebook.com/dollarfontclub"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Facebook />
          </SocialLink>

          <SocialLink
            href="https://www.instagram.com/dollarfontclub"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Instagram />
          </SocialLink>

          <SocialLink
            href="https://www.twitter.com/dollarfontclub"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Twitter />
          </SocialLink>
        </Social>
        <Links>
          <button onClick={() => changeModal("faqs")}>FAQs</button>
          {/* <button onClick={() => changeModal("support")}>Support</button> */}
          <button onClick={() => changeModal("privacy")}>Privacy</button>
          <button onClick={() => changeModal("eula")}>Terms</button>
          <button onClick={() => changeModal("custom")}>
            Looking for custom fonts?
          </button>
        </Links>
        <Copyright>Copyright © 2020 Dollar Font Club.</Copyright>
      </FooterInner>

      <StyledModalContainer
        pose={modal ? "open" : "closed"}
        style={{ pointerEvents: modal ? "auto" : "none" }}
      >
        <Modals modal={modal} closeModal={closeModal} />
      </StyledModalContainer>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  padding-bottom: 100px;
  width: 85%;
  margin: auto;

  ${respondTo("large")} {
    width: 100%;
    margin: 0px;
    margin-right: 230px;
  }
`;

const FooterInner = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${respondTo("large")} {
    flex-direction: row;
    align-items: center;
    margin-right: 7%;
  }
`;

const Social = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 49px;

  ${respondTo("large")} {
    flex-shrink: 0;
    margin-bottom: 0px;
  }
`;

const SocialLink = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  transition: 0.3s ease-in-out;
  box-shadow: 0px 4px 12px rgba(121, 120, 117, 0.25);
  width: 35px;
  height: 35px;
  margin-right: 35px;
  padding: 6px;

  path {
    transition: 0.3s ease-in-out;
  }

  &:hover,
  &:focus {
    path {
      fill: ${(props) => props.theme.colors.green};
    }
  }

  &:last-of-type {
    margin-right: 0px;
  }

  ${respondTo("large")} {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    padding: 5px;
  }

  ${respondTo("xlarge")} {
    width: 40px;
    height: 40px;
    margin-right: 40px;
    padding: 0px;
  }
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 28px;

  button {
    color: ${(props) => props.theme.colors.black};
    transition: 0.3s ease-in-out;
    letter-spacing: 0.02em;
    font-size: ${(props) => props.theme.fontSize};
    line-height: 1.75;
    margin-bottom: 0px;
    transition: 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      color: ${(props) => props.theme.colors.green};
    }

    &:not(:last-child) {
      ${respondTo("medium")} {
        margin-right: 40px;
      }

      ${respondTo("large")} {
        margin-right: 10px;
      }

      ${respondTo("xlarge")} {
        margin-right: 70px;
      }
    }
  }

  ${respondTo("medium")} {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 48px;
  }

  ${respondTo("large")} {
    justify-content: flex-start;
    margin-bottom: 0;

    p {
      line-height: 1.5;
    }
  }

  ${respondTo("xlarge")} {
    p {
      line-height: 1.5;
    }
  }
`;

const Copyright = styled.p`
  text-align: center;
  font-weight: bold;
  margin-bottom: 0px;
  letter-spacing: 0.02em;
  font-size: 13px;
  color: ${(props) => props.theme.colors.black};
`;

const PosedModalContainer = posed.div({
  closed: {
    opacity: 0,
    transition: { duration: 500 },
    applyAtEnd: { visibility: "hidden", pointerEvents: "none" },
  },
  open: {
    opacity: 1,
    transition: { duration: 500 },
    applyAtStart: { visibility: "visible", pointerEvents: "auto" },
  },
});

const StyledModalContainer = styled(PosedModalContainer)`
  position: relative;
  z-index: ${(props) => props.theme.indexes.modal};
`;

export default Footer;
