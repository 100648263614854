import React, { useState } from "react";
import styled from "styled-components";
import { rem } from "polished";

import SettingsIcon from "../../../components/icons/Settings";

const ProjectMenu = ({ edit, handleEdit, handleDelete }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setConfirmDelete(false);
  };

  const handleEditClick = () => {
    toggleMenu();
    handleEdit();
  };

  return (
    <>
      <SettingsMenu className={isOpen ? "open" : "closed"}>
        <SettingsLink onClick={handleEditClick}>
          {edit ? `Cancel` : `Edit`}
        </SettingsLink>
        {confirmDelete ? (
          <SettingsLink onClick={handleDelete}>Confirm delete</SettingsLink>
        ) : (
          <SettingsLink
            onClick={() => {
              setConfirmDelete(true);
            }}
          >
            Delete
          </SettingsLink>
        )}
      </SettingsMenu>
      <SettingsButton
        aria-label="Open options menu for this project"
        onClick={toggleMenu}
      >
        <SettingsIcon />
      </SettingsButton>
    </>
  );
};

export const SettingsMenu = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border: 2px solid ${(props) => props.theme.colors.pumice};
  border-radius: 15px;
  position: absolute;
  top: 50%;
  left: 3rem;
  transform: translateY(-50%);
  display: none;
  flex-direction: column;
  align-items: flex-start;
  padding: ${rem("30px")} ${rem("35px")};
  z-index: 2;
  transition: color 0.2s;

  &.open {
    display: flex;
  }

  &:after,
  &:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(250, 251, 249, 0);
    border-right-color: #fafbf9;
    border-width: 14px;
    margin-top: -15px;
  }
  &:before {
    border-color: rgba(201, 212, 205, 0);
    border-right-color: #c9d4cd;
    border-width: 17px;
    margin-top: -18px;
  }
`;

export const SettingsLink = styled.button`
  font-size: ${(props) => props.theme.fontSize};
  font-weight: 500;
  color: ${(props) => props.theme.colors.grayDark};

  &:not(:last-child) {
    margin-bottom: 14px;
  }

  &:hover {
    color: ${(props) => props.theme.colors.blackLight};
  }
`;

export const SettingsButton = styled.button`
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  svg {
    width: 100%;
    height: 100%;
    fill: ${(props) => props.theme.colors.pumice};
    transition: fill 0.2s;
  }

  &:hover {
    svg {
      fill: ${(props) => props.theme.colors.pumiceDark};
    }
  }
`;

export default ProjectMenu;
