import React from "react";

const Help = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="12"
        cy="12"
        r="12"
        transform="rotate(90 12 12)"
        fill="#FAFBF9"
      />
      <path
        d="M15.52 9.568C15.52 7.68 14.064 6.512 12.32 6.512C10.512 6.512 9.136 7.776 9.136 9.584H10.768C10.768 8.72 11.328 7.968 12.32 7.968C13.248 7.968 13.888 8.672 13.888 9.568C13.888 10.016 13.68 10.4 13.392 10.784L12.144 12.464C11.76 12.976 11.6 13.392 11.6 14.048V14.608H13.232V14.128C13.232 13.872 13.344 13.504 13.52 13.264L14.752 11.536C15.184 10.944 15.52 10.336 15.52 9.568ZM13.312 18V16.256H11.504V18H13.312Z"
        fill="#838B91"
      />
    </svg>
  );
};

export default Help;
