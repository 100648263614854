import styled from "styled-components";

const FormAction = styled.button`
  box-shadow: ${(props) => props.theme.shadow};
  border-radius: 39px;
  background-color: ${(props) => props.theme.colors.gray};
  color: ${(props) => props.theme.colors.grayDark};
  font-size: ${(props) => props.theme.fontSize_xl};
  font-weight: bold;
  padding: 13px 56px;
  transition: 0.3s all ease, 0.15s color ease;

  &:hover,
  &:focus {
    color: white;
    background-color: ${(props) => props.theme.colors.grayDark};
    box-shadow: inset 4px 8px 21px rgba(48, 57, 64, 0.5);
  }
`;

export const FormActionContainer = styled.div`
  margin-top: 46px;
`;

export default FormAction;
