import React from "react";
import styled from "styled-components";

import LogoImage from "../../../components/icons/LogoImage";
import LogoText from "../../../components/icons/LogoText";

import { respondTo } from "../../../styles/styleHelpers";

const FormWrapper = ({ children }) => {
  return (
    <Container>
      <Logo>
        <LogoImageContainer>
          <LogoImage />
        </LogoImageContainer>
        <LogoText />
      </Logo>

      {children}
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.colors.gray};
  border-radius: 15px;
  padding: ${(props) => props.theme.space};
  margin: ${(props) => props.theme.space} 0;
  å ${respondTo("xlarge")} {
    padding: 60px ${(props) => props.theme.space};
  }

  ${respondTo("xxlarge")} {
    padding: 60px 40px;
  }
`;

const Logo = styled.div`
  margin-bottom: ${(props) => props.theme.space};
  display: flex;
  align-items: center;

  ${respondTo("large")} {
    margin-bottom: ${(props) => props.theme.space};
  }
  ${respondTo("xlarge")} {
    margin-bottom: 70px;
  }
`;

const LogoImageContainer = styled.div`
  box-shadow: ${(props) => props.theme.shadow};
  border-radius: 16px;
  margin-right: 21px;
  height: 65px;
  width: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default FormWrapper;
