import React from "react";

const Instagram = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M13.9998 3.06921C17.5598 3.06921 17.9815 3.08254 19.3876 3.14699C20.9048 3.21643 22.3131 3.52032 23.3964 4.60365C24.4798 5.68698 24.7837 7.09531 24.8531 8.61252C24.917 10.0186 24.9309 10.4403 24.9309 14.0003C24.9309 17.5603 24.9175 17.9819 24.8531 19.3881C24.7837 20.9053 24.4798 22.3136 23.3964 23.3969C22.3131 24.4803 20.9048 24.7841 19.3876 24.8536C17.982 24.9175 17.5603 24.9314 13.9998 24.9314C10.4393 24.9314 10.0176 24.918 8.61204 24.8536C7.09482 24.7841 5.68649 24.4803 4.60316 23.3969C3.51983 22.3136 3.21594 20.9053 3.1465 19.3881C3.08261 17.9819 3.06872 17.5603 3.06872 14.0003C3.06872 10.4403 3.08205 10.0186 3.1465 8.61252C3.21594 7.09531 3.51983 5.68698 4.60316 4.60365C5.68649 3.52032 7.09482 3.21643 8.61204 3.14699C10.0181 3.0831 10.4398 3.06921 13.9998 3.06921ZM13.9998 0.666992C10.3787 0.666992 9.92481 0.682548 8.50259 0.746992C6.33593 0.845881 4.4326 1.37643 2.90428 2.90476C1.3765 4.43254 0.845392 6.33531 0.746504 8.50308C0.682059 9.9253 0.666504 10.3792 0.666504 14.0003C0.666504 17.6214 0.682059 18.0753 0.746504 19.4975C0.845392 21.6642 1.37595 23.5675 2.90428 25.0958C4.43205 26.6236 6.33482 27.1547 8.50259 27.2536C9.92481 27.318 10.3787 27.3336 13.9998 27.3336C17.6209 27.3336 18.0748 27.318 19.497 27.2536C21.6631 27.1547 23.567 26.6241 25.0953 25.0958C26.6231 23.568 27.1542 21.6653 27.2531 19.4975C27.3175 18.0753 27.3331 17.6214 27.3331 14.0003C27.3331 10.3792 27.3175 9.9253 27.2531 8.50308C27.1542 6.33642 26.6237 4.43309 25.0953 2.90476C23.5676 1.37699 21.6648 0.845881 19.497 0.746992C18.0748 0.682548 17.6209 0.666992 13.9998 0.666992Z"
          fill="#838B91"
        />
        <path
          d="M13.9997 7.15381C10.2181 7.15381 7.15308 10.2194 7.15308 14.0005C7.15308 17.7816 10.2181 20.8471 13.9997 20.8471C17.7814 20.8471 20.8464 17.7821 20.8464 14.0005C20.8464 10.2188 17.7814 7.15381 13.9997 7.15381ZM13.9997 18.4449C11.5453 18.4449 9.55529 16.4549 9.55529 14.0005C9.55529 11.546 11.5453 9.55602 13.9997 9.55602C16.4542 9.55602 18.4442 11.546 18.4442 14.0005C18.4442 16.4549 16.4542 18.4449 13.9997 18.4449Z"
          fill="#838B91"
        />
        <path
          d="M21.1171 8.48319C22.0007 8.48319 22.7171 7.76685 22.7171 6.8832C22.7171 5.99955 22.0007 5.2832 21.1171 5.2832C20.2334 5.2832 19.5171 5.99955 19.5171 6.8832C19.5171 7.76685 20.2334 8.48319 21.1171 8.48319Z"
          fill="#838B91"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="26.6666"
            height="26.6666"
            fill="white"
            transform="translate(0.666504 0.666992)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Instagram;
