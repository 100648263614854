import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { rgba } from "polished";

import { useFonts } from "../../../context/FontsContext";
import { getDaysUntilNextMonth } from "../../../utils/utils";

import SlideToggle, {
  SlideToggleContainer,
} from "../../../components/SlideToggle";

import Dial from "./Dial";
import Drawer from "./Drawer";
import Expandable from "./Expandable";

import { respondTo } from "../../../styles/styleHelpers";

const FontSelection = ({
  isCollecting,
  toggleCollecting,
  tokens,
  fontsOwned,
  fontsSelected,
  emailsToInvite,
  deselectItem,
  addItem,
  isPremium,
  referralCode,
}) => {
  const {
    fonts,
    redeemFonts,
    referrals,
    referralGoal,
    inviteEmails,
    inviteSuccess,
    setInviteSuccess,
  } = useFonts();

  const [confirmed, setConfirmed] = useState(false);
  const [fontNames, setFontNames] = useState([]);

  const [daysUntilNextMonth, setDaysUntilNextMonth] = useState("");

  useEffect(() => {
    setDaysUntilNextMonth(getDaysUntilNextMonth());
  }, []);

  useEffect(() => {
    // get the names of the fonts, so we know what font-family to show for them
    const newFontNames = fontsSelected.map((fontId) => {
      const thisFont = fonts.find((font) => font._id === fontId);
      return thisFont.name;
    });
    setFontNames(newFontNames);

    setConfirmed(false);
  }, [fontsSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container isCollecting={isCollecting}>
      <Inner isCollecting={isCollecting}>
        <Dial
          isCollecting={isCollecting}
          handleClick={toggleCollecting}
          tokens={tokens}
          fontsOwned={fontsOwned}
          fontsSelected={fontsSelected}
          isPremium={isPremium}
          referrals={referrals}
          referralGoal={referralGoal}
          daysUntilNextMonth={daysUntilNextMonth}
        />
      </Inner>

      <Expandable pose={isCollecting ? "open" : "closed"}>
        <Drawer
          items={isPremium ? emailsToInvite : fontNames}
          addItem={addItem}
          removeItem={deselectItem}
          full={!tokens}
          isPremium={isPremium}
          referralGoal={referralGoal}
          success={inviteSuccess}
          daysUntilNextMonth={daysUntilNextMonth}
          referralCode={referralCode}
        />

        <Action shadow={isCollecting}>
          <SlideToggleContainer>
            {confirmed ? (
              <SlideToggle key="warning">
                <DrawerMessage>
                  Once fonts are added to your collection, they cannot be
                  returned. Continue?
                </DrawerMessage>
              </SlideToggle>
            ) : null}
            {fontsSelected.length ? (
              <SlideToggle key="confirm">
                <Button
                  onClick={() => {
                    setConfirmed(!confirmed);
                  }}
                  dark={confirmed}
                >
                  {confirmed ? "Cancel" : "Add to collection"}
                </Button>
              </SlideToggle>
            ) : null}

            {fontsSelected.length && confirmed ? (
              <SlideToggle key="certain">
                <Button
                  onClick={() => {
                    redeemFonts(fontsSelected);
                    toggleCollecting();
                  }}
                >
                  Add to collection
                </Button>
              </SlideToggle>
            ) : null}
            {emailsToInvite.length ? (
              <SlideToggle key="invite">
                <Button
                  onClick={() => {
                    inviteEmails(emailsToInvite);
                  }}
                >
                  Send invite{emailsToInvite.length > 1 ? "s" : ""}
                </Button>
              </SlideToggle>
            ) : null}
            {inviteSuccess ? (
              <SlideToggle key="thanks">
                <Button
                  onClick={() => {
                    setInviteSuccess(false);
                  }}
                >
                  Thank You!
                </Button>
              </SlideToggle>
            ) : null}
          </SlideToggleContainer>
        </Action>
      </Expandable>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${(props) =>
    props.isCollecting ? props.theme.colors.green : props.theme.colors.yellow};

  border-radius: 0px 0px
    ${(props) => `${props.theme.borderRadius} ${props.theme.borderRadius}`};

  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */

  /* delay it so it changes once the dial animation finishes */
  transition: background-color 300ms 1000ms ease-in-out;
`;

const Inner = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  padding-top: ${(props) => props.theme.space};
  box-shadow: ${(props) =>
    props.isCollecting ? `0px 8px 10px rgba(0, 0, 0, 0.15)` : `none`};

  z-index: 2;
  transition: box-shadow 300ms 1000ms ease-in-out;

  ${respondTo("xlarge")} {
    padding-top: 80px;
  }
`;

const Action = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */

  min-height: 17px;
  width: 100%;
  box-shadow: ${(props) =>
    props.shadow ? `0px 8px 10px rgba(0, 0, 0, 0.15)` : ``};
  border-radius: 0 0 15px 15px;
  overflow: hidden;

  transition: box-shadow 300ms ${(props) => (props.shadow ? `1000ms` : `0ms`)}
    ease-in-out;
`;

const DrawerMessage = styled.p`
  background: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fontSize_l};
  font-weight: 600;
  line-height: 1.27;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${(props) => props.theme.colors.grayDark};
  margin: 0 auto;
  padding: ${(props) => props.theme.space} 0;
`;

const Button = styled.button`
  position: relative;
  font-size: ${(props) => props.theme.fontSize_xl};
  font-weight: 700;
  line-height: 1.22;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) =>
    props.dark ? props.theme.colors.black : "transparent"};
  padding: 20px 10px;
  flex-grow: 1;
  width: 100%;
  transition: background-color 200ms ease;

  /* light overlay */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => rgba(props.theme.colors.white, 0.2)};

    opacity: 0;
    pointer-events: none;
    transition: opacity 200ms ease;
  }

  &:hover,
  &:focus {
    /* fade in the overlay, so the bg color of the button lightens */
    &::before {
      opacity: 1;
    }
  }

  ${respondTo("xlarge")} {
    padding-top: 23px;
    padding-bottom: 23px;
  }
`;

export default FontSelection;
