import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useFormState } from "react-use-form-state";
import { PoseGroup } from "react-pose";
import {
  CardNumberElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import styled from "styled-components";
import store from "store";

import FormWrapper from "./FormWrapper";
import FormButton, { ButtonContainer } from "./FormButton";
import FormMessage from "../../../components/FormMessage";
import PlanRadio from "./PlanRadio";
import Link from "./Link";
import FormIntro from "./FormIntro";
import CardFields from "../../../components/CardFields";
import Input, { InputField } from "../../../components/Input";
import Label from "../../../components/Label";
import Spinner from "../../../components/Spinner";
import StripeBadge from "../../../components/icons/StripeBadge";
import { useAuth } from "../../../context/AuthContext";

const SignUp = ({
  handleSignUp,
  verifyDiscount,
  signUpError,
  isLoading,
  login,
  isTeamInvite,
  message,
  inviteeEmail,
}) => {
  const { policyCallback, eulaCallback } = useAuth();

  const [step, setStep] = useState(isTeamInvite ? "team-invite" : 1);
  const [discountView, setDiscountView] = useState(false);
  const [discountFailed, setDiscountFailed] = useState(false);
  const [discountCode, setDiscountCode] = useState("");
  const [isDiscountActive, setIsDiscountActive] = useState(false);
  const [discountInfo, setDiscountInfo] = useState({
    name: "",
    starter: { isApplied: false },
    premium: { isApplied: false },
  });
  const [starterPrice, setStarterPrice] = useState(
    process.env.REACT_APP_STARTER_PRICE,
  );
  const [premiumPrice, setPremiumPrice] = useState(
    process.env.REACT_APP_PREMIUM_PRICE,
  );

  const stripe = useStripe();
  const elements = useElements();

  const [formState, { text, email, password, radio }] = useFormState({
    email: inviteeEmail || "",
    // ? select "premium" plan default for now, since its the only option
    plan: "premium",
  });

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [termsError, setTermsError] = useState(false);

  const [cardError, setCardError] = useState("");
  const [cardValid, setCardValid] = useState(false);
  const [cardTouched, setCardTouched] = useState(false);

  const { validity, touched, values } = formState;

  const totalSteps = 2;

  useEffect(() => {
    if (inviteeEmail) {
      formState.setField("email", inviteeEmail);
    }
  }, [inviteeEmail]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isDiscountActive) {
      updatePrices();
    } else {
      setStarterPrice(process.env.REACT_APP_STARTER_PRICE);
      setPremiumPrice(process.env.REACT_APP_PREMIUM_PRICE);
    }
  }, [isDiscountActive]); // eslint-disable-line react-hooks/exhaustive-deps

  const updatePrices = () => {
    if (discountInfo.starter.isApplied) {
      if (discountInfo.starter.percent_off) {
        setStarterPrice(
          removePercentage(
            process.env.REACT_APP_STARTER_PRICE,
            discountInfo.starter.percent_off,
          ),
        );
      } else if (discountInfo.starter.amount_off) {
        setStarterPrice(
          removeAmount(
            process.env.REACT_APP_STARTER_PRICE,
            discountInfo.starter.amount_off,
          ),
        );
      } else {
        setStarterPrice(process.env.REACT_APP_STARTER_PRICE);
      }
    }

    if (discountInfo.premium.isApplied) {
      if (discountInfo.premium.percent_off) {
        setPremiumPrice(
          removePercentage(
            process.env.REACT_APP_PREMIUM_PRICE,
            discountInfo.premium.percent_off,
          ),
        );
      } else if (discountInfo.premium.amount_off) {
        setPremiumPrice(
          removeAmount(
            process.env.REACT_APP_PREMIUM_PRICE,
            discountInfo.premium.amount_off,
          ),
        );
      } else {
        setPremiumPrice(process.env.REACT_APP_PREMIUM_PRICE);
      }
    }
  };

  const removePercentage = (originalPrice, percentage) => {
    const discount = (percentage / 100) * originalPrice;
    let newPrice = originalPrice - discount.toFixed(2);
    if (newPrice < 0) newPrice = 0;

    return newPrice;
  };

  const removeAmount = (originalPrice, discount) => {
    let newPrice = originalPrice - discount;

    if (newPrice < 0) newPrice = 0;
    return newPrice;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isValid = false;

    if (step === 1) {
      // Step 1: must have name, email, and password
      isValid =
        validity.name && validity.email && validity.password && acceptedTerms;

      if (!acceptedTerms) {
        setTermsError(true);
      } else {
        setTermsError(false);
      }
    } else if (step === 2) {
      // Step 2:
      // - must have selected a plan
      // - get the payment method from Stripe

      isValid = validity.plan;

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet
        return;
      }

      //const cardElement = elements.getElement(CardElement);
      let number = elements.getElement(CardNumberElement);

      // use the user's input in the stripe card element to create a payment method
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: number,
      });

      if (error) {
        setCardError(error.message);
      } else {
        setCardError("");

        // sign up the user
        handleSignUp({
          name: values.name,
          email: values.email.toLowerCase(),
          password: values.password,
          plan: values.plan,
          paymentMethodId: paymentMethod.id,
          discountCode: discountCode,
        });

        store.remove("dfc-referral");
      }
    } else if (step === "team-invite") {
      // the user is being invited to join a team
      // just need name, email, and password

      isValid = validity.name && validity.email && validity.password;

      if (isValid) {
        handleSignUp({
          name: values.name,
          email: values.email.toLowerCase(),
          password: values.password,
          plan: "team_member",
        });

        store.remove("dfc-referral");
      }
    }

    if (isValid && step < totalSteps) {
      setStep(step + 1);
    }
  };

  const checkDiscount = async () => {
    if (!discountCode) {
      return failDiscount();
    }

    setDiscountFailed(false);
    setIsDiscountActive(false);

    const { success, name, productDiscounts } = await verifyDiscount(
      discountCode,
    );

    if (success === true) {
      const tempDiscountInfo = { ...discountInfo };
      tempDiscountInfo.name = name;
      tempDiscountInfo.starter = { ...productDiscounts.starter };
      tempDiscountInfo.premium = { ...productDiscounts.premium };
      setDiscountInfo(tempDiscountInfo);
      setIsDiscountActive(true);
      setDiscountView(false);
    } else {
      failDiscount();
    }
  };

  const failDiscount = () => {
    setDiscountFailed(true);
    setIsDiscountActive(false);
  };

  // Shows the policy modal passed in from footer
  const showPolicy = () => {
    if (policyCallback) {
      policyCallback();
    }
  };

  // Shows the eula modal passed in from footer
  const showEula = () => {
    if (eulaCallback) {
      eulaCallback();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <FormWrapper>
          {message ? <FormIntro>{message}</FormIntro> : null}

          {step === 1 || step === "team-invite" ? (
            <div>
              <InputField>
                <Label htmlFor="name">Name</Label>
                <Input
                  id="name"
                  required
                  {...text("name")}
                  className="no-track"
                />
                <PoseGroup>
                  {touched.name && !validity.name ? (
                    <FormMessage key="name">
                      We would love to know your name
                    </FormMessage>
                  ) : null}
                </PoseGroup>
              </InputField>

              <InputField>
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  required
                  {...email("email")}
                  className="no-track"
                />
                <PoseGroup>
                  {touched.email && !validity.email ? (
                    <FormMessage key="email">
                      Please enter a valid email address
                    </FormMessage>
                  ) : null}
                </PoseGroup>
              </InputField>

              <InputField>
                <Label htmlFor="password">Password</Label>
                <Input
                  className="no-track"
                  id="password"
                  required
                  minLength="8"
                  {...password("password")}
                />
                <PoseGroup>
                  {touched.password && !validity.password ? (
                    <FormMessage key="password">
                      Password must be at least 8 characters
                    </FormMessage>
                  ) : null}
                </PoseGroup>
              </InputField>
              <InputField>
                <CheckboxLabel htmlFor="terms">
                  <Input
                    type="checkbox"
                    id="terms"
                    required
                    onClick={() => setAcceptedTerms(!acceptedTerms)}
                    value={acceptedTerms}
                  />
                  <Checkbox isActive={acceptedTerms} />
                  <p>
                    By joining, you agree to our{" "}
                    <CheckboxLink type="button" onClick={showEula}>
                      End User License Agreement (EULA)
                    </CheckboxLink>
                    and{" "}
                    <CheckboxLink type="button" onClick={showPolicy}>
                      Privacy Notice
                    </CheckboxLink>
                  </p>
                </CheckboxLabel>
                <PoseGroup>
                  {termsError ? (
                    <FormMessage key="terms">
                      You must agree to our terms to continue.
                    </FormMessage>
                  ) : null}
                </PoseGroup>
              </InputField>
            </div>
          ) : step === 2 ? (
            discountView ? (
              <div>
                <DiscountContainer>
                  <InputField>
                    <Label htmlFor="discountCode">Discount Code</Label>
                    <Input
                      id="discountCode"
                      value={discountCode}
                      onChange={(e) => setDiscountCode(e.target.value)}
                    />
                  </InputField>
                  <DiscountRow>
                    <DiscountButton small="1" onClick={checkDiscount}>
                      Apply
                    </DiscountButton>
                    {discountFailed ? (
                      <FormMessage key="discount">
                        Code is not valid
                      </FormMessage>
                    ) : null}
                  </DiscountRow>
                </DiscountContainer>
                <CancelLink as="button" onClick={() => setDiscountView(false)}>
                  Cancel
                </CancelLink>
              </div>
            ) : (
              <div>
                {/* // ? hiding the starter member option */}
                {/* <PlanRadio
                  id="starter"
                  title="Starter Member"
                  price={`$${starterPrice}/mo`}
                  description="Add a new font to your collection each month. Billed annually."
                  required
                  {...radio("plan", "starter")}
                /> */}
                <PlanRadio
                  id="premium"
                  title="Membership"
                  price={`$${premiumPrice}/mo`}
                  description="Get immediate access to the entire font library. Billed monthly."
                  required
                  {...radio("plan", "premium")}
                />

                {isDiscountActive ? (
                  <DiscountWrapper>
                    {discountInfo.name}{" "}
                    <DiscountEditLink
                      as="button"
                      onClick={() => setDiscountView(true)}
                    >
                      edit
                    </DiscountEditLink>
                  </DiscountWrapper>
                ) : (
                  <DiscountWrapper>
                    <DiscountLink
                      as="button"
                      onClick={() => setDiscountView(true)}
                    >
                      Have a Discount Code?
                    </DiscountLink>
                  </DiscountWrapper>
                )}

                <InputField>
                  <label>
                    <Label as="div">Credit Card</Label>
                    <CardFields
                      className="no-track"
                      onBlur={() => {
                        setCardTouched(true);
                      }}
                      onChange={(e) => {
                        setCardError(e.error ? e.error.message : "");

                        setCardValid(e.complete);
                      }}
                    />
                  </label>

                  <StripeBadge />

                  <PoseGroup>
                    {cardTouched && !cardValid && cardError ? (
                      <FormMessage key="card">{cardError}</FormMessage>
                    ) : null}
                  </PoseGroup>
                </InputField>
              </div>
            )
          ) : null}

          <PoseGroup>
            {signUpError ? (
              <FormMessage key="error">{signUpError}</FormMessage>
            ) : null}
          </PoseGroup>
        </FormWrapper>

        <ButtonContainer>
          {step > 1 ? (
            <FormButton
              type="button"
              aria-label="Go back"
              onClick={() => {
                setStep(step - 1);
              }}
              small
              inverse
            >
              &larr;
            </FormButton>
          ) : null}

          <FormButton
            type="submit"
            disabled={
              isLoading
                ? true
                : step === 1 || step === "team-invite"
                ? !values.name ||
                  !values.email ||
                  !values.password ||
                  !acceptedTerms
                : step === 2
                ? !values.plan || !cardValid
                : null
            }
          >
            {isLoading ? (
              <Spinner />
            ) : isTeamInvite ? (
              "Join"
            ) : step < totalSteps ? (
              "Next"
            ) : (
              "Sign up"
            )}
          </FormButton>
        </ButtonContainer>
      </form>

      {login && !isTeamInvite ? (
        <StyledLink as="button" onClick={login}>
          Already have an account?
        </StyledLink>
      ) : null}
    </>
  );
};

const Checkbox = styled.div`
  width: 100%;
  max-width: 30px;
  height: 30px;
  border-radius: 9px;
  position: relative;
  background-color: ${(props) => props.theme.colors.white};
  margin-right: 18px;

  &::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    border-radius: 6px;
    background-color: ${(props) => props.theme.colors.grayDark};
    opacity: ${(props) => (props.isActive ? `1` : `0`)};
    transform: scale(${(props) => (props.isActive ? `1` : `.8`)});
    transition: transform 0.2s, opacity 0.2s;
  }
`;

const CheckboxLink = styled.button`
  display: inline;
  color: ${(props) => props.theme.colors.grayDark};
  text-decoration: underline;
  transition: color 0.2s;

  &:hover {
    color: ${(props) => props.theme.colors.blackLight};
  }
`;

const CheckboxLabel = styled(Label)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: ${(props) => props.theme.fontSize_xs};
  font-weight: 500;
  margin-top: 30px;
  cursor: pointer;

  #terms {
    position: absolute;
    opacity: 0;
    width: 2px;
  }
`;

const StyledLink = styled(Link)`
  margin-top: ${(props) => props.theme.space};
`;

const DiscountContainer = styled.div``;

const DiscountRow = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DiscountLink = styled(StyledLink)`
  text-align: center;
  margin: 0 auto;
`;

const DiscountWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${(props) => props.theme.space};
`;

const DiscountEditLink = styled(DiscountLink)`
  display: inline;
  margin: 0;
  margin-left: 15px;
  font-size: 18px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.yellow};

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const CancelLink = styled(StyledLink)`
  text-align: center;
  margin: 100px auto 0;
`;

const DiscountButton = styled(FormButton)`
  padding: 12px 20px;
`;

SignUp.propTypes = {
  inviteeEmail: PropTypes.string,
};
SignUp.defaultProps = {
  inviteeEmail: "",
};

export default SignUp;
