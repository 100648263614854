import axios from "axios";
import store from "store";

const apiBaseUrl = process.env.REACT_APP_API_URL + "/api/v1";

const getToken = () => {
  try {
    const token = store.get("DFC-token");
    return token;
  } catch (err) {
    return null;
  }
};

export const getAllFonts = async () => {
  const config = {
    method: "get",
    url: `${apiBaseUrl}/fonts`,
    headers: {
      "x-access-token": getToken(),
    },
  };

  const result = await axios(config);

  return result;
};

export const checkLogin = async (email, password) => {
  try {
    const data = { email, password };

    const config = {
      method: "post",
      url: `${apiBaseUrl}/login`,
      headers: {
        "x-access-token": getToken(),
      },
      data: data,
    };

    const result = await axios(config);

    return result.data;
  } catch (err) {
    throw err;
  }
};

export const getUser = async () => {
  try {
    const config = {
      method: "get",
      url: `${apiBaseUrl}/get-user`,
      headers: {
        "x-access-token": getToken(),
      },
    };
    const result = await axios(config);

    return result.data.user;
  } catch (err) {
    console.error(err);
  }
};

export const createUser = async ({
  name,
  email,
  password,
  plan,
  paymentMethodId,
  inviteToken,
  referrerToken,
  teamToken,
  discountCode,
}) => {
  const data = {
    name,
    email,
    password,
    plan,
    paymentMethodId,
    inviteToken,
    referrerToken,
    teamToken,
    discountCode,
  };

  try {
    const config = {
      method: "post",
      url: `${apiBaseUrl}/signup`,
      data,
    };
    const result = await axios(config);

    return result.data;
  } catch (err) {
    if (err.response.data && err.response.data.msg) {
      throw err.response.data.msg;
    } else if (err.response.data && err.response.data.message) {
      throw err.response.data.message;
    } else {
      throw err;
    }
  }
};

export const updateUsersName = async (name) => {
  const data = { name };
  try {
    const config = {
      method: "patch",
      url: `${apiBaseUrl}/user-info`,
      headers: {
        "x-access-token": getToken(),
      },
      data: data,
    };
    return await axios(config);
  } catch (err) {
    console.error(err);
  }
};

export const checkDiscountOnServer = async (code) => {
  try {
    const config = {
      method: "get",
      url: `${apiBaseUrl}/verify-discount/${code}`,
    };
    const result = await axios(config);
    return result.data;
  } catch (err) {
    console.error(err);
  }
};

export const redeemFont = (fontId) => {
  const data = { fontId };
  try {
    const config = {
      method: "patch",
      url: `${apiBaseUrl}/user-fonts`,
      headers: {
        "x-access-token": getToken(),
      },
      data: data,
    };

    return axios(config);
  } catch (err) {
    throw err;
  }
};

export const deactivateFont = (fontId) => {
  const data = { fontId, remove: true };
  try {
    const config = {
      method: "patch",
      url: `${apiBaseUrl}/user-fonts`,
      headers: {
        "x-access-token": getToken(),
      },
      data: data,
    };

    return axios(config);
  } catch (err) {
    throw err;
  }
};

export const addProjectToServer = async (fonts, whitelist) => {
  const data = { fonts, whitelist };

  try {
    const config = {
      method: "post",
      url: `${apiBaseUrl}/projects`,
      headers: {
        "x-access-token": getToken(),
      },
      data,
    };
    return await axios(config);
  } catch (err) {
    console.error(err);
  }
};

export const updateProjectOnServer = async (projectId, fonts, whitelist) => {
  const data = { fonts, whitelist };
  try {
    const config = {
      method: "patch",
      url: `${apiBaseUrl}/projects/${projectId}`,
      headers: {
        "x-access-token": getToken(),
      },
      data: data,
    };
    return await axios(config);
  } catch (err) {
    console.error(err);
  }
};

export const deleteProjectOnServer = async (projectId) => {
  try {
    const config = {
      method: "delete",
      url: `${apiBaseUrl}/projects/${projectId}`,
      headers: {
        "x-access-token": getToken(),
      },
    };
    return await axios(config);
  } catch (err) {
    console.error(err);
  }
};

export const getBillingDetails = async () => {
  try {
    const config = {
      method: "get",
      url: `${apiBaseUrl}/user-billing`,
      headers: {
        "x-access-token": getToken(),
      },
    };

    const result = await axios(config);

    return result.data.msg;
  } catch (err) {
    if (err.response.data) {
      throw err.response.data;
    } else {
      throw err;
    }
  }
};

export const updateBillingDetails = async (paymentMethodId) => {
  const data = { paymentMethodId };
  try {
    const config = {
      method: "patch",
      url: `${apiBaseUrl}/user-billing`,
      headers: {
        "x-access-token": getToken(),
      },
      data,
    };

    const result = await axios(config);

    return result.data.msg;
  } catch (err) {
    throw err;
  }
};

export const sendReferral = async (email) => {
  const data = { email };
  try {
    const config = {
      method: "post",
      url: `${apiBaseUrl}/referrals`,
      headers: {
        "x-access-token": getToken(),
      },
      data,
    };

    const result = await axios(config);

    return result.data.msg;
  } catch (err) {
    throw err.response ? err.response.data : err;
  }
};

export const getUserReferrals = async () => {
  try {
    const config = {
      method: "get",
      url: `${apiBaseUrl}/referrals`,
      headers: {
        "x-access-token": getToken(),
      },
    };

    const result = await axios(config);

    return result.data.msg;
  } catch (err) {
    throw err;
  }
};

export const getTeamInvites = async () => {
  try {
    const config = {
      method: "get",
      url: `${apiBaseUrl}/team-invites`,
      headers: {
        "x-access-token": getToken(),
      },
    };

    const result = await axios(config);

    return result.data.msg;
  } catch (err) {
    throw err;
  }
};

export const getTeamInvite = async (token) => {
  try {
    const config = {
      method: "get",
      url: `${apiBaseUrl}/team-invites/${token}`,
      headers: {
        "x-access-token": getToken(),
      },
    };

    const result = await axios(config);

    return result.data.msg;
  } catch (err) {
    throw err;
  }
};

export const sendTeamInvite = async (email) => {
  const data = { email };
  try {
    const config = {
      method: "post",
      url: `${apiBaseUrl}/team-invites`,
      headers: {
        "x-access-token": getToken(),
      },
      data,
    };

    const result = await axios(config);

    return result.data.msg;
  } catch (err) {
    throw err.response ? err.response.data : err;
  }
};

export const deleteTeamInvite = async (email) => {
  const data = { email };
  try {
    const config = {
      method: "delete",
      url: `${apiBaseUrl}/team-invites`,
      headers: {
        "x-access-token": getToken(),
      },
      data,
    };

    const result = await axios(config);

    return result.data.msg;
  } catch (err) {
    throw err;
  }
};

export const renameTeam = async (name) => {
  const data = { name };
  try {
    const config = {
      method: "patch",
      url: `${apiBaseUrl}/user-team`,
      headers: {
        "x-access-token": getToken(),
      },
      data,
    };

    const result = await axios(config);

    return result.data.msg;
  } catch (err) {
    throw err;
  }
};

export const updateSubscription = async (subscription) => {
  const data = { subscription };
  try {
    const config = {
      method: "patch",
      url: `${apiBaseUrl}/user-subscription`,
      headers: {
        "x-access-token": getToken(),
      },
      data,
    };

    const result = await axios(config);

    return result.data.msg;
  } catch (err) {
    if (err.response.data.msg) {
      throw err.response.data.msg;
    } else {
      throw err;
    }
  }
};

export const cancelSubscription = async (subscription) => {
  const data = { subscription };
  try {
    const config = {
      method: "delete",
      url: `${apiBaseUrl}/user-subscription`,
      headers: {
        "x-access-token": getToken(),
      },
      data,
    };

    const result = await axios(config);

    return result.data.msg;
  } catch (err) {
    if (err.response.data.msg) {
      throw err.response.data.msg;
    } else {
      throw err;
    }
  }
};

export const forgotPassword = async (email) => {
  const data = { email };
  try {
    const config = {
      method: "post",
      url: `${apiBaseUrl}/password-forgot`,
      data,
    };

    const result = await axios(config);

    return result.data;
  } catch (err) {
    throw err.response ? err.response.data : err;
  }
};

export const resetPassword = async (token, password) => {
  const data = { token, password };
  try {
    const config = {
      method: "post",
      url: `${apiBaseUrl}/password-reset`,
      data,
    };

    const result = await axios(config);

    return result.data;
  } catch (err) {
    if (err.response.data.msg) {
      throw err.response.data.msg;
    } else {
      throw err;
    }
  }
};

export const sendCustomFontQuery = async (name, company, email) => {
  const data = { name, company, email };
  try {
    const config = {
      method: "post",
      url: `${apiBaseUrl}/custom-font-email`,
      data: data,
    };
    const result = await axios(config);

    if (result.data.success) {
      return true;
    }

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};
