import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";

import Login from "./components/Login";
import SignUp from "./components/SignUp";
import Password from "./components/Password";

import { getTeamInvite, forgotPassword, resetPassword } from "../../utils/api";

const AuthSidebar = ({
  closeSidebar,
  inviteToken,
  referrerToken,
  teamToken,
  resetToken,
}) => {
  const {
    login,
    signUp,
    checkDiscountCode,
    setSidebarSignupCallback,
    setSidebarLoginCallback,
  } = useAuth();
  const history = useHistory();

  const [isNewUser, setIsNewUser] = useState(
    inviteToken || referrerToken || teamToken ? true : false,
  );

  const [isForgot, setIsForgot] = useState(false);
  const [isReset, setIsReset] = useState(resetToken ? true : false);

  const [hasLoginError, setHasLoginError] = useState(false);

  const [signUpError, setSignUpError] = useState(false);

  const [passwordError, setPasswordError] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [invitor, setInvitor] = useState(null);
  const [email, setEmail] = useState(null);

  useEffect(() => {
    // Passes the ability to open the login and sidebar signup to AuthContext, so we can use it in other views
    setSidebarLoginCallback(() => () => {
      setIsNewUser(false);
    });

    setSidebarSignupCallback(() => () => {
      setIsNewUser(true);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // get the name of the inviter for a team invite
  useEffect(() => {
    if (teamToken) {
      getInviteDetails(teamToken);
    }
  }, [teamToken]);

  const handleLogin = async (email, password) => {
    try {
      setIsLoading(true);

      const loginResult = await login(email, password);

      setIsLoading(false);

      if (loginResult.success) {
        // Login passes
        setHasLoginError(false);

        // go to fonts dashboard
        history.push("/fonts");

        // close the sidebar (for mobile)
        if (closeSidebar) {
          closeSidebar();
        }
      } else {
        // Login fails
        setHasLoginError(true);
      }
    } catch (err) {
      setIsLoading(false);
      // login errors
    }
  };

  const verifyDiscount = async (code) => {
    const result = await checkDiscountCode(code);

    return result;
  };

  const handleSignUp = async (data) => {
    try {
      setIsLoading(true);
      console.log(referrerToken);

      const signUpData = {
        ...data,
        inviteToken,
        referrerToken,
        teamToken,
      };

      const signUpResult = await signUp(signUpData);

      setIsLoading(false);

      if (signUpResult.success) {
        // Sign up successful
        setSignUpError(false);

        // go to fonts dashboard
        history.push("/fonts");

        // close the sidebar (for mobile)
        if (closeSidebar) {
          closeSidebar();
        }
      } else {
        // sign up fails
        setSignUpError(signUpResult.msg);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const handleForgot = async (email) => {
    try {
      setPasswordError(null);
      setIsLoading(true);

      // send reset email
      await forgotPassword(email);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);

      if (err.data && err.data.errors) {
        setPasswordError(err.data.errors.email[0]);
      }

      throw err;
    }
  };

  const handleReset = async (password) => {
    try {
      setPasswordError(null);
      setIsLoading(true);

      // reset password
      await resetPassword(resetToken, password);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);

      setPasswordError(err);

      throw err;
    }
  };

  const getInviteDetails = async (token) => {
    try {
      const invite = await getTeamInvite(token);

      setInvitor(invite.owner.name);
      setEmail(invite.email);
    } catch (err) {}
  };

  return (
    <>
      {isForgot || isReset ? (
        <Password
          error={passwordError}
          isLoading={isLoading}
          handleForgot={handleForgot}
          login={() => {
            setIsNewUser(false);
            setIsForgot(false);
            setIsReset(false);
            setPasswordError(null);
          }}
          isReset={isReset}
          handleReset={handleReset}
        />
      ) : isNewUser ? (
        <SignUp
          handleSignUp={handleSignUp}
          verifyDiscount={verifyDiscount}
          signUpError={signUpError}
          isLoading={isLoading}
          login={() => {
            setIsNewUser(false);
          }}
          isTeamInvite={teamToken ? true : false}
          message={invitor ? `Join ${invitor}'s team.` : undefined}
          inviteeEmail={email}
        />
      ) : (
        <Login
          signUp={() => {
            setIsNewUser(true);
          }}
          handleLogin={handleLogin}
          hasLoginError={hasLoginError}
          isLoading={isLoading}
          forgot={() => {
            setIsReset(false);
            setIsForgot(true);
          }}
        />
      )}
    </>
  );
};

export default AuthSidebar;
