import React from "react";
import styled from "styled-components";
import { rem } from "polished";
import posed, { PoseGroup } from "react-pose";

const Dial = ({
  isCollecting,
  handleClick,
  tokens,
  fontsOwned,
  fontsSelected,
  isPremium,
  referrals,
  referralGoal,
  daysUntilNextMonth,
}) => {
  const strokeWidth = 5;
  const diameter = 172;
  const radius = 172 / 2;

  // remaining fonts that can be selected
  const remainingFonts =
    tokens - fontsSelected.length > -1 ? tokens - fontsSelected.length : 0;

  // number of referrals needed to get free unlimited membership
  const remainingReferrals = referralGoal - referrals;

  const filledPieces = isPremium ? referrals : fontsOwned;
  const pieces = isPremium ? referralGoal : tokens + fontsOwned;

  // amount of space between each piece
  const spacer = pieces < 50 ? 5 : 3;

  return (
    <DialButton isCollecting={isCollecting} onClick={handleClick}>
      <Svg size={diameter}>
        {Array.from({ length: pieces }).map((piece, index) => (
          <Circle
            cx="50%"
            cy="50%"
            key={index}
            index={index}
            total={pieces}
            /* radius = half the width of the SVG, minus the stroke width */
            radius={radius - strokeWidth}
            circumference={2 * Math.PI * (radius - strokeWidth)}
            spacer={spacer}
            filled={index < filledPieces}
            isCollecting={isCollecting}
          />
        ))}
      </Svg>

      <DialContent flipMove={false}>
        {isCollecting ? (
          <DialFade key="collecting">
            <DialNumber>
              {isPremium
                ? remainingReferrals < 1
                  ? ""
                  : remainingReferrals
                : remainingFonts}
            </DialNumber>
            <DialMessage
              dangerouslySetInnerHTML={{
                __html: isPremium
                  ? remainingReferrals < 1
                    ? "You’ve earned a free unlimited membership!"
                    : "referrals to&nbsp;go"
                  : "remaining fonts",
              }}
            />
          </DialFade>
        ) : (
          <DialFade key="static">
            <DialCta small={isPremium || remainingFonts === 0}>
              {isPremium
                ? remainingReferrals < 1
                  ? "Free Unlimited"
                  : "Get Unlimited Free"
                : remainingFonts > 0
                ? "Add Fonts"
                : `New font in ${daysUntilNextMonth} days`}
            </DialCta>
          </DialFade>
        )}
      </DialContent>
    </DialButton>
  );
};

const DialContent = styled(PoseGroup)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const DialCta = styled.p`
  color: ${(props) => props.theme.colors.black};
  font-size: ${(props) => (props.small ? rem("23px") : rem("30px"))};
  font-weight: 700;
  line-height: 1.1;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0;
  transition: color 200ms ease;
`;

const DialMessage = styled.p`
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fontSize_l};
  font-weight: 700;
  line-height: 1.1;
  text-align: center;
  margin-bottom: 0;
`;

const DialNumber = styled.p`
  color: ${(props) => props.theme.colors.white};
  font-size: ${rem("85px")};
  line-height: 0.9;
  text-align: center;
  margin-bottom: 0;
`;

const DialFade = posed.div({
  enter: {
    opacity: 1,
    transition: { delay: 1000, duration: 200 },
  },
  exit: {
    opacity: 0,
    transition: { delay: 1000, duration: 200 },
  },
});

const DialButton = styled.button`
  position: relative;
  margin-bottom: 33px;
  box-shadow: ${(props) =>
    props.isCollecting
      ? `inset 4px 8px 21px rgba(48, 57, 64, 0.5)`
      : `inset 0 0 0 transparent, -5px -10px 30px #efcd6c, 5px 10px 30px #a68d46`};
  border-radius: 50%;
  line-height: 0;
  padding: 16px;
  /* delay the transition when going from active state to static state, so it coincides with the bg color changing */
  transition: 300ms ${(props) => (props.isCollecting ? `0ms` : `1s`)} box-shadow
    ease-in-out;

  &:hover,
  &:focus {
    ${DialCta} {
      color: ${(props) => props.theme.colors.white};
    }
  }

  &:active {
    box-shadow: inset 4px 8px 21px rgba(48, 57, 64, 0.5);
  }
`;

const Svg = styled.svg`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  transform: rotate(-90deg);
`;

const Circle = styled.circle`
  stroke: ${(props) =>
    props.isCollecting
      ? props.filled
        ? props.theme.colors.black
        : props.theme.colors.white
      : props.filled
      ? props.theme.colors.white
      : props.theme.colors.black};

  /* divide total by 1000 so the animation always takes 1 sec, regardless of how many pieces there are */
  /* give them a transition delay based on their index */
  /* add a little to the transition delay based on how far away this index is from the middle, to give the whole thing an ease-in-out effect */
  transition: ${(props) =>
    `stroke ${1000 / props.total}ms ${
      (1000 / props.total) * props.index +
      Math.abs(props.total / 2 - props.index)
    }ms ease-in-out`};

  stroke-width: 5px;
  fill: transparent;

  /* radius = half the width of the SVG, minus the stroke width */
  /* r: calc(50% - 5px); */
  r: ${(props) => props.radius}px;

  /* make both values the entire circumference, minus the stroke width */
  stroke-dasharray: ${(props) =>
    `${props.circumference} ${props.circumference}`};

  /* the length of each piece */
  /* take the total circumference and subtract the desired length from it */
  /* but also add some extra (to decrease the length of each piece) so we have some space between the pieces */
  stroke-dashoffset: ${(props) =>
    props.circumference - props.circumference / props.total + props.spacer};

  transform-origin: 50% 50%;

  /* rotate each piece to its position on the dial */
  /* length of the piece / radius = rotation in radians */
  /* convert radians to degrees: radians * (180 / pi) = degress */
  /* multiply by this piece's index, so we know how far along the circle it needs to be rotated */
  transform: ${(props) =>
    `rotate(${
      (props.circumference / props.total / props.radius) *
      (180 / Math.PI) *
      props.index
    }deg)`};
`;

export default Dial;
