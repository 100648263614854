import styled from "styled-components";
import { rem } from "polished";

const Input = styled.input`
  border-radius: 15px;
  border: 2px solid ${(props) => props.theme.colors.pumice};
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.grayDark};
  outline: none;
  width: 100%;
  font-weight: 500;
  padding: 25px ${(props) => props.theme.space};
  font-size: ${rem("21px")};
`;

export const InputField = styled.div`
  margin-bottom: ${(props) => props.theme.space};

  &:last-child {
    margin-bottom: 0px;
  }
`;

export default Input;
