import React from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";

import { useAuth } from "../../context/AuthContext";

import Hero from "./components/Hero";
import IntroInfo from "./components/IntroInfo";
import IntroSlider from "./components/IntroSlider";
import HowSection from "./components/HowSection";
import UnlimitedSection from "./components/UnlimitedSection";
import SignupSection from "./components/SignupSection";
import FontSection from "./components/FontSection";
import { respondTo } from "../../styles/styleHelpers";

import { getImagesFromFolder } from "../../utils/utils";

const Marketing = () => {
  const { user, openSidebarSignup } = useAuth();

  const heroImages = getImagesFromFolder(
    require.context(
      "../../images/marketing",
      false,
      /hero-[0-9]\.(png|jpe?g)$/,
    ),
  );

  const secondaryImages = getImagesFromFolder(
    require.context(
      "../../images/marketing",
      false,
      /secondary-[0-9]\.(png|jpe?g)$/,
    ),
  );

  const tertiaryImages = getImagesFromFolder(
    require.context(
      "../../images/marketing",
      false,
      /tertiary-[0-9]\.(png|jpe?g)$/,
    ),
  );

  return user ? (
    // redirect to /fonts if logged in
    <Redirect to="/fonts" />
  ) : (
    <MarketingContainer>
      <Hero images={heroImages} />

      <IntroSection>
        <IntroInfo />
        <IntroSlider images={secondaryImages} />
      </IntroSection>

      <HowSection />

      <UnlimitedSection images={tertiaryImages} />

      <SignupSection openSignup={openSidebarSignup} />

      <FontSection />
    </MarketingContainer>
  );
};

const MarketingContainer = styled.div`
  background: ${(props) => props.theme.colors.white};
  position: relative;
`;

const IntroSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 60px;
  max-width: 2000px;
  width: 85%;
  margin-left: auto;
  margin-right: auto;

  ${respondTo("medium")} {
    flex-direction: row;
    margin-bottom: 36px;
  }

  ${respondTo("large")} {
    width: 100%;
    margin-left: 0;
    margin-bottom: 69px;
  }

  ${respondTo("xlarge")} {
    margin-bottom: 200px;
  }
`;

export default Marketing;
