import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const RangeSlider = ({
  defaultValue,
  name,
  min,
  max,
  step,
  trackSize,
  thumbSize,
  handleChange,
  handleStop,
  highlightCurrentRange,
  showCenterTick,
  showCounter,
  softEdges,
  disabled,
}) => {
  const [currentRangeValue, setRangeValue] = useState(defaultValue);

  const updateValue = (e) => {
    const { value } = e.target;
    setRangeValue(Number(value));

    // Handle any other updates
    if (handleChange) {
      handleChange(value);
    }
  };

  return (
    <Slider offsetForCounter={showCounter} disabled={disabled}>
      <Progress
        currentRangeValue={currentRangeValue}
        max={max}
        min={min}
        trackSize={trackSize}
        thumbSize={thumbSize}
        highlightCurrentRange={highlightCurrentRange}
        softEdges={softEdges}
        disabled={disabled}
      />
      <Tick showCenterTick={showCenterTick} />
      <Input
        aria-label={name}
        onChange={updateValue}
        onMouseUp={handleStop || undefined}
        onTouchEnd={handleStop || undefined}
        min={min}
        max={max}
        name={name}
        step={step}
        thumbSize={thumbSize}
        trackSize={trackSize}
        softEdges={softEdges}
        type="range"
        value={currentRangeValue}
        disabled={disabled}
      />
      {showCounter && <Counter>{currentRangeValue}</Counter>}
    </Slider>
  );
};

const Slider = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: ${(props) => (props.offsetForCounter ? "-16.5px" : 0)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : undefined)};
`;

const Counter = styled.span`
  display: block;
  text-align: center;
  bottom: -38px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
`;

const Progress = styled.div.attrs((props) => ({
  style: {
    width: `${
      ((props.currentRangeValue - props.min) / (props.max - props.min)) * 100
    }%`,
  },
}))`
  background-color: ${(props) =>
    !props.disabled ? props.theme.colors.primary : props.theme.colors.gray};
  border-radius: ${(props) => (props.softEdges ? "2.4px" : 0)};
  content: "";
  display: ${(props) => (props.highlightCurrentRange ? "block" : "none")};
  height: ${(props) => props.trackSize}px;
  position: absolute;
  left: 0;
  pointer-events: none;
  user-select: none;
  z-index: 3;
`;

const Tick = styled.div`
  background-color: ${(props) => props.theme.colors.gray};
  border-radius: 2px;
  content: "";
  display: ${(props) => (props.showCenterTick ? "block" : "none")};
  height: 33px;
  width: 2px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`;

const Input = styled.input`
  -webkit-appearance: none;
  width: 100%;
  margin: ${(props) => (props.thumbSize - props.trackSize) / 2}px 0;
  z-index: 2;

  &:focus {
    outline: none;
  }

  &:disabled {
    pointer-events: none;

    &::-webkit-slider-thumb {
      background: #8e95a7;
    }

    &::-webkit-slider-runnable-track {
      background: #eeeae7;
    }
  }

  /* track */
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: ${(props) => props.trackSize}px;
    cursor: pointer;
    box-shadow: none;
    background: ${(props) => props.theme.colors.grayDark};
    border-radius: ${(props) => (props.softEdges ? "2.4px" : 0)};
    border: 0;
  }
  &::-moz-range-track {
    width: 100%;
    height: ${(props) => props.trackSize}px;
    cursor: pointer;
    box-shadow: none;
    background: ${(props) => props.theme.colors.grayDark};
    border-radius: ${(props) => (props.softEdges ? "2.4px" : 0)};
    border: 0;
  }
  &::-ms-track {
    width: 100%;
    height: ${(props) => props.trackSize}px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  /* thumb */
  &::-webkit-slider-thumb {
    box-shadow: none;
    border: 0px solid ${(props) => props.theme.colors.primary};
    height: ${(props) => props.thumbSize}px;
    width: ${(props) => props.thumbSize}px;
    border-radius: ${(props) => props.thumbSize}px;
    background: ${(props) => props.theme.colors.white};
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: ${(props) => props.trackSize / 2 - props.thumbSize / 2}px;

    transform: scale(0.9);
    transition: transform 200ms ease-in-out;
  }
  &::-moz-range-thumb {
    box-shadow: none;
    border: 0px solid ${(props) => props.theme.colors.primary};
    height: ${(props) => props.thumbSize}px;
    width: ${(props) => props.thumbSize}px;
    border-radius: ${(props) => props.thumbSize}px;
    background: ${(props) => props.theme.colors.primary};
    cursor: pointer;

    transform: scale(0.9);
    transition: transform 200ms ease-in-out;
  }
  &::-ms-thumb {
    box-shadow: none;
    border: 0px solid ${(props) => props.theme.colors.primary};
    height: ${(props) => props.thumbSize}px;
    width: ${(props) => props.thumbSize}px;
    border-radius: ${(props) => props.thumbSize}px;
    background: ${(props) => props.theme.colors.primary};
    cursor: pointer;
  }

  /* focus state */
  &:focus::-webkit-slider-thumb {
    transform: scale(1.03);
  }
  &:focus::-moz-range-thumb {
    transform: scale(1.03);
  }

  &::-ms-fill-lower {
    background: ${(props) => props.theme.colors.gray};
    border: ${(props) => props.trackSize}px solid
      ${(props) => props.theme.colors.gray};
    border-radius: ${(props) => (props.softEdges ? "4.8px" : 0)};
    box-shadow: none;
  }

  &::-ms-fill-upper {
    background: ${(props) => props.theme.colors.gray};
    border: ${(props) => props.trackSize}px solid
      ${(props) => props.theme.colors.gray};
    border-radius: ${(props) => (props.softEdges ? "4.8px" : 0)};
    box-shadow: none;
  }

  &:focus::-ms-fill-lower {
    background: ${(props) => props.theme.colors.whiteDark};
  }

  &:focus::-ms-fill-upper {
    background: ${(props) => props.theme.colors.whiteDark};
  }
`;

RangeSlider.propTypes = {
  defaultValue: PropTypes.number,
  name: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  trackSize: PropTypes.number,
  thumbSize: PropTypes.number,
  handleChange: PropTypes.func,
  handleStop: PropTypes.func,
  highlightCurrentRange: PropTypes.bool,
  showCenterTick: PropTypes.bool,
  showCounter: PropTypes.bool,
  softEdges: PropTypes.bool,
  disabled: PropTypes.bool,
};

RangeSlider.defaultProps = {
  min: -100,
  max: 100,
  step: 1,
  trackSize: 4,
  thumbSize: 19,
  defaultValue: 0,
};

export default RangeSlider;
