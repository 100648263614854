import React from "react";
import styled from "styled-components";
import posed, { PoseGroup } from "react-pose";

const PosedSlideToggle = posed.div({
  enter: {
    height: "auto",
    transition: {
      duration: 300,
    },
  },
  exit: {
    height: "0px",
    transition: {
      duration: 300,
    },
  },
});

const SlideToggle = styled(PosedSlideToggle)`
  overflow: hidden;
`;

export const SlideToggleContainer = ({ children }) => (
  <PoseGroup flipMove={false}>{children}</PoseGroup>
);

export default SlideToggle;
