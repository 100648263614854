import React from "react";
import { NavLink } from "react-router-dom";
import { rem } from "polished";
import styled from "styled-components";

import { listReset } from "../../../styles/styleHelpers";

const Menu = ({ handleSignOut, isActive, closeSidebar }) => {
  return (
    <Nav>
      <MenuList>
        {isActive ? (
          <li>
            <MenuLink
              to="/fonts"
              onClick={closeSidebar ? closeSidebar : undefined}
            >
              Fonts
            </MenuLink>
          </li>
        ) : null}
        <li>
          <MenuLink
            to="/membership"
            onClick={closeSidebar ? closeSidebar : undefined}
          >
            Membership
          </MenuLink>
        </li>
        <li>
          <MenuLink
            as="button"
            onClick={handleSignOut ? handleSignOut : undefined}
          >
            Sign Out
          </MenuLink>
        </li>
      </MenuList>
    </Nav>
  );
};

const Nav = styled.nav`
  background-color: ${(props) => props.theme.colors.gray};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 20px ${(props) => props.theme.space};

  margin-top: ${(props) => props.theme.space_s};
`;

const MenuList = styled.ul`
  ${listReset()};

  li {
    margin-bottom: 0;
  }
`;

const MenuLink = styled(NavLink)`
  display: inline-block;
  padding: 10px 0;
  color: ${(props) => props.theme.colors.grayDark};
  font-size: ${rem("21px")};
  font-weight: ${(props) => props.theme.fontWeight_m};
  line-height: 1;
  transition: color 200ms ease;

  &:hover,
  &:focus,
  &.active {
    color: ${(props) => props.theme.colors.blackLight};
  }
`;

export default Menu;
