import React from "react";

const Discover = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="109"
    height="19"
    viewBox="0 0 187.09 31.46"
  >
    <path
      d="M0,.48H8.13a29.76,29.76,0,0,1,4.42.35c7.16,1,12.82,4.82,12.82,14.89S19.71,29.57,12.55,30.54a30,30,0,0,1-4.42.27H0ZM184.47,5.1A1.93,1.93,0,0,0,186.4,3a1.94,1.94,0,1,0-3.86,0A1.92,1.92,0,0,0,184.47,5.1Zm0-4.68A2.61,2.61,0,0,1,187.09,3h0a2.62,2.62,0,0,1-2.62,2.62h0a2.62,2.62,0,1,1,0-5.23Zm0,3-.14-.21H184V4.48h-.56v-3h1.17c.69,0,1.11.27,1.11.89s-.35.76-.83.83l.83,1.24h-.62Zm0-1.52h.07c.27,0,.55.07.55.42s-.28.48-.55.48H184v-.9ZM165.3,13.37c2.28-.27,4.14-1.24,4.14-4.13s-1.86-3.52-4.14-3.86h-4.13v8.13h1.93A21.8,21.8,0,0,0,165.3,13.37Zm0-12.82c5.38.21,10.34,1.31,10.34,8.21a6.78,6.78,0,0,1-6.06,7c1.58.2,2.55,1.79,3.1,3.17l4.76,11.85h-6.62l-3.58-9.51a4.73,4.73,0,0,0-1.93-2.69,4.74,4.74,0,0,0-2.21-.41h-1.93V30.81h-5.93V.48h6.41c1.17,0,2.48,0,3.66.07Zm-55.21,9a16.76,16.76,0,0,1,1,6.07,17.51,17.51,0,0,1-1,6.07c-2.14,5.85-7.31,9.65-14.41,9.65-9.51,0-15.58-6.48-15.58-15.72S86.31,0,95.68,0C102.78,0,108,3.72,110.09,9.58Zm0-6.41,10.48,27.64h2.2L134.42.48h-6.2l-6.21,16L115.4.48h-6.35ZM135.94.48h17.64v4.9H141.93v7.44h10.62v4.69H141.94v8.55h11.71v4.75H135.94V.48ZM12.55,5.86a10.43,10.43,0,0,0-3.24-.48H5.93V26.06H9.31a10.49,10.49,0,0,0,3.24-.55,10,10,0,0,0,6.61-9.79A10,10,0,0,0,12.55,5.86ZM27.71.48h6V30.81h-6ZM52.94,8.55c-2.07-1.31-4.27-2.62-6.61-2.9C44.19,5.38,41.91,6,41.91,9c0,5.24,13.1,3,13.1,13.17,0,6.61-5.24,9.86-11.23,9.17a15,15,0,0,1-7.66-3v-6.2c2,1.1,5,3.24,7.31,3.65,2.55.42,5.31-.34,5.31-3.1,0-5.86-13-3.45-13-13.44C35.71,2.48,40.81,0,46.05,0a10,10,0,0,1,6.89,2.76V8.55ZM78.58,27.78c-1.51,1.93-5.1,3-7.51,3.24-9.31.62-15.51-4.55-15.51-14.82S61-.21,71.07.35A13,13,0,0,1,78.45,2.9V8.69a16,16,0,0,0-7.38-2.76c-6.13-.21-9.31,3.65-9.31,9.86s3,9.65,9.31,9.37c2.48-.07,6.21-2.07,7.51-3.17Z"
      fill="#FFF"
    />
  </svg>
);

export default Discover;
