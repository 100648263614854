import React, { useState } from "react";
import styled from "styled-components";
import DownloadMac from "../../../images/download-mac.png";
import DownloadWindows from "../../../images/download-windows.png";
import { respondTo } from "../../../styles/styleHelpers";

// TODO: accessibility could be improved here with some aria-controls attributes

const HowSection = () => {
  const [step, setStep] = useState(1);

  return (
    <HowContainer>
      <Title>How it works</Title>
      <HowRow>
        <InstructionsContainer>
          <Instruction1
            onClick={() => setStep(1)}
            className={`instruction--1 ${step === 1 ? "-active" : ""}`}
          >
            <div className="instruction__number">01</div>
            <p className="instruction__text">Activate Fonts</p>
          </Instruction1>

          <Instruction2
            onClick={() => setStep(2)}
            className={`instruction--2 ${step === 2 ? "-active" : ""}`}
          >
            <div className="instruction__number">02</div>
            <p className="instruction__text">Download the App</p>
          </Instruction2>

          <Instruction3
            onClick={() => setStep(3)}
            className={`instruction--3 ${step === 3 ? "-active" : ""}`}
          >
            <div className="instruction__number">03</div>
            <p className="instruction__text">Done</p>
          </Instruction3>
        </InstructionsContainer>

        <Info className={`info--${step}`}>
          <InfoInner>
            {step === 1 && (
              <InfoItem>
                <InfoText>
                  Visit the online library to activate the fonts that you want
                  to add to your collection and generate CSS codes for web
                  projects.
                </InfoText>
              </InfoItem>
            )}

            {step === 2 && (
              <InfoItem>
                <InfoText>
                  Install the macOS or Windows desktop app to sync your font
                  library with your computer.
                </InfoText>

                <DownloadLinks>
                  <a
                    href={process.env.REACT_APP_API_URL + "/download/mac"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="download-link"
                  >
                    <img src={DownloadMac} alt="" />
                  </a>
                  <a
                    href={process.env.REACT_APP_API_URL + "/download/win"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="download-link"
                  >
                    <img src={DownloadWindows} alt="" />
                  </a>
                </DownloadLinks>
              </InfoItem>
            )}

            {step === 3 && (
              <InfoItem>
                <InfoText>That’s it. Start using your new fonts.</InfoText>
              </InfoItem>
            )}
          </InfoInner>
        </Info>
      </HowRow>
    </HowContainer>
  );
};

const HowContainer = styled.div`
  width: 85%;
  margin: auto;
  margin-bottom: 60px;
  max-width: 2000px;

  ${respondTo("medium")} {
    margin-bottom: 100px;
  }

  ${respondTo("large")} {
    width: 100%;
    margin-left: 0;
  }

  ${respondTo("xlarge")} {
    margin-bottom: 200px;
  }
`;

const HowRow = styled.div`
  display: flex;
  flex-direction: column;

  ${respondTo("medium")} {
    flex-direction: row;
  }
`;

const InstructionsContainer = styled.div`
  position: relative;
  z-index: 2;
  margin-bottom: ${(props) => props.theme.space};

  ${respondTo("medium")} {
    width: 211px;
    margin-right: 11px;
    margin-bottom: 0px;
  }

  ${respondTo("xlarge")} {
    width: 412px;
    margin-right: ${(props) => props.theme.space};
  }
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize_l};
  line-height: 23px;
  margin-bottom: 19px;
  color: ${(props) => props.theme.colors.grayDark};

  ${respondTo("medium")} {
    font-size: ${(props) => props.theme.fontSize_xl};
    line-height: 28px;
  }

  ${respondTo("xlarge")} {
    font-size: ${(props) => props.theme.fontSize_xxl};
    line-height: 50px;
    margin-bottom: ${(props) => props.theme.space};
  }
`;

const Instruction = styled.button`
  cursor: pointer;
  border-radius: 15px;
  background-color: ${(props) => props.theme.colors.gray};
  display: flex;
  z-index: 2;
  align-items: center;
  transition: 0.3s ease-in-out;
  padding: 8px 12px;
  margin-bottom: 16px;
  width: 211px;
  max-width: 320px;

  &:last-of-type {
    margin-bottom: 0px;
  }

  .instruction__number {
    background-color: ${(props) => props.theme.colors.gray};
    box-shadow: -3px -6px 30px #fafbf9, 3px 6px 30px #bcbcbc;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 32px;
    color: ${(props) => props.theme.colors.blackLight};
    font-size: ${(props) => props.theme.fontSize};
    font-weight: 600;
    flex-shrink: 0;
    transition: 0.3s ease-in-out;
  }

  .instruction__text {
    font-weight: 600;
    font-size: ${(props) => props.theme.fontSize_s};
    margin-bottom: 0px;
    color: ${(props) => props.theme.colors.blackLight};
    transition: 0.3s ease-in-out;
  }

  &:hover,
  &:focus,
  &.-active {
    width: 100%;
  }

  ${respondTo("medium")} {
    max-width: none;

    .instruction__number {
      font-size: ${(props) => props.theme.fontSize_l};
      margin-right: 20px;
    }

    .instruction__text {
      font-size: ${(props) => props.theme.fontSize};
    }

    &.-active {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      width: 240px;
    }
  }

  ${respondTo("xlarge")} {
    width: 412px;
    padding: 20px;
    margin-bottom: ${(props) => props.theme.space};

    .instruction__text {
      font-size: ${(props) => props.theme.fontSize_xl};
    }

    .instruction__number {
      width: 60px;
      height: 60px;
      font-size: ${(props) => props.theme.fontSize_xl};
      margin-right: 32px;
    }

    &.-active {
      width: 464px;
    }
  }
`;

const Instruction1 = styled(Instruction)`
  background-color: ${(props) => props.theme.colors.grayDark};

  .instruction__number {
    background-color: ${(props) => props.theme.colors.grayDark};
    box-shadow: 3px 6px 50px rgba(39, 45, 49, 0.5),
      -3px -6px 45px rgba(221, 235, 226, 0.5);
    color: ${(props) => props.theme.colors.gray};
  }

  .instruction__text {
    color: ${(props) => props.theme.colors.gray};
  }

  &:hover,
  &:focus,
  &.-active {
    .instruction__number {
      color: ${(props) => props.theme.colors.white};
    }

    .instruction__text {
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

const Instruction2 = styled(Instruction)`
  background-color: ${(props) => props.theme.colors.pumice};

  .instruction__number {
    background-color: ${(props) => props.theme.colors.pumice};
    box-shadow: -3px -6px 30px #dfebe3, 3px 6px 30px #a4afa9;
    color: ${(props) => props.theme.colors.grayDark};
  }

  .instruction__text {
    color: ${(props) => props.theme.colors.grayDark};
  }

  &:hover,
  &:focus,
  &.-active {
    .instruction__number {
      color: ${(props) => props.theme.colors.blackLight};
    }

    .instruction__text {
      color: ${(props) => props.theme.colors.blackLight};
    }
  }
`;

const Instruction3 = styled(Instruction)`
  background-color: ${(props) => props.theme.colors.gray};

  .instruction__number {
    background-color: ${(props) => props.theme.colors.gray};
    box-shadow: -3px -6px 30px #fafbf9, 3px 6px 30px #bcbcbc;
    color: ${(props) => props.theme.colors.grayDark};
  }

  .instruction__text {
    color: ${(props) => props.theme.colors.grayDark};
  }

  &:hover,
  &:focus,
  &.-active {
    .instruction__number {
      color: ${(props) => props.theme.colors.blackLight};
    }

    .instruction__text {
      color: ${(props) => props.theme.colors.blackLight};
    }
  }
`;

const Info = styled.div`
  background-color: white;
  padding: 33px 35px;
  border-radius: 15px;
  /*text-align: center;*/
  border-left: 28px solid ${(props) => props.theme.colors.grayDark};
  width: 100%;
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: 0.3s ease-in-out;

  ${respondTo("medium")} {
    padding: 10px 60px;
    height: auto;
    min-height: auto;
  }

  ${respondTo("large")} {
    margin-right: 7%;
  }

  ${respondTo("xlarge")} {
    padding: 10px 70px;
  }

  ${respondTo("xxlarge")} {
    padding: 10px 90px;
  }

  &.info--2 {
    border-color: ${(props) => props.theme.colors.pumice};
  }

  &.info--3 {
    border-color: ${(props) => props.theme.colors.gray};
  }
`;

const InfoInner = styled.div`
  /* max-width: 500px;

  ${respondTo("medium")} {
    max-width: 621px;
  }

  ${respondTo("xlarge")} {
    max-width: 621px;
  } */
`;

const InfoItem = styled.div``;

const InfoText = styled.p`
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize_l};
  line-height: 28px;
  margin-bottom: 0px;
  color: ${(props) => props.theme.colors.black};

  ${respondTo("medium")} {
    font-size: ${(props) => props.theme.fontSize_xl};
    line-height: 34px;
  }

  ${respondTo("xlarge")} {
    font-size: ${(props) => props.theme.fontSize_xxl};
    line-height: 50px;
  }
`;

const DownloadLinks = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-top: ${(props) => props.theme.space};

  .download-link {
    max-width: 148px;
  }

  .download-link:first-of-type {
    margin-right: 9px;
  }

  ${respondTo("medium")} {
    margin-top: 26px;

    .download-link:first-of-type {
      margin-right: 12px;
    }
  }

  ${respondTo("medium")} {
    margin-top: 26px;

    .download-link:first-of-type {
      margin-right: 18px;
    }
  }

  ${respondTo("xlarge")} {
    margin-top: 30px;

    .download-link:first-of-type {
      margin-right: ${(props) => props.theme.space};
    }
  }
`;

export default HowSection;
