import React, { useState } from "react";
import { PoseGroup } from "react-pose";

import FormWrapper from "./FormWrapper";
import FormButton from "./FormButton";
import FormMessage from "../../../components/FormMessage";
import FormAction, { FormActionContainer } from "./FormAction";
import FormIntro from "./FormIntro";
import Spinner from "../../../components/Spinner";
import Input, { InputField } from "../../../components/Input";
import Label from "../../../components/Label";

const Password = ({
  login,
  error,
  handleForgot,
  isLoading,
  isReset,
  handleReset,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      if (isReset) {
        await handleReset(password);
      } else {
        await handleForgot(email);
      }

      setSuccess(true);
    } catch (err) {}
  };

  return (
    <>
      <FormWrapper>
        <form onSubmit={handleSubmit}>
          <FormIntro>
            {isReset
              ? "Reset password"
              : "We'll send you an email with a link to reset your password."}
          </FormIntro>

          {success ? (
            <FormIntro>
              {isReset
                ? "Success! You may now log in with your new password."
                : "Sent! Check your inbox for that email."}
            </FormIntro>
          ) : (
            <>
              {isReset ? (
                <InputField>
                  <Label htmlFor="password">New Password</Label>
                  <Input
                    className="no-track"
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    required
                  />
                  <PoseGroup>
                    {error ? (
                      <FormMessage key="error">{error}</FormMessage>
                    ) : null}
                  </PoseGroup>
                </InputField>
              ) : (
                <InputField>
                  <Label htmlFor="email">Email</Label>
                  <Input
                    className="no-track"
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    required
                  />
                  <PoseGroup>
                    {error ? (
                      <FormMessage key="error">{error}</FormMessage>
                    ) : null}
                  </PoseGroup>
                </InputField>
              )}
              <FormActionContainer>
                <FormAction type="submit" disabled={isLoading}>
                  {isLoading ? <Spinner /> : isReset ? "Submit" : "Send"}
                </FormAction>
              </FormActionContainer>
            </>
          )}
        </form>
      </FormWrapper>
      <FormButton onClick={login}>Log in</FormButton>
    </>
  );
};

export default Password;
