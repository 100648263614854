import React, { useState, useEffect } from "react";
import { PoseGroup } from "react-pose";

import { useAuth } from "../../../context/AuthContext";

import Accordion from "./Accordion";
import Table from "./Table";
import Input, { InputField } from "./Input";
import Button from "./Button";
import RemoveButton from "./RemoveButton";
import Panel, { PanelLeft } from "./Panel";
import FormMessage from "../../../components/FormMessage";

const TeamPanel = ({ isOpen, handleActive }) => {
  const { user, changeTeamName, removeTeamMember, addTeamMember } = useAuth();

  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [isPanelOpen, setIsPanelOpen] = useState(isOpen);

  useEffect(() => {
    setIsPanelOpen(isOpen);
  }, [isOpen]);

  const handleInvite = async (e) => {
    e.preventDefault();

    const emailToInvite = email;

    try {
      setError(null);

      await addTeamMember(emailToInvite);

      // if successful, reset the field
      setEmail("");
    } catch (err) {
      // if it fails, set the email back to what it was
      setEmail(emailToInvite);

      if (err.data && err.data.errors) {
        setError(err.data.errors.email[0]);
      }
    }
  };

  return (
    <Accordion
      title={user.team.name || "Team"}
      id="team-panel"
      handleEdit={(newName) => {
        changeTeamName(newName);
      }}
      isEditable
      isOpen={isPanelOpen}
      handleActive={handleActive}
    >
      <Panel>
        <PanelLeft>
          <form onSubmit={handleInvite}>
            <InputField>
              <Input
                placeholder="Enter Email"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                disabled={!user.isActive}
              />

              <PoseGroup>
                {error ? <FormMessage key="email">{error}</FormMessage> : null}
              </PoseGroup>
            </InputField>
            <Button type="submit" disabled={!email || !user.isActive}>
              Send Invite
            </Button>
          </form>
        </PanelLeft>

        <Table>
          <thead>
            <tr>
              <th>Team Member</th>
              <th className="condensed">Fonts In Use</th>
              <th className="center condensed">Remove</th>
            </tr>
          </thead>
          <tbody>
            {user.team.members && user.team.members.length ? (
              user.team.members.map((invite) => (
                <tr key={invite.email || 0}>
                  <td>
                    {invite.member ? invite.member.name : invite.email}
                    {invite.valid ? " (pending)" : null}
                  </td>
                  <td>
                    <b>
                      {invite.member ? invite.member.fontsOwned.length : ""}
                    </b>
                  </td>
                  <td className="center">
                    <RemoveButton
                      onClick={() => {
                        removeTeamMember(invite.email);
                      }}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3">Team is empty.</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Panel>
    </Accordion>
  );
};

export default TeamPanel;
