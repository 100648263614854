import React from "react";

const LogoImage = () => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.77955 36.4854H1.53517C0.969337 36.4854 0.514648 36.0307 0.514648 35.4649V1.53508C0.514648 0.969245 0.969337 0.514557 1.53517 0.514557H7.77955C10.1136 0.514557 12.0132 2.40404 12.0132 4.74821V32.2518C12.0132 34.596 10.1136 36.4854 7.77955 36.4854Z"
        fill="#838B91"
      />
      <path
        d="M36.4856 1.53508V16.4387C36.4856 17.0046 36.0309 17.4593 35.4651 17.4593H18.3284C15.9943 17.4593 14.0947 15.5698 14.0947 13.2256V4.74821C14.0947 2.41414 15.9842 0.514557 18.3284 0.514557H35.4651C36.0309 0.514557 36.4856 0.969245 36.4856 1.53508Z"
        fill="#838B91"
      />
      <path
        d="M35.4649 36.4855H30.5442C28.2101 36.4855 26.3105 34.596 26.3105 32.2519V23.7745C26.3105 21.4404 28.2 19.5408 30.5442 19.5408H35.4649C36.0308 19.5408 36.4855 19.9955 36.4855 20.5613V35.465C36.4855 36.0308 36.0308 36.4855 35.4649 36.4855Z"
        fill="#838B91"
      />
      <path
        d="M23.2289 36.4855H15.1152C14.5494 36.4855 14.0947 36.0308 14.0947 35.465V23.7745C14.0947 21.4404 15.9842 19.5408 18.3284 19.5408H23.2289C23.7947 19.5408 24.2494 19.9955 24.2494 20.5613V35.465C24.2494 36.0308 23.7947 36.4855 23.2289 36.4855Z"
        fill="#838B91"
      />
    </svg>
  );
};

export default LogoImage;
