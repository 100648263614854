import React from "react";

const CloseIcon = () => {
  return (
    <svg
      width="51"
      height="51"
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="25.4558"
        cy="25.4558"
        r="17"
        transform="rotate(45 25.4558 25.4558)"
        fill="#E3E4E0"
        stroke="#838B91"
        strokeWidth="2"
      />
      <path
        d="M31.1127 19.799L19.799 31.1127M19.799 19.799L31.1127 31.1127"
        stroke="#838B91"
        strokeWidth="2"
      />
    </svg>
  );
};

export default CloseIcon;
