import React, { useState } from "react";
import styled, { css } from "styled-components";
import { rem } from "polished";
import { listReset, respondTo } from "../../../styles/styleHelpers";
import { compareAsc } from "date-fns";

import ListItem from "./ListItem";
import Filters from "./Filters";

const FontList = ({ fonts, fontAction, disabled, isPremium }) => {
  const [sampleText, setSampleText] = useState("");
  const [gridView, setGridView] = useState(false);
  const [sortAlpha, setSortAlpha] = useState(false);
  const [display, setDisplay] = useState("all");

  function dateSort(a, b) {
    return compareAsc(new Date(b.added), new Date(a.added));
  }

  function alphaSort(a, b) {
    return a.name.toUpperCase() < b.name.toUpperCase()
      ? -1
      : a.name.toUpperCase() > b.name.toUpperCase()
      ? 1
      : 0;
  }

  // apply filters
  const filteredFonts = fonts
    .filter((font) => {
      // whether to display fonts owned, not owned, or all
      if (display === "added") {
        return font.owned;
      } else if (display === "not-added") {
        return !font.owned;
      } else {
        return font;
      }
    })
    // sort by aplha or date
    .sort(sortAlpha ? alphaSort : dateSort);

  return (
    <>
      <Filters
        gridView={gridView}
        setGridView={setGridView}
        sortAlpha={sortAlpha}
        setSortAlpha={setSortAlpha}
        display={display}
        setDisplay={setDisplay}
        sampleText={sampleText}
        setSampleText={setSampleText}
      />

      {filteredFonts.length > 0 ? (
        <Container grid={gridView}>
          {filteredFonts.map((font) => {
            return (
              <ListItem
                key={font.slug}
                font={font}
                action={fontAction}
                grid={gridView}
                sampleText={sampleText}
                disabled={disabled && !font.owned && !font.selected}
                isPremium={isPremium}
              />
            );
          })}
        </Container>
      ) : (
        <NoFontsMessage>There are no fonts available</NoFontsMessage>
      )}
    </>
  );
};

const Container = styled.ul`
  ${listReset()};
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 50px;

  ${(props) =>
    props.grid
      ? css`
          border: 2px solid ${(props) => props.theme.colors.gray};
          border-radius: ${(props) => props.theme.borderRadius};
          overflow: hidden;
        `
      : ``}

  ${respondTo("xlarge")} {
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 60px;
  }
`;

const NoFontsMessage = styled.p`
  font-weight: bold;
  letter-spacing: 0.01em;
  color: ${(props) => props.theme.colors.grayDark};
  font-size: ${rem("21px")};
  line-height: 1.5;
  margin-top: ${(props) => props.theme.space};
  text-align: center;

  ${respondTo("xlarge")} {
    margin-top: 63px;
  }
`;

export default FontList;
