import React, { useState } from "react";
import styled from "styled-components";
import Swiper from "react-id-swiper";
import "../../../vendor/swiper/swiper.css";
import { respondTo } from "../../../styles/styleHelpers";

const UnlimitedSection = ({ images }) => {
  const [swiper, setSwiper] = useState(null);

  const goNext = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  const params = {
    effect: "fade",
    speed: 1500,
    loop: true,
    allowTouchMove: false,
    autoplay: {
      delay: 5000,
    },
    navigation: {
      nextEl: ".font-slider__prev",
      prevEl: ".font-slider__next",
    },
  };

  return (
    <UnlimitedContainer>
      <UnlimitedSlider>
        <Swiper getSwiper={setSwiper} {...params}>
          {images.map((image, index) => (
            <Slide key={index}>
              <SlideImage src={image} alt=""></SlideImage>
            </Slide>
          ))}
        </Swiper>
      </UnlimitedSlider>

      <UnlimitedInfo>
        <UnlimitedInfoInner>
          <h2>
            Unlimited personal and commercial use. <strong>Forever.</strong>
          </h2>
          <p>
            Members get access to a library of custom fonts that won't
            disappear, plus unlimited font licensing for as long as you're a
            member.
          </p>
        </UnlimitedInfoInner>

        {images.length > 1 ? (
          <SwiperNavigation>
            <button
              className="font-slider__button font-slider__prev"
              onClick={goPrev}
            ></button>
            <button
              className="font-slider__button font-slider__next"
              onClick={goNext}
            ></button>
          </SwiperNavigation>
        ) : null}
      </UnlimitedInfo>
    </UnlimitedContainer>
  );
};

const UnlimitedContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: auto;
  margin-bottom: 76px;
  max-width: 2000px;

  ${respondTo("medium")} {
    flex-direction: row;
    margin-bottom: 100px;
  }

  ${respondTo("large")} {
    width: 100%;
    margin-bottom: 100px;
    margin-left: 0;
  }

  ${respondTo("xlarge")} {
    margin-bottom: 241px;
  }
`;

const UnlimitedInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${respondTo("medium")} {
    align-items: flex-start;
  }

  ${respondTo("large")} {
    margin-right: 7%;
  }
`;

const UnlimitedInfoInner = styled.div`
  background-color: white;
  border-radius: 15px;
  color: ${(props) => props.theme.colors.black};
  padding: 37px 47px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  h2 {
    font-size: ${(props) => props.theme.fontSize_l};
    line-height: 24px;
    letter-spacing: 0.03em;
    margin-bottom: 17px;
  }

  p {
    font-size: ${(props) => props.theme.fontSize_xs};
    line-height: 1.6;
    letter-spacing: 0.03em;
    margin-bottom: 0px;
  }

  ${respondTo("medium")} {
    padding: 22px 49px;
    flex-grow: 1;

    h2 {
      margin-bottom: 7px;
    }
  }

  ${respondTo("xlarge")} {
    padding: 52px 56px;

    h2 {
      font-size: ${(props) => props.theme.fontSize_xxl};
      line-height: 50px;
      margin-bottom: 20px;
    }

    p {
      font-size: ${(props) => props.theme.fontSize_l};
    }
  }

  ${respondTo("xlarge")} {
    padding: 52px 90px;
  }
`;

const UnlimitedSlider = styled.div`
  margin-bottom: 16px;

  ${respondTo("medium")} {
    width: 209px;
    margin-right: 11px;
    margin-bottom: 0px;
  }

  ${respondTo("large")} {
    width: 30%;
    margin-right: 16px;
  }

  ${respondTo("xlarge")} {
    width: 412px;
    margin-right: ${(props) => props.theme.space};
  }
`;

const SwiperNavigation = styled.div`
  justify-content: flex-end;
  margin-top: 21px;
  display: flex;

  .font-slider__button,
  .font-slider__button {
    position: relative;
    margin-top: 0;
    background-image: none;
    width: 39px;
    height: 39px;
    display: inline-block;
    background-color: ${(props) => props.theme.colors.yellow};
    border-radius: 50%;
    transition: 0.3s ease-in-out;

    &::before {
      position: absolute;
      content: "";
      display: block;
      width: 0px;
      height: 0px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      top: 10px;
      transition: 0.3s ease-in-out;
    }

    &:hover {
      background-color: white;
      box-shadow: ${(props) => props.theme.shadow};
    }
  }

  .font-slider__prev {
    margin-right: 8px;

    &::before {
      border-right: 10px solid white;
      left: 13px;
    }

    &:hover {
      &::before {
        border-right: 10px solid ${(props) => props.theme.colors.yellow};
      }
    }
  }

  .font-slider__next {
    &::before {
      border-left: 10px solid white;
      left: 16px;
    }

    &:hover {
      &::before {
        border-left: 10px solid ${(props) => props.theme.colors.yellow};
      }
    }
  }

  ${respondTo("medium")} {
    transform: scale(0.7);
    transform-origin: bottom;
    margin-top: 8px;
    margin-left: 34px;
  }

  ${respondTo("xlarge")} {
    transform: scale(1);
    margin-top: ${(props) => props.theme.space};
    margin-left: 0px;
  }
`;

const Slide = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  height: 0px;
  padding-bottom: 85%;

  ${respondTo("medium")} {
    padding-bottom: 96%;
  }

  ${respondTo("large")} {
    padding-bottom: 98%;
  }

  ${respondTo("xlarge")} {
    height: 349px;
    padding-bottom: 0px;
  }
`;

const SlideImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default UnlimitedSection;
