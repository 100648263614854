import React, { useState } from "react";
import styled from "styled-components";
import { respondTo } from "../../styles/styleHelpers";

import { useAuth } from "../../context/AuthContext";

import UserSection from "./components/UserSection";
import TeamPanel from "./components/TeamPanel";
import ReferralsPanel from "./components/ReferralsPanel";
import ProjectsPanel from "./components/ProjectsPanel";
import PaymentPanel from "./components/PaymentPanel";

const Membership = () => {
  const { user, isPremium, isTeamMember } = useAuth();
  const [teamOpen, setTeamOpen] = useState(false);

  return user ? (
    <Container>
      <UserSection setTeamOpen={setTeamOpen} />

      {isPremium && !isTeamMember ? (
        <TeamPanel
          isOpen={teamOpen}
          handleActive={(isOpen) => {
            setTeamOpen(isOpen);
          }}
        />
      ) : null}

      <ReferralsPanel />

      <ProjectsPanel />

      {/* show for all users except team members */}
      {!isTeamMember ? <PaymentPanel /> : null}
    </Container>
  ) : null;
};

const Container = styled.div`
  margin-bottom: 50px;

  ${respondTo("xlarge")} {
    margin-bottom: 60px;
  }
`;

export default Membership;
