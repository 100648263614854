import React from "react";

const Twitter = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M27.3329 5.72995C26.3517 6.16507 25.2974 6.45921 24.1906 6.59143C25.3202 5.91436 26.1877 4.84217 26.5962 3.56462C25.5391 4.19159 24.3682 4.6469 23.122 4.89228C22.1241 3.82895 20.7022 3.16455 19.1287 3.16455C16.1071 3.16455 13.6575 5.61412 13.6575 8.6354C13.6575 9.06424 13.7059 9.48173 13.7992 9.88219C9.25231 9.65405 5.22104 7.47597 2.52267 4.16596C2.05174 4.97401 1.78198 5.91379 1.78198 6.91644C1.78198 8.81448 2.74785 10.4891 4.21582 11.4702C3.31901 11.4418 2.47543 11.1957 1.73777 10.7859C1.73739 10.8087 1.73731 10.8316 1.73731 10.8546C1.73731 13.5054 3.62315 15.7167 6.12596 16.2192C5.66684 16.3442 5.18351 16.4111 4.68458 16.4111C4.33203 16.4111 3.98931 16.3768 3.65525 16.313C4.35146 18.4865 6.37191 20.0683 8.76595 20.1125C6.89353 21.5798 4.53455 22.4544 1.97134 22.4544C1.52974 22.4544 1.09424 22.4285 0.66626 22.378C3.08744 23.9303 5.96317 24.836 9.05275 24.836C19.1159 24.836 24.6188 16.4995 24.6188 9.2698C24.6188 9.03261 24.6134 8.79666 24.603 8.56205C25.6718 7.79068 26.5994 6.82709 27.3329 5.72995Z"
          fill="#838B91"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="26.6666"
            height="26.6666"
            fill="white"
            transform="translate(0.66626 0.666992)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Twitter;
