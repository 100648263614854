import React from "react";
import styled from "styled-components";
import { rem } from "polished";

import Mastercard from "../../../components/icons/Mastercard";
import Visa from "../../../components/icons/Visa";
import Amex from "../../../components/icons/Amex";
import Discover from "../../../components/icons/Discover";

import { respondTo } from "../../../styles/styleHelpers";

const CreditCard = ({ cardType, lastFour, children }) => {
  return (
    <Container>
      <Icon>
        {cardType === "visa" ? (
          <Visa />
        ) : cardType === "mastercard" ? (
          <Mastercard />
        ) : cardType === "amex" ? (
          <Amex />
        ) : cardType === "discover" ? (
          <Discover />
        ) : null}
      </Icon>

      <CardInner>
        {children ? (
          children
        ) : (
          <>
            <Group>
              <Bullet />
              <Bullet />
              <Bullet />
              <Bullet />
            </Group>
            <Group>
              <Bullet />
              <Bullet />
              <Bullet />
              <Bullet />
              {cardType === "amex" ? (
                <>
                  <Bullet />
                  <Bullet />
                </>
              ) : null}
            </Group>
            {cardType !== "amex" ? (
              <Group>
                <Bullet />
                <Bullet />
                <Bullet />
                <Bullet />
              </Group>
            ) : null}
            <Group>
              {cardType === "amex" ? <Bullet /> : null}
              {lastFour ? (
                ("" + lastFour)
                  .split("")
                  .map((number, index) => (
                    <Digit key={`${number}-${index}`}>{number}</Digit>
                  ))
              ) : (
                <>
                  <Bullet />
                  <Bullet />
                  <Bullet />
                  <Bullet />
                </>
              )}
            </Group>
          </>
        )}
      </CardInner>
    </Container>
  );
};

const Container = styled.div`
  border-radius: 15px;
  background-color: ${(props) => props.theme.colors.gray};
  padding: ${(props) => props.theme.space};
  height: 220px;
  max-width: 500px;

  ${respondTo("medium")} {
    height: 315px;
    padding: 40px;
  }
`;

const CardInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;

  ${respondTo("medium")} {
    margin-top: 108px;
  }

  > * {
    flex-grow: 1;
  }
`;

const Group = styled.div`
  display: inline;
  flex-grow: 0;

  > * {
    margin-right: 5px;

    &:last-of-type {
      margin-right: 0px;
    }

    ${respondTo("medium")} {
      margin-right: 10px;
    }
    ${respondTo("xlarge")} {
      margin-right: 0.33vw;
    }
    ${respondTo("xxlarge")} {
      margin-right: 10px;
    }
  }
`;

const Digit = styled.span`
  display: inline-block;
  color: ${(props) => props.theme.colors.grayDark};
  font-size: ${(props) => props.theme.fontSize_xl};
  font-weight: ${(props) => props.theme.fontWeight_m};
`;

const Bullet = styled.span`
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.grayDark};
  font-size: ${rem("50px")};

  ${respondTo("small")} {
    width: 11px;
    height: 11px;
  }
`;

const Icon = styled.div`
  max-width: 33%;

  svg {
    max-width: 100%;
  }
`;

export default CreditCard;
