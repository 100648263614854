import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { normalize } from "polished";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import theme from "./styles/styles";
import { globalStyles } from "./styles/globalStyles";

import PrivateRoute from "./components/PrivateRoute";

import Wrapper from "./components/Wrapper";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";
import Main, { MainInner } from "./components/Main";
import ScrollToTop from "./components/ScrollToTop";

import { AuthProvider } from "./context/AuthContext";
import { FontsProvider } from "./context/FontsContext";

import Marketing from "./views/marketing/";
import Fonts from "./views/fonts/";
import Font from "./views/font/";
import Membership from "./views/membership/";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

// global styles
const GlobalStyle = createGlobalStyle`
  ${normalize()}
  ${globalStyles}
`;

const App = () => {
  return (
    <>
      <GlobalStyle />

      <ThemeProvider theme={theme}>
        <Router>
          <AuthProvider>
            <FontsProvider>
              <Elements stripe={stripePromise}>
                <ScrollToTop />

                <Wrapper>
                  <Sidebar />

                  <Switch>
                    {/* marketing site */}
                    <Route
                      exact
                      path="/"
                      render={(props) => (
                        <Main>
                          <Marketing {...props} />
                          <Footer />
                        </Main>
                      )}
                    />

                    {/* main fonts dashboard */}
                    <PrivateRoute
                      activeOnly
                      exact
                      path="/fonts"
                      render={(props) => (
                        <Main>
                          <MainInner>
                            <Fonts {...props} />
                          </MainInner>
                          <Footer />
                        </Main>
                      )}
                    />

                    {/* single font */}
                    <PrivateRoute
                      activeOnly
                      exact
                      path="/fonts/:slug"
                      render={(props) => (
                        <Main>
                          <MainInner>
                            <Font {...props} />
                          </MainInner>
                          <Footer />
                        </Main>
                      )}
                    />

                    {/* profile */}
                    <PrivateRoute
                      exact
                      path="/membership"
                      render={(props) => (
                        <Main>
                          <MainInner>
                            <Membership {...props} />
                          </MainInner>
                          <Footer />
                        </Main>
                      )}
                    />

                    <Redirect to="/fonts" />
                  </Switch>
                </Wrapper>
              </Elements>
            </FontsProvider>
          </AuthProvider>
        </Router>
      </ThemeProvider>
    </>
  );
};

export default App;
