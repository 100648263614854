import styled from "styled-components";

import { respondTo } from "../styles/styleHelpers";

const Main = styled.main`
  flex-grow: 1;

  max-width: 100%;

  ${respondTo("large")} {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
`;

export const MainInner = styled.div`
  margin: ${(props) => props.theme.space} 5.5vw;
  margin-top: ${(props) => `${props.theme.toggleHeight + 30}px`};

  ${respondTo("large")} {
    margin-top: 25px;
    margin-left: 0;
    margin-right: ${(props) => props.theme.space};
  }
  ${respondTo("xlarge")} {
    margin-right: 5vw;
  }
`;

export default Main;
