import styled from "styled-components";
import { respondTo } from "../styles/styleHelpers";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  z-index: 1;

  ${respondTo("large")} {
    height: 100vh;
    overflow: hidden;
    max-width: 2000px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export default Wrapper;
