import React from "react";

const Facebook = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7014 26.2227L10.6664 15.1116H6.22192V10.6672H10.6664V7.88941C10.6664 3.76565 13.22 1.77832 16.8987 1.77832C18.6608 1.77832 20.1753 1.90951 20.6166 1.96815V6.27773L18.0653 6.27889C16.0646 6.27889 15.6772 7.22958 15.6772 8.62465V10.6672H21.4997L19.2774 15.1116H15.6772V26.2227H10.7014Z"
        fill="#838B91"
      />
    </svg>
  );
};

export default Facebook;
