import React from "react";
import styled from "styled-components";

const FilterRadio = ({
  id = "radio",
  name = "radio",
  label,
  onChange,
  value = "1",
  checked = false,
  ...rest
}) => (
  <Container {...rest}>
    <Input
      type="radio"
      id={id}
      name={name}
      checked={checked}
      onChange={onChange ? onChange : undefined}
      value={value}
    />
    <Label htmlFor={id}>{label}</Label>
  </Container>
);

const Container = styled.div`
  display: inline-flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0%;
`;

const Label = styled.label`
  font-size: ${(props) => props.theme.fontSize_s};
  font-weight: ${(props) => props.theme.fontWeight_m};
  line-height: 1.285;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${(props) => props.theme.colors.blackLight};
  padding: 15px;
  border-radius: 8px;

  flex-grow: 1;
  white-space: nowrap;

  background: ${(props) => props.theme.colors.pumice};
  box-shadow: 3px 4px 10px #b9c4bd, -4px -3px 10px #dae4de;

  user-select: none;
  cursor: pointer;
  transition: box-shadow 200ms ease-in-out, background-color 200ms ease-in-out,
    color 200ms ease-in-out;

  &:hover {
    color: ${(props) => props.theme.colors.white};
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;

  &:checked {
    + ${Label} {
      color: ${(props) => props.theme.colors.white};
      background-color: ${(props) => props.theme.colors.pumiceDark};
      box-shadow: inset 3px 4px 10px #a1aca5, inset -4px -3px 10px #c5d2ca;
    }
  }

  /* TODO: focus style */
  /* &:focus {
    + ${Label} {
    }
  } */
`;

export default FilterRadio;
