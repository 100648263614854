import React from "react";
import styled from "styled-components";

import { respondTo } from "../../../styles/styleHelpers";

import DownloadMac from "../../../images/download-mac.png";
import DownloadWindows from "../../../images/download-windows.png";

const DownloadBar = () => {
  return (
    <Container>
      <Message>
        Now that you are a member, please download and install the DFC desktop
        app to sync fonts with your device.
      </Message>
      <DownloadLinks>
        <a
          href={process.env.REACT_APP_API_URL + "/download/mac"}
          target="_blank"
          rel="noopener noreferrer"
          className="download-link"
        >
          <img src={DownloadMac} alt="" />
        </a>
        <a
          href={process.env.REACT_APP_API_URL + "/download/win"}
          target="_blank"
          rel="noopener noreferrer"
          className="download-link"
        >
          <img src={DownloadWindows} alt="" />
        </a>
      </DownloadLinks>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.green};
  margin-top: ${(props) => props.theme.space};
  padding: 25px 27px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${respondTo("medium")} {
  }

  ${respondTo("large")} {
    padding: 34px 36px;
    flex-direction: row;
  }
`;

const Message = styled.p`
  color: ${(props) => props.theme.colors.white};
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSize_xl};
  margin-bottom: 0;

  letter-spacing: 0.005em;
  flex: 3;

  ${respondTo("large")} {
    font-size: ${(props) => props.theme.fontSize_xl};
  }

  ${respondTo("xlarge")} {
    flex: 6;
  }
`;

const DownloadLinks = styled.div`
  display: none;

  ${respondTo("large")} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    padding: 0 10px;

    .download-link {
      img {
        display: block;
      }
    }

    .download-link:first-of-type {
      margin-right: 12px;
    }
  }

  ${respondTo("large")} {
    flex-direction: column;
    flex: 2;
    margin-top: 0;

    .download-link:first-of-type {
      margin-right: 0;
    }
  }

  ${respondTo("xlarge")} {
    flex-direction: row;
    flex: 5;

    .download-link:first-of-type {
      margin-right: ${(props) => props.theme.space};
    }
  }
`;

export default DownloadBar;
