//import "../assets${process.env.REACT_APP_API_URL}/fonts${process.env.REACT_APP_API_URL}/fonts.css";

import { colors, typography } from "./styles";
import { respondTo } from "./styleHelpers";

export const globalStyles = `
  /* Global Styles */

  *, *::before, *::after {
    box-sizing: border-box;
  }

  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: "din-2014", -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;
    font-size: 16px;
    color: ${colors.text};
    background-color: ${colors.white};
    line-height: 1.5;
    position: relative;
  }

  a {
    color: ${colors.primary};
    text-decoration: none;
    transition: .15s color ease-in-out;

    &:focus {
      outline: none;
    }
  }

  img {
    height: auto;
    max-width: 100%;
  }

  fieldset {
    padding: 0;
    border: none;
    margin: 0;
  }

  input,
  textarea {
    -webkit-appearance: none;
    border: 0;
    background: transparent;
    color: ${colors.primary};

    /* Hide clear button in IE */
    &::-ms-clear {
      display: none;
    }

    &:focus {
      outline: none;
    }
  }

  textarea {
    resize: none;
    min-height: 10em;
  }

  h1,h2,h3,h4,h5,h6 {
    font-size: 1rem;
    font-weight: ${typography.fontWeight_m};
  }

  h1 {
    font-size: ${typography.fontSize_xxl};

    ${respondTo("medium")}{
      font-size: ${typography.fontSize_xxxl};
    }
  }

  h2 {
    font-size: ${typography.fontSize_xl};

    ${respondTo("medium")}{
      font-size: ${typography.fontSize_xxl};
    }
  }

  h3 {
    font-size: ${typography.fontSize_l};

    ${respondTo("medium")}{
      font-size: ${typography.fontSize_xl};
    }
  }

  h1,h2,h3,h4,h5,h6,hgroup,
  ul,ol,dd,
  p,figure,
  pre,table,hr {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  ul,ol {
    padding-left: 1.5em;
  }

  button {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
    outline: none;
    -webkit-appearance: none;

    &:focus {
      outline: none;
    }
  }

  /* Scroll Bar */
  /* width */
  ::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }

  /* Track */
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-corner {
    background-color: ${colors.white};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colors.primary};
    border-radius: 10px;
    border: 5px solid ${colors.white};
  }

  /* Firefox scrollbar */
  * {
    @-moz-document url-prefix() {
      & {
                  /*     Thumb          Track */
        scrollbar-color: ${colors.primary} ${colors.primary};
        scrollbar-width: thin;
      }
    }
  }

  ::-webkit-input-placeholder {
    color: ${colors.grayDark};
  }

  ::-moz-placeholder {
    color: ${colors.grayDark};
  }

  :-ms-input-placeholder {
    color: ${colors.grayDark};
  }

  :-moz-placeholder {
    color: ${colors.grayDark};
  }

  /* Tools */
  .ttc {
    text-transform: capitalize;
  }

  .tac {
    text-align: center;
  }

  .brc {
    border-radius: 50%;
  }

  /* ALL FONT IMPORTS */
  /* To be replaced with project import */
  @font-face {
    font-family: "Abruzzi";
    src: local("Abruzzi"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1757d4d6aa803f6064bda9.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1757d4d6aa803f6064bda9.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1757d4d6aa803f6064bda9.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-abruzzi {
    font-family: "Abruzzi", sans-serif;
  }

  @font-face {
    font-family: "Abruzzo";
    src: local("Abruzzo"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1758c3d6aa803f6064bdaa.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1758c3d6aa803f6064bdaa.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1758c3d6aa803f6064bdaa.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 600;
  }
  .dfc-abruzzo {
    font-family: "Abruzzo", sans-serif;
  }

  @font-face {
    font-family: "Blonde";
    src: local("Blonde"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1758ddd6aa803f6064bdab.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1758ddd6aa803f6064bdab.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1758ddd6aa803f6064bdab.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 200;
  }
  .dfc-blonde {
    font-family: "Blonde", sans-serif;
  }

  @font-face {
    font-family: "Bravo";
    src: local("Bravo"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1758e8d6aa803f6064bdac.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1758e8d6aa803f6064bdac.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1758e8d6aa803f6064bdac.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-bravo {
    font-family: "Bravo", sans-serif;
  }

  @font-face {
    font-family: "Suisse";
    src: local("Suisse"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1758fdd6aa803f6064bdad.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1758fdd6aa803f6064bdad.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1758fdd6aa803f6064bdad.otf") format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 400;
  }
  .dfc-suisse {
    font-family: "Suisse", sans-serif;
  }

  @font-face {
    font-family: "Fielder";
    src: local("Fielder"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f17590ad6aa803f6064bdae.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f17590ad6aa803f6064bdae.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f17590ad6aa803f6064bdae.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-fielder {
    font-family: "Fielder", sans-serif;
  }

  @font-face {
    font-family: "Filament";
    src: local("Filament"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f175916d6aa803f6064bdaf.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f175916d6aa803f6064bdaf.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f175916d6aa803f6064bdaf.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-filament {
    font-family: "Filament", sans-serif;
  }

  @font-face {
    font-family: "Halliday";
    src: local("Halliday"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f175923d6aa803f6064bdb0.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f175923d6aa803f6064bdb0.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f175923d6aa803f6064bdb0.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-halliday {
    font-family: "Halliday", sans-serif;
  }

  @font-face {
    font-family: "Husky";
    src: local("Husky"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f175932d6aa803f6064bdb1.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f175932d6aa803f6064bdb1.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f175932d6aa803f6064bdb1.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 800;
  }
  .dfc-husky {
    font-family: "Husky", sans-serif;
  }

  @font-face {
    font-family: "Lander";
    src: local("Lander"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f17594fd6aa803f6064bdb2.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f17594fd6aa803f6064bdb2.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f17594fd6aa803f6064bdb2.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-lander {
    font-family: "Lander", sans-serif;
  }

  @font-face {
    font-family: "Master";
    src: local("Master"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f17595fd6aa803f6064bdb3.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f17595fd6aa803f6064bdb3.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f17595fd6aa803f6064bdb3.otf") format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 200;
  }
  .dfc-master {
    font-family: "Master", sans-serif;
  }

  @font-face {
    font-family: "Medici";
    src: local("Medici"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f175971d6aa803f6064bdb4.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f175971d6aa803f6064bdb4.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f175971d6aa803f6064bdb4.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-medici {
    font-family: "Medici", sans-serif;
  }

  @font-face {
    font-family: "Monorail";
    src: local("Monorail"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f17597fd6aa803f6064bdb5.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f17597fd6aa803f6064bdb5.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f17597fd6aa803f6064bdb5.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-monorail {
    font-family: "Monorail", sans-serif;
  }

  @font-face {
    font-family: "Union";
    src: local("Union"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f17598ed6aa803f6064bdb6.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f17598ed6aa803f6064bdb6.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f17598ed6aa803f6064bdb6.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-union {
    font-family: "Union", sans-serif;
  }

  @font-face {
    font-family: "Ramsey";
    src: local("Ramsey"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f175996d6aa803f6064bdb7.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f175996d6aa803f6064bdb7.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f175996d6aa803f6064bdb7.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-ramsey {
    font-family: "Ramsey", sans-serif;
  }

  @font-face {
    font-family: "Portland";
    src: local("Portland"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1759a3d6aa803f6064bdb8.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1759a3d6aa803f6064bdb8.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1759a3d6aa803f6064bdb8.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 200;
  }
  .dfc-portland {
    font-family: "Portland", sans-serif;
  }

  @font-face {
    font-family: "Rayon";
    src: local("Rayon"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1759aed6aa803f6064bdb9.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1759aed6aa803f6064bdb9.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1759aed6aa803f6064bdb9.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-rayon {
    font-family: "Rayon", sans-serif;
  }

  @font-face {
    font-family: "Scout";
    src: local("Scout"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1759b8d6aa803f6064bdba.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1759b8d6aa803f6064bdba.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1759b8d6aa803f6064bdba.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 200;
  }
  .dfc-scout {
    font-family: "Scout", sans-serif;
  }

  @font-face {
    font-family: "Skinny";
    src: local("Skinny"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1759c4d6aa803f6064bdbb.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1759c4d6aa803f6064bdbb.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1759c4d6aa803f6064bdbb.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 200;
  }
  .dfc-skinny {
    font-family: "Skinny", sans-serif;
  }

  @font-face {
    font-family: "Spacer";
    src: local("Spacer"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1759d0d6aa803f6064bdbc.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1759d0d6aa803f6064bdbc.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1759d0d6aa803f6064bdbc.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 800;
  }
  .dfc-spacer {
    font-family: "Spacer", sans-serif;
  }

  @font-face {
    font-family: "Supreme";
    src: local("Supreme"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1759dbd6aa803f6064bdbd.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1759dbd6aa803f6064bdbd.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1759dbd6aa803f6064bdbd.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 600;
  }
  .dfc-supreme {
    font-family: "Supreme", sans-serif;
  }

  @font-face {
    font-family: "Vinyl";
    src: local("Vinyl"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1759ebd6aa803f6064bdbe.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1759ebd6aa803f6064bdbe.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1759ebd6aa803f6064bdbe.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 800;
  }
  .dfc-vinyl {
    font-family: "Vinyl", sans-serif;
  }

  @font-face {
    font-family: "Kaiju";
    src: local("Kaiju"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1759f5d6aa803f6064bdbf.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1759f5d6aa803f6064bdbf.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5f1759f5d6aa803f6064bdbf.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-kaiju {
    font-family: "Kaiju", sans-serif;
  }

  @font-face {
    font-family: "Dodger";
    src: local("Dodger"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5fc55623aa6e7c1cf3b53212.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5fc55623aa6e7c1cf3b53212.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5fc55623aa6e7c1cf3b53212.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-dodger {
    font-family: "Dodger", sans-serif;
  }

  @font-face {
    font-family: "Joyeux";
    src: local("Joyeux"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5fc55c2baa6e7c1cf3b53213.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5fc55c2baa6e7c1cf3b53213.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5fc55c2baa6e7c1cf3b53213.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-joyeux {
    font-family: "Joyeux", sans-serif;
  }

  @font-face {
    font-family: "Regional";
    src: local("Regional"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5feb8759fd2b242c4a51af18.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5feb8759fd2b242c4a51af18.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/5feb8759fd2b242c4a51af18.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-regional {
    font-family: "Regional", sans-serif;
  }

  @font-face {
    font-family: "Ranger";
    src: local("Ranger"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/60180902b115a30b45312eea.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/60180902b115a30b45312eea.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/60180902b115a30b45312eea.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-ranger {
    font-family: "Ranger", sans-serif;
  }

  @font-face {
    font-family: "Public Italic";
    src: local("Public Italic"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/603d150827572a0211904336.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/603d150827572a0211904336.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/603d150827572a0211904336.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-public-italic {
    font-family: "Public Italic", sans-serif;
  }

  @font-face {
    font-family: "Cosmonaut";
    src: local("Cosmonaut"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/6065d19185cf6f18430efce7.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/6065d19185cf6f18430efce7.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/6065d19185cf6f18430efce7.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-cosmonaut {
    font-family: "Cosmonaut", sans-serif;
  }

  @font-face {
    font-family: "Bueno";
    src: local("Bueno"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/608ffaaa02bfff10154283ce.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/608ffaaa02bfff10154283ce.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/608ffaaa02bfff10154283ce.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-bueno {
    font-family: "Bueno", sans-serif;
  }

  @font-face {
    font-family: "Liner";
    src: local("Liner"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/60ae54933a8f8607bb1bc0ca.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/60ae54933a8f8607bb1bc0ca.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/60ae54933a8f8607bb1bc0ca.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-liner {
    font-family: "Liner", sans-serif;
  }

  @font-face {
    font-family: "Public";
    src: local("Public"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/60dde4e9da92b2322e98e14e.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/60dde4e9da92b2322e98e14e.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/60dde4e9da92b2322e98e14e.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-public {
    font-family: "Public", sans-serif;
  }

  @font-face {
    font-family: "Crater";
    src: local("Crater"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/61042d319f3a3c94a8312afc.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/61042d319f3a3c94a8312afc.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/61042d319f3a3c94a8312afc.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-crater {
    font-family: "Crater", sans-serif;
  }

  @font-face {
    font-family: "Haber";
    src: local("Haber"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/612f7d0cf4d25e265d19a6b0.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/612f7d0cf4d25e265d19a6b0.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/612f7d0cf4d25e265d19a6b0.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-haber {
    font-family: "Haber", sans-serif;
  }

  @font-face {
    font-family: "Cacti";
    src: local("Cacti"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/61562a137f3fc9c218865ec3.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/61562a137f3fc9c218865ec3.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/61562a137f3fc9c218865ec3.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-cacti {
    font-family: "Cacti", sans-serif;
  }

  @font-face {
    font-family: "Gemini";
    src: local("Gemini"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/617c2f7e7b2c49446bed01bb.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/617c2f7e7b2c49446bed01bb.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/617c2f7e7b2c49446bed01bb.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-gemini {
    font-family: "Gemini", sans-serif;
  }

  @font-face {
    font-family: "Rancho";
    src: local("Rancho"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/61a77fe85a4b8207d2400b9e.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/61a77fe85a4b8207d2400b9e.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/61a77fe85a4b8207d2400b9e.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-rancho {
    font-family: "Rancho", sans-serif;
  }

  @font-face {
    font-family: "Cacti Italic";
    src: local("Cacti Italic"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/61d5a33c2133343dccaffbff.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/61d5a33c2133343dccaffbff.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/61d5a33c2133343dccaffbff.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-cacti-italic {
    font-family: "Cacti Italic", sans-serif;
  }

  @font-face {
    font-family: "Mentho";
    src: local("Mentho"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/61f981a65d6c3d64bea687be.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/61f981a65d6c3d64bea687be.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/61f981a65d6c3d64bea687be.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-mentho {
    font-family: "Mentho", sans-serif;
  }

  @font-face {
    font-family: "Crater Italic";
    src: local("Crater Italic"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/621cdfd3b44b7560d4a01663.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/621cdfd3b44b7560d4a01663.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/621cdfd3b44b7560d4a01663.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-crater-italic {
    font-family: "Crater Italic", sans-serif;
  }
  
  @font-face {
    font-family: "Supreme Sharp";
    src: local("Supreme Sharp"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/625f25a311f6b6a1b6938695.woff2") format("woff2"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/625f25a311f6b6a1b6938695.woff") format("woff"),
      url("${
        process.env.REACT_APP_API_URL
      }/font/625f25a311f6b6a1b6938695.otf") format("opentype");
    font-display: auto;
    font-style: regular;
    font-weight: 400;
  }
  .dfc-supreme-sharp {
    font-family: "Supreme Sharp", sans-serif;
  }
`;
