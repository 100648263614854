import React from "react";

const Check = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18" r="18" fill="#465A68" />
      <path
        d="M11.5 18.7308L15.7696 23L25.0003 13"
        stroke="#FAFBF9"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Check;
