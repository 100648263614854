import styled from "styled-components";

const ArrowBtn = styled.button`
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.pumice};
  border-radius: 50%;
  flex-shrink: 0;

  transition: background-color 200ms ease;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: ${(props) =>
      props.back
        ? `translate(-35%, -50%) rotate(135deg)`
        : `translate(-65%, -50%) rotate(-45deg)`};
    width: 11px;
    height: 11px;
    border: 2px solid ${(props) => props.theme.colors.blackLight};
    border-top: 0;
    border-left: 0;

    transition: border-color 200ms ease;
  }

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.pumiceDark};

    &::before {
      border-color: ${(props) => props.theme.colors.white};
    }
  }
`;

export default ArrowBtn;
