import React from "react";
import styled from "styled-components";

import { respondTo } from "../../../styles/styleHelpers";
import { getCurrency } from "../../../utils/utils";
import { useFonts } from "../../../context/FontsContext";

const IntroInfo = () => {
  const { fonts } = useFonts();

  return (
    <IntroContainer>
      <Title>
        Fonts for everyone.
        <br />
        Less than a dollar per month.
      </Title>

      <p className="subtitle">
        Access {fonts.length || ""} exclusive fonts only found at Dollar Font
        Club. Join the club for high-quality typography used by studio
        designers, freelancers, students, and everyone in-between for a monthly
        cost of only{" "}
        {fonts.length
          ? getCurrency(process.env.REACT_APP_PREMIUM_PRICE / fonts.length)
          : ""}{" "}
        per font.
      </p>

      <p>
        <strong>Monthly Fonts:</strong> At least one new font every month. Who
        knows, some months we might get crazy.
      </p>
      <p>
        <strong>Simple Pricing:</strong> Beautiful fonts accessible to anyone is
        our mission. Straightforward pricing is our game.
      </p>
      <p>
        <strong>Always Yours:</strong> We create the fonts, we keep the fonts.
        Members never worry about fonts disappearing.
      </p>
    </IntroContainer>
  );
};

const IntroContainer = styled.div`
  background-color: white;
  padding: 23px 47px;
  border-radius: 15px;
  color: ${(props) => props.theme.colors.black};
  z-index: 2;
  width: 100%;
  margin-bottom: ${(props) => props.theme.space};

  p {
    font-size: ${(props) => props.theme.fontSize_xs};
    line-height: 22px;
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }

  .subtitle {
    font-weight: bold;
    letter-spacing: 0.02em;
    font-size: ${(props) => props.theme.fontSize_xs};
    line-height: 20px;
    margin-bottom: 14px;
  }

  ${respondTo("medium")} {
    /* width: 62%; */
    padding: 32px 53px;
    margin-right: 30px;
    margin-bottom: 0px;

    .subtitle {
      font-size: ${(props) => props.theme.fontSize_s};
      line-height: 23px;
      margin-bottom: 20px;
    }
  }

  ${respondTo("large")} {
    /* width: 62%; */
  }

  ${respondTo("xlarge")} {
    padding: 70px 95px 95px 95px;

    .subtitle {
      font-size: 24px;
      line-height: 31px;
      margin-bottom: 65px;
    }

    p {
      font-size: ${(props) => props.theme.fontSize_l};
      line-height: 24px;
      margin-bottom: 20px;
    }
  }

  ${respondTo("xxlarge")} {
    padding: 50px 90px 70px;
  }
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 10px;

  ${respondTo("medium")} {
    font-size: 28px;
    line-height: 31px;
    margin-bottom: 21px;
  }

  ${respondTo("xlarge")} {
    font-size: ${(props) => props.theme.fontSize_xxxl};
    line-height: 60px;
    margin-bottom: 65px;
  }
`;

export default IntroInfo;
