import React, { useEffect, useState, useContext } from "react";
import { withRouter, useHistory } from "react-router-dom";
import store from "store";
import qs from "qs";

import {
  checkLogin,
  getUser,
  createUser,
  checkDiscountOnServer,
  updateUsersName,
  addProjectToServer,
  updateProjectOnServer,
  deleteProjectOnServer,
  getBillingDetails,
  updateBillingDetails,
  sendReferral,
  getUserReferrals,
  getTeamInvites,
  sendTeamInvite,
  deleteTeamInvite,
  renameTeam,
  updateSubscription,
  cancelSubscription,
} from "../utils/api";

const AuthContext = React.createContext();

const AuthProvider = (props) => {
  const history = useHistory();

  // for initial authentication
  const [authenticating, setAuthenticating] = useState(true);

  const [user, setUser] = useState(null);
  const [isNew, setIsNew] = useState(false);
  const [isPremium, setIsPremium] = useState(false);
  const [isTeamMember, setIsTeamMember] = useState(false);

  //These are functions passed in from the footer modals so we can use them in the sidebar during signup
  const [policyCallback, setPolicyCallback] = useState(null);
  const [eulaCallback, setEulaCallback] = useState(null);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [sidebarSignupCallback, setSidebarSignupCallback] = useState(null);
  const [sidebarLoginCallback, setSidebarLoginCallback] = useState(null);
  const [params, setParams] = useState({});

  useEffect(() => {
    const token = store.get("DFC-token");
    if (token) {
      refreshUser().then(() => {
        setAuthenticating(false);
      });
    } else {
      setUser(null);
      setAuthenticating(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user) {
      const premium = user && user.subscription === "PREMIUM";
      setIsPremium(premium);

      const newUser = user && user.newUser;

      setIsNew(newUser);

      const teamMember = premium && !user.team;

      // user belongs to the team of a premium user
      setIsTeamMember(teamMember);

      // get billing details
      if (!user.billing && !teamMember) {
        getBilling();
      }

      // get user referrals
      if (!user.referrals) {
        getReferrals();
      }

      // get user's team (if they have one)
      if (user.team && !user.team.members) {
        getTeamMembers();
      }
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const login = async (email, password) => {
    const result = await checkLogin(email, password);

    if (result.success === true) {
      store.set("DFC-token", result.webToken);

      setUser(result.user);

      return { success: true };
    } else {
      return { success: false, msg: result.msg };
    }
  };

  const refreshUser = async () => {
    const thisUser = await getUser();

    setUser(thisUser);
  };

  const changeName = async (name) => {
    // eslint-disable-next-line
    const result = await updateUsersName(name);
    const thisUser = { ...user, name: name };

    setUser(thisUser);
  };

  const signUp = async (data) => {
    try {
      const result = await createUser(data);

      if (result.success === true) {
        store.set("DFC-token", result.webToken);

        setUser(result.user);

        return { success: true };
      } else {
        return { success: false, msg: result.msg };
      }
    } catch (err) {
      return { success: false, msg: err };
    }
  };

  const checkDiscountCode = async (code) => {
    const result = await checkDiscountOnServer(code);
    return result;
  };

  const logout = () => {
    setUser(null);

    store.remove("DFC-token");
  };

  const resetPassword = () => {};

  const getBilling = async () => {
    try {
      const billing = await getBillingDetails();

      const updatedUser = {
        ...user,
        billing,
      };

      setUser(updatedUser);
    } catch (err) {
      const updatedUser = {
        ...user,
        billing: err.msg ? err.msg : {},
      };

      setUser(updatedUser);
    }
  };

  const updateBilling = async (paymentMethodId, brand, last4) => {
    const oldBilling = user.billing;
    try {
      // optimistically update user's billing
      const updatedBilling = {
        ...user.billing,
        card: {
          brand,
          last4,
        },
      };

      const updatedUser = {
        ...user,
        billing: updatedBilling,
      };

      setUser(updatedUser);

      // eslint-disable-next-line
      const result = await updateBillingDetails(paymentMethodId);
    } catch (err) {
      // revert to previous billing
      const updatedUser = {
        ...user,
        billing: oldBilling,
      };

      setUser(updatedUser);

      throw new Error(
        "Sorry, we could not update your payment info at this time.",
      );
    }
  };

  const addProject = async (fonts, whitelist) => {
    const oldProjects = user.projects;
    try {
      const result = await addProjectToServer(fonts, whitelist);

      const newProjects = [...oldProjects, result.data.project];

      const updatedUser = {
        ...user,
        projects: newProjects,
      };

      setUser(updatedUser);
    } catch (err) {
      console.error(err);

      const updatedUser = {
        ...user,
        projects: oldProjects,
      };

      setUser(updatedUser);

      throw new Error("Sorry, there was an error adding your project.");
    }
  };

  const updateProject = async (projectId, fonts, whitelist) => {
    const oldProjects = user.projects;
    try {
      const result = await updateProjectOnServer(projectId, fonts, whitelist);

      const newProject = result.data.project;

      //Inserts the new project into where the old one was
      let newProjects = oldProjects.map((project) => {
        if (project._id === newProject._id) {
          return newProject;
        } else {
          return project;
        }
      });

      const updatedUser = {
        ...user,
        projects: newProjects,
      };

      setUser(updatedUser);
    } catch (err) {
      console.error(err);

      const updatedUser = {
        ...user,
        projects: oldProjects,
      };

      setUser(updatedUser);

      throw new Error("Sorry, there was an error updating your project.");
    }
  };

  const deleteProject = async (projectId) => {
    const oldProjects = user.projects;
    try {
      const newProjects = oldProjects.filter(
        (project) => project._id !== projectId,
      );

      const updatedUser = {
        ...user,
        projects: newProjects,
      };

      setUser(updatedUser);

      // eslint-disable-next-line
      const result = await deleteProjectOnServer(projectId);
    } catch (err) {
      console.error(err);

      const updatedUser = {
        ...user,
        projects: oldProjects,
      };

      setUser(updatedUser);

      throw new Error("Sorry, there was an error deleting your project.");
    }
  };

  // for referrals
  const invite = async (email) => {
    const oldReferrals = user.referrals;

    try {
      // check if an invite has already been sent to this person
      const existingReferral = user.referrals.find(
        (referral) => referral.email === email,
      );

      // optimistically add this to the referrals
      if (!existingReferral) {
        const newReferrals = [...user.referrals, { email }];

        const updatedUser = {
          ...user,
          referrals: newReferrals,
        };

        setUser(updatedUser);
      }

      await sendReferral(email);
    } catch (err) {
      const updatedUser = {
        ...user,
        referrals: oldReferrals,
      };

      setUser(updatedUser);

      throw new Error(err);
    }
  };

  const getReferrals = async () => {
    try {
      const referrals = await getUserReferrals();

      const updatedUser = {
        ...user,
        referrals,
      };

      setUser(updatedUser);
    } catch (err) {
      // console.log(err);
    }
  };

  const changeSubscription = async (subscription) => {
    try {
      // don't optimistically update, just wait for the server

      const updatedUser = await updateSubscription(subscription);

      const becamePremium = subscription.toUpperCase() === "PREMIUM";

      // if they are downgrading, drop their isPremium first before updating the user
      if (!becamePremium) {
        setIsPremium(false);
      }

      // update the user in state
      setUser(updatedUser);

      // if they are upgrading, update the user first, then give them isPremium
      if (becamePremium) {
        setIsPremium(true);
      }
    } catch (err) {
      throw new Error(err);
    }
  };

  const cancelUserSubscription = async () => {
    try {
      await cancelSubscription();

      const updatedUser = { ...user, hasCancelled: true };
      // update the user in state
      setUser(updatedUser);
    } catch (err) {
      throw new Error(err);
    }
  };

  const changeTeamName = async (name) => {
    const oldTeam = user.team;
    try {
      const updatedTeam = {
        ...user.team,
        name,
      };
      const updatedUser = {
        ...user,
        team: updatedTeam,
      };

      // optimisitcally update the user in state
      setUser(updatedUser);

      // don't include the members array when updating, since user.team in the DB only has the name
      await renameTeam(name);
    } catch (err) {
      const updatedUser = {
        ...user,
        team: oldTeam,
      };

      setUser(updatedUser);
    }
  };

  const getTeamMembers = async () => {
    try {
      const members = await getTeamInvites();

      const updatedTeam = {
        ...user.team,
        members,
      };

      const updatedUser = {
        ...user,
        team: updatedTeam,
      };

      setUser(updatedUser);
    } catch (err) {
      // console.log(err);
    }
  };

  const addTeamMember = async (email) => {
    const oldTeam = user.team;

    try {
      // check if an invite has already been sent to this person
      const existingMember = user.team.members.find(
        (member) => member.email === email,
      );

      // optimistically add this email to the user's team
      if (!existingMember) {
        const newMembers = [...user.team.members, { email, valid: true }];

        const updatedTeam = {
          ...user.team,
          members: newMembers,
        };

        const updatedUser = {
          ...user,
          team: updatedTeam,
        };

        setUser(updatedUser);
      }

      await sendTeamInvite(email);
    } catch (err) {
      // console.log(err);

      const updatedUser = {
        ...user,
        team: oldTeam,
      };

      setUser(updatedUser);

      throw new Error(err);
    }
  };

  const removeTeamMember = async (email) => {
    const oldTeam = user.team;

    try {
      const updatedTeamMembers = user.team.members.filter(
        (member) => member.email !== email,
      );
      const updatedTeam = {
        ...user.team,
        members: updatedTeamMembers,
      };
      const updatedUser = {
        ...user,
        team: updatedTeam,
      };

      // optimisitcally update the user in state
      setUser(updatedUser);

      await deleteTeamInvite(email);
    } catch (err) {
      const updatedUser = {
        ...user,
        team: oldTeam,
      };

      setUser(updatedUser);
    }
  };

  // Checks for referrals in url
  useEffect(() => {
    // get query string out of URL
    const urlParams = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });

    // If there is a referrer, invite or team in the url, we store it in local
    // (overwriting whatever was there before)
    if (urlParams.referrer) {
      store.set("dfc-referral", {
        type: "referrer",
        token: urlParams.referrer,
      });
    } else if (urlParams.invite) {
      store.set("dfc-referral", { type: "invite", token: urlParams.invite });
    } else if (urlParams.team) {
      store.set("dfc-referral", { type: "team", token: urlParams.team });
    } else {
      // If there's no new referral in url
      const prevReferral = store.get("dfc-referral");

      if (prevReferral !== undefined) {
        switch (prevReferral.type) {
          case "referrer":
            urlParams.referrer = prevReferral.token;
            break;

          case "invite":
            urlParams.invite = prevReferral.token;
            break;

          case "team":
            urlParams.team = prevReferral.token;
            break;

          default:
            break;
        }
      }
    }

    setParams(urlParams);
  }, [history]);

  // Opens sidebar if there is a referral
  useEffect(() => {
    // if a team token or reset token was passed in, activate the nav by default on mobile
    if ((params.team || params.reset) && !user) {
      setIsSidebarOpen(true);
    }
  }, [params]); // eslint-disable-line

  // forget the reset token after the user logs in
  // (so that after they sign out, they won't go back to the password reset form)
  useEffect(() => {
    if (user) {
      const newParams = {
        ...params,
        reset: undefined,
      };
      setParams(newParams);
    }
  }, [user]); // eslint-disable-line

  const openSidebarSignup = () => {
    sidebarSignupCallback();
    setIsSidebarOpen(true);
  };

  const toggleSidebarSignup = () => {
    sidebarSignupCallback();
    setIsSidebarOpen(!isSidebarOpen);
  };

  const openSidebarLogin = () => {
    sidebarLoginCallback();
    setIsSidebarOpen(true);
  };

  const toggleSidebarLogin = () => {
    sidebarLoginCallback();
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isNew,
        isPremium,
        isTeamMember,
        changeName,
        refreshUser,

        addProject,
        updateProject,
        deleteProject,

        login,
        logout,
        signUp,
        resetPassword,
        invite,
        checkDiscountCode,

        authenticating,

        changeSubscription,
        cancelUserSubscription,
        changeTeamName,
        addTeamMember,
        removeTeamMember,

        updateBilling,

        policyCallback,
        setPolicyCallback,
        eulaCallback,
        setEulaCallback,

        params,
        openSidebarSignup,
        toggleSidebarSignup,
        openSidebarLogin,
        toggleSidebarLogin,
        isSidebarOpen,
        setIsSidebarOpen,
        setSidebarSignupCallback,
        setSidebarLoginCallback,
      }}
      {...props}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);
const Provider = withRouter(AuthProvider);
export { Provider as AuthProvider, useAuth, AuthContext };
