import React from 'react'

const Privacy = () => {
	return (
		<>
		<p>
                Thank you for choosing to use the services made possible by
                Dollar Font Club (“Company”, “we”, “us”, or “our”). We are
                committed to protecting your personal information and your right
                to privacy. If you have any questions or concerns about this
                privacy notice, or our practices with regards to your personal
                information, please contact us at privacy@dollarfontclub.com.
                When you visit our website dollarfontclub.com (the "Website"),
                and more generally, use any of our services (the "Services",
                which include the Website), we appreciate that you are trusting
                us with your personal information. We take your privacy very
                seriously. In this privacy notice, we seek to explain to you in
                the clearest way possible what information we collect, how we
                use it and what rights you have in relation to it. We hope you
                take some time to read through it carefully, as it is important.
                If there are any terms in this privacy notice that you do not
                agree with, please discontinue use of our Services immediately.
                This privacy notice applies to all information collected through
                our Services (which, as described above, includes our Website),
                as well as any related services, sales, marketing or events.
                Please read this privacy notice carefully as it will help you
                understand what we do with the information that we collect.
              </p>

              <strong>1. WHAT INFORMATION DO WE COLLECT?</strong>
              <p>Personal information you disclose to us</p>
              <p>In Short: We collect information that you provide to us.</p>
              <p>
                We collect personal information that you voluntarily provide to
                us when you express an interest in obtaining information about
                us or our products and Services, when you participate in
                activities on the Website or otherwise when you contact us.
              </p>
              <p>
                The personal information that we collect depends on the context
                of your interactions with us and the Website, the choices you
                make and the products and features you use. The personal
                information we collect may include the following:
              </p>
              <p>
                Personal Information Provided by You. We collect names; phone
                numbers; email addresses; job titles; and other similar
                information.
              </p>
              <p>
                All personal information that you provide to us must be true,
                complete and accurate, and you must notify us of any changes to
                such personal information.
              </p>
              <p>Information automatically collected</p>
              <p>
                In Short: Some information — such as your Internet Protocol (IP)
                address and/or browser and device characteristics — is collected
                automatically when you visit our Website.
              </p>
              <p>
                We automatically collect certain information when you visit, use
                or navigate the Website. This information does not reveal your
                specific identity (like your name or contact information) but
                may include device and usage information, such as your IP
                address, browser and device characteristics, operating system,
                language preferences, referring URLs, device name, country,
                location, information about who and when you use our Website and
                other technical information. This information is primarily
                needed to maintain the security and operation of our Website,
                and for our internal analytics and reporting purposes.
              </p>
              <p>
                Like many businesses, we also collect information through
                cookies and similar technologies.
              </p>
              <p>The information we collect includes:</p>
              <ul>
                <li>
                  Log and Usage Data. Log and usage data is service-related,
                  diagnostic usage and performance information our servers
                  automatically collect when you access or use our Website and
                  which we record in log files. Depending on how you interact
                  with us, this log data may include your IP address, device
                  information, browser type and settings and information about
                  your activity in the Website (such as the date/time stamps
                  associated with your usage, pages and files viewed, searches
                  and other actions you take such as which features you use),
                  device event information (such as system activity, error
                  reports (sometimes called 'crash dumps') and hardware
                  settings).
                </li>
                <li>
                  Device Data. We collect device data such as information about
                  your computer, phone, tablet or other device you use to access
                  the Website. Depending on the device used, this device data
                  may include information such as your IP address (or proxy
                  server), device application identification numbers, location,
                  browser type, hardware model Internet service provider and/or
                  mobile carrier, operating system configuration information.
                </li>
                <li>
                  Location Data. We collect information data such as information
                  about your device's location, which can be either precise or
                  imprecise. How much information we collect depends on the type
                  of settings of the device you use to access the Website. For
                  example, we may use GPS and other technologies to collect
                  geolocation data that tells us your current location (based on
                  your IP address). You can opt out of allowing us to collect
                  this information either by refusing access to the information
                  or by disabling your Locations settings on your device. Note
                  however, if you choose to opt out, you may not be able to use
                  certain aspects of the Services.
                </li>
              </ul>
              <p>
                Opt-out from receiving cross-device site advertising (i.e.
                tracking a user across devices), by accessing your device
                setting or visiting and employing the controls described on the{" "}
                <a href="http://networkadvertising.org/mobile-choice/">
                  NAI’s Mobile Choices page
                </a>
                .
              </p>
              <strong>2. HOW DO WE USE YOUR INFORMATION?</strong>
              <p>
                In Short: We process your information for purposes based on
                legitimate business interests, the fulfillment of our contract
                with you, compliance with our legal obligations, and/or your
                consent.
              </p>
              <p>
                We use personal information collected via our Website for a
                variety of business purposes described below. We process your
                personal information for these purposes in reliance on our
                legitimate business interests, in order to enter into or perform
                a contract with you, with your consent, and/or for compliance
                with our legal obligations. We indicate the specific processing
                grounds we rely on next to each purpose listed below.
              </p>
              <p>We use the information we collect or receive:</p>
              <ul>
                <li>
                  To send you marketing and promotional communications. We
                  and/or our third-party marketing partners may use the personal
                  information you send to us for our marketing purposes, if this
                  is in accordance with your marketing preferences. For example,
                  when expressing an interest in obtaining information about us
                  or our Website, subscribing to marketing or otherwise
                  contacting us, we will collect personal information from you.
                  You can opt-out of our marketing emails at any time (see the
                  "WHAT ARE YOUR PRIVACY RIGHTS" below).
                </li>
                <li>
                  Deliver targeted advertising to you. We may use your
                  information to develop and display personalized content and
                  advertising (and work with third parties who do so) tailored
                  to your interests and/or location and to measure its
                  effectiveness.
                </li>
              </ul>
              <strong>2. HOW DO WE USE YOUR INFORMATION?</strong>
              <p>
                In Short: We process your information for purposes based on
                legitimate business interests, the fulfillment of our contract
                with you, compliance with our legal obligations, and/or your
                consent.
              </p>
              <p>
                We use personal information collected via our Website for a
                variety of business purposes described below. We process your
                personal information for these purposes in reliance on our
                legitimate business interests, in order to enter into or perform
                a contract with you, with your consent, and/or for compliance
                with our legal obligations. We indicate the specific processing
                grounds we rely on next to each purpose listed below.
              </p>
              <p>We use the information we collect or receive:</p>
              <ul>
                <li>
                  To send you marketing and promotional communications. We
                  and/or our third-party marketing partners may use the personal
                  information you send to us for our marketing purposes, if this
                  is in accordance with your marketing preferences. For example,
                  when expressing an interest in obtaining information about us
                  or our Website, subscribing to marketing or otherwise
                  contacting us, we will collect personal information from you.
                  You can opt-out of our marketing emails at any time (see the
                  "WHAT ARE YOUR PRIVACY RIGHTS" below).
                </li>
                <li>
                  Deliver targeted advertising to you. We may use your
                  information to develop and display personalized content and
                  advertising (and work with third parties who do so) tailored
                  to your interests and/or location and to measure its
                  effectiveness.
                </li>
              </ul>
              <strong>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</strong>
              <p>
                In Short: We only share information with your consent, to comply
                with laws, to provide you with services, to protect your rights,
                or to fulfill business obligations.
              </p>
              <p>
                We may process or share your data that we hold based on the
                following legal basis:
              </p>
              <ul>
                <li>
                  Consent: We may process your data if you have given us
                  specific consent to use your personal information in a
                  specific purpose.
                </li>
                <li>
                  Legitimate Interests: We may process your data when it is
                  reasonably necessary to achieve our legitimate business
                  interests.
                </li>
                <li>
                  Performance of a Contract: Where we have entered into a
                  contract with you, we may process your personal information to
                  fulfill the terms of our contract.
                </li>
                <li>
                  Legal Obligations: We may disclose your information where we
                  are legally required to do so in order to comply with
                  applicable law, governmental requests, a judicial proceeding,
                  court order, or legal process, such as in response to a court
                  order or a subpoena (including in response to public
                  authorities to meet national security or law enforcement
                  requirements).
                </li>
                <li>
                  Vital Interests: We may disclose your information where we
                  believe it is necessary to investigate, prevent, or take
                  action regarding potential violations of our policies,
                  suspected fraud, situations involving potential threats to the
                  safety of any person and illegal activities, or as evidence in
                  litigation in which we are involved.
                </li>
              </ul>
              <p>
                More specifically, we may need to process your data or share
                your personal information in the following situations:
              </p>
              <ul>
                <li>
                  Business Transfers. We may share or transfer your information
                  in connection with, or during negotiations of, any merger,
                  sale of company assets, financing, or acquisition of all or a
                  portion of our business to another company.
                </li>
                <li>
                  Vendors, Consultants and Other Third-Party Service Providers.
                  We may share your data with third-party vendors, service
                  providers, contractors or agents who perform services for us
                  or on our behalf and require access to such information to do
                  that work. Examples include: payment processing, data
                  analysis, email delivery, hosting services, customer service
                  and marketing efforts. We may allow selected third parties to
                  use tracking technology on the Website, which will enable them
                  to collect data on our behalf about how you interact with our
                  Website over time. This information may be used to, among
                  other things, analyze and track data, determine the popularity
                  of certain content, pages or features, and better understand
                  online activity. Unless described in this notice, we do not
                  share, sell, rent or trade any of your information with third
                  parties for their promotional purposes.
                </li>
                <li>
                  Affiliates. We may share your information with our affiliates,
                  in which case we will require those affiliates to honor this
                  privacy notice. Affiliates include our parent company and any
                  subsidiaries, joint venture partners or other companies that
                  we control or that are under common control with us.
                </li>
                <li>
                  Business Partners. We may share your information with our
                  business partners to offer you certain products, services or
                  promotions.
                </li>
              </ul>
              <strong>4. WHO WILL YOUR INFORMATION BE SHARED WITH?</strong>
              <p>
                In Short: We only share information with the following third
                parties.
              </p>
              <p>
                We only share and disclose your information with the following
                third parties. We have categorized each party so that you may
                easily understand the purpose of our data collection and
                processing practices. If we have processed your data based on
                your consent and you wish to revoke your consent, please contact
                us using the contact details provided in the section below
                titled "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?".
              </p>
              <ul>
                <li>Retargeting Platforms</li>
                <li>AdRoll</li>
                <li>Hubspot</li>
                <li>CallRail</li>
                <li>Dollar Font Club Affiliated Companies </li>
              </ul>
              <strong>
                5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </strong>
              <p>
                In Short: We may use cookies and other tracking technologies to
                collect and store your information.
              </p>
              <p>
                We may use cookies and similar tracking technologies (like web
                beacons and pixels) to access or store information. Specific
                information about how we use such technologies and how you can
                refuse certain cookies is set out in our Cookie Notice.
              </p>
              <strong>6. HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
              <p>
                In Short: We keep your information for as long as necessary to
                fulfill the purposes outlined in this privacy notice unless
                otherwise required by law.
              </p>
              <p>
                We will only keep your personal information for as long as it is
                necessary for the purposes set out in this privacy notice,
                unless a longer retention period is required or permitted by law
                (such as tax, accounting or other legal requirements).
              </p>
              <p>
                When we have no ongoing legitimate business need to process your
                personal information, we will either delete or anonymize such
                information, or, if this is not possible (for example, because
                your personal information has been stored in backup archives),
                then we will securely store your personal information and
                isolate it from any further processing until deletion is
                possible.
              </p>
              <strong>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong>
              <p>
                In Short: We aim to protect your personal information through a
                system of organizational and technical security measures.
              </p>
              <p>
                We have implemented appropriate technical and organizational
                security measures designed to protect the security of any
                personal information we process. However, despite our safeguards
                and efforts to secure your information, no electronic
                transmission over the Internet or information storage technology
                can be guaranteed to be 100% secure, so we cannot promise or
                guarantee that hackers, cybercriminals, or other unauthorized
                third parties will not be able to defeat our security, and
                improperly collect, access, steal, or modify your information.
                Although we will do our best to protect your personal
                information, transmission of personal information to and from
                our Website is at your own risk. You should only access the
                Website within a secure environment.
              </p>
              <strong>8. DO WE COLLECT INFORMATION FROM MINORS?</strong>
              <p>
                In Short: We do not knowingly collect data from or market to
                children under 18 years of age.
              </p>
              <p>
                We do not knowingly solicit data from or market to children
                under 18 years of age. By using the Website, you represent that
                you are at least 18 or that you are the parent or guardian of
                such a minor and consent to such minor dependent’s use of the
                Website. If we learn that personal information from users less
                than 18 years of age has been collected, we will deactivate the
                account and take reasonable measures to promptly delete such
                data from our records. If you become aware of any data we may
                have collected from children under age 18, please contact us at
                privacy@dollarfontclub.com.
              </p>
              <strong>9. WHAT ARE YOUR PRIVACY RIGHTS?</strong>
              <p>
                In Short: You may review, change, or terminate your account at
                any time.
              </p>
              <p>
                If you are resident in the European Economic Area and you
                believe we are unlawfully processing your personal information,
                you also have the right to complain to your local data
                protection supervisory authority. You can find their contact
                details here:
                http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
              </p>
              <p>
                If you are resident in Switzerland, the contact details for the
                data protection authorities are available here:
                https://www.edoeb.admin.ch/edoeb/en/home.html.
              </p>
              <p>
                Cookies and similar technologies: Most Web browsers are set to
                accept cookies by default. If you prefer, you can usually choose
                to set your browser to remove cookies and to reject cookies. If
                you choose to remove cookies or reject cookies, this could
                affect certain features or services of our Website. To opt-out
                of interest-based advertising by advertisers on our Website
                visit http://www.aboutads.info/choices/.
              </p>
              <strong>10. CONTROLS FOR DO-NOT-TRACK FEATURES</strong>
              <p>
                Most web browsers and some mobile operating systems and mobile
                applications include a Do-Not-Track (“DNT”) feature or setting
                you can activate to signal your privacy preference not to have
                data about your online browsing activities monitored and
                collected. At this stage, no uniform technology standard for
                recognizing and implementing DNT signals has been finalized. As
                such, we do not currently respond to DNT browser signals or any
                other mechanism that automatically communicates your choice not
                to be tracked online. If a standard for online tracking is
                adopted that we must follow in the future, we will inform you
                about that practice in a revised version of this privacy notice.
              </p>
              <strong>
                11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </strong>
              <p>
                In Short: Yes, if you are a resident of California, you are
                granted specific rights regarding access to your personal
                information.
              </p>
              <p>
                California Civil Code Section 1798.83, also known as the “Shine
                The Light” law, permits our users who are California residents
                to request and obtain from us, once a year and free of charge,
                information about categories of personal information (if any) we
                disclosed to third parties for direct marketing purposes and the
                names and addresses of all third parties with which we shared
                personal information in the immediately preceding calendar year.
                If you are a California resident and would like to make such a
                request, please submit your request in writing to us using the
                contact information provided below.
              </p>
              <p>
                If you are under 18 years of age, reside in California, and have
                a registered account with the Website, you have the right to
                request removal of unwanted data that you publicly post on the
                Website. To request removal of such data, please contact us
                using the contact information provided below, and include the
                email address associated with your account and a statement that
                you reside in California. We will make sure the data is not
                publicly displayed on the Website, but please be aware that the
                data may not be completely or comprehensively removed from all
                our systems (e.g. backups, etc.).
              </p>
              <p>CCPA Privacy Notice</p>
              <p>The California Code of Regulations defines a "resident" as:</p>
              <ul>
                <li>
                  (1) every individual who is in the State of California for
                  other than a temporary or transitory purpose and
                </li>
                <li>
                  (2) every individual who is domiciled in the State of
                  California who is outside the State of California for a
                  temporary or transitory purpose
                </li>
              </ul>
              <p>All other individuals are defined as "non-residents."</p>
              <p>
                If this definition of "resident" applies to you, certain rights
                and obligations apply regarding your personal information.
              </p>
              <p>What categories of personal information do we collect?</p>
              <p>
                We have collected the following categories of personal
                information in the past twelve (12) months:
              </p>
              <table>
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Exampled</th>
                    <th>Collected</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>A. Identifiers</td>
                    <td>
                      Contact details, such as real name, alias, postal address,
                      telephone or mobile contact number, unique personal
                      identifier, online identifier, Internet Protocol address,
                      email address and account name
                    </td>
                    <td>YES</td>
                  </tr>
                  <tr>
                    <td>
                      B. Personal information categories listed in the
                      California Customer Records statute
                    </td>
                    <td>
                      Name, contact information, education, employment,
                      employment history and financial information
                    </td>
                    <td>YES</td>
                  </tr>
                  <tr>
                    <td>
                      C. Protected classification characteristics under
                      California or federal law
                    </td>
                    <td>Gender and date of birth</td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>D. Commercial information</td>
                    <td>
                      Transaction information, purchase history, financial
                      details and payment information
                    </td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>E. Biometric information</td>
                    <td>Fingerprints and voiceprints</td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>F. Internet or other similar network activity</td>
                    <td>
                      Browsing history, search history, online behavior,
                      interest data, and interactions with our and other
                      websites, applications, systems and advertisements
                    </td>
                    <td>YES</td>
                  </tr>
                  <tr>
                    <td>G. Geolocation data</td>
                    <td>Device location</td>
                    <td>YES</td>
                  </tr>
                  <tr>
                    <td>
                      H. Audio, electronic, visual, thermal, olfactory, or
                      similar information
                    </td>
                    <td>
                      Images and audio, video or call recordings created in
                      connection with our business activities
                    </td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>I. Professional or employment-related information</td>
                    <td>
                      Business contact details in order to provide you our
                      services at a business level, job title as well as work
                      history and professional qualifications if you apply for a
                      job with us
                    </td>
                    <td>YES</td>
                  </tr>
                  <tr>
                    <td>J. Education Information</td>
                    <td>Student records and directory information</td>
                    <td>NO</td>
                  </tr>
                  <tr>
                    <td>K. Inferences drawn from other personal information</td>
                    <td>
                      Inferences drawn from any of the collected personal
                      information listed above to create a profile or summary
                      about, for example, an individual’s preferences and
                      characteristics
                    </td>
                    <td>NO</td>
                  </tr>
                </tbody>
              </table>
              <p>
                We may also collect other personal information outside of these
                categories in instances where you interact with us in-person,
                online, or by phone or mail in the context of:
              </p>
              <ul>
                <li>Receiving help through our customer support channels</li>
                <li>Participation in customer surveys or contests; and</li>
                <li>
                  Facilitation in the delivery of our Services and to respond to
                  your inquiries
                </li>
              </ul>
              <p>How do we use and share your personal information?</p>
              <p>
                More information about our data collection and sharing practices
                can be found in this privacy notice.
              </p>
              <p>
                You may contact us by email at privacy@dollarfontclub.com, or by
                referring to the contact details at the bottom of this document.
              </p>
              <p>
                If you are using an authorized agent to exercise your right to
                opt-out, we may deny a request if the authorized agent does not
                submit proof that they have been validly authorized to act on
                your behalf.
              </p>
              <p>Will your information be shared with anyone else?</p>
              <p>
                We may disclose your personal information with our service
                providers pursuant to a written contract between us and each
                service provider. Each service provider is a for-profit entity
                that processes the information on our behalf.
              </p>
              <p>
                The current list of our service providers can be found below.
              </p>
              <p>
                We may use your personal information for our own business
                purposes, such as for undertaking internal research for
                technological development and demonstration. This is not
                considered to be "selling" of your personal data.
              </p>
              <p>
                Dollar Font Club has disclosed the following categories or personal
                information to third parties for a business or commercial
                purpose in the preceding twelve (12) months:
              </p>
              <p>
                Category B. Personal information, as defined in the California
                Customer Records law, such as your name, contact information,
                education, employment, employment history and financial
                information.
              </p>
              <p>
                The categories of third parties to whom we disclosed personal
                information for a business or commercial purpose can be found
                under "WHO WILL YOUR INFORMATION BE SHARED WITH?".
              </p>
              <p>Your rights with respect to your personal data</p>
              <p>Right to request deletion of the data - Request to delete</p>
              <p>
                You can ask for the deletion of your personal information. If
                you ask us to delete your personal information, we will respect
                your request and delete your personal information, subject to
                certain exceptions provided by law, such as (but not limited to)
                the exercise by another consumer of his or her right to free
                speech, our compliance requirements resulting from a legal
                obligation or any processing that may be required to protect
                against illegal activities.
              </p>
              <p>Right to be informed - Request to know</p>
              <p>Depending on the circumstances, you have a right to know:</p>
              <ul>
                <li>whether we collect and use your personal information;</li>
                <li>the categories of personal information that we collect;</li>
                <li>
                  the purposes for which the collected personal information is
                  used;
                </li>
                <li>
                  whether we sell your personal information to third parties;
                </li>
                <li>
                  the categories of personal information that we sold or
                  disclosed for a business purpose;
                </li>
                <li>
                  the categories of third parties to whom the personal
                  information was sold or disclosed for a business purpose; and
                </li>
                <li>
                  the business or commercial purpose for collecting or selling
                  personal information.
                </li>
              </ul>
              <p>
                In accordance with applicable law, we are not obligated to
                provide or delete consumer information that is de-identified in
                response to a consumer request or to re-identify individual data
                to verify a consumer request.
              </p>
              <p>
                Right to Non-Discrimination for the Exercise of a Consumer's
                Privacy Rights
              </p>
              <p>
                We will not discriminate against you if you exercise your
                privacy rights.
              </p>
              <p>Verification process</p>
              <p>
                Upon receiving your request, we will need to verify your
                identity to determine you are the same person about whom we have
                the information in our system. These verification efforts
                require us to ask you to provide information so that we can
                match it with the information you have previously provided us.
                For instance, depending on the type of request you submit, we
                may ask you to provide certain information so that we can match
                the information you provide with the information we already have
                on file, or we may contact you through a communication method
                (e.g. phone or email) that you have previously provided to us.
                We may also use other verification methods as the circumstances
                dictate.
              </p>
              <p>
                We will only use personal information provided in your request
                to verify your identity or authority to make the request. To the
                extent possible, we will avoid requesting additional information
                from you for the purposes of verification. If, however, if we
                cannot verify your identity from the information already
                maintained by us, we may request that you provide additional
                information for the purposes of verifying your identity, and for
                security or fraud-prevention purposes. We will delete such
                additionally provided information as soon as we finish verifying
                you.
              </p>
              <p>Other privacy rights</p>
              <ul>
                <li>you may object to the processing of your personal data</li>
                <li>
                  you may request correction of your personal data if it is
                  incorrect or no longer relevant, or ask to restrict the
                  processing of the data
                </li>
                <li>
                  you can designate an authorized agent to make a request under
                  the CCPA on your behalf. We may deny a request from an
                  authorized agent that does not submit proof that they have
                  been validly authorized to act on your behalf in accordance
                  with the CCPA.
                </li>
                <li>
                  you may request to opt-out from future selling of your
                  personal information to third parties. Upon receiving a
                  request to opt-out, we will act upon the request as soon as
                  feasibly possible, but no later than 15 days from the date of
                  the request submission.
                </li>
              </ul>
              <p>
                To exercise these rights, you can contact us by email at
                privacy@dollarfontclub.com, or by referring to the contact
                details at the bottom of this document. If you have a complaint
                about how we handle your data, we would like to hear from you.
                For more information, view{" "}
                <a href="https://click.api.drift.com/click/f5e26382-dbe5-4070-85a2-6f7a5be11522?u=https%3A%2F%2Fwww.nextroll.com%2Fprivacy%23service-13&h=6edb0cedbc3d8aeeff5eb2f982bdbfa1">
                  California Residents section of NextRoll’s Service Privacy
                  Notice{" "}
                </a>
                .
              </p>
              <strong>12. DO WE MAKE UPDATES TO THIS NOTICE?</strong>
              <p>
                In Short: Yes, we will update this notice as necessary to stay
                compliant with relevant laws.
              </p>
              <p>
                We may update this privacy notice from time to time. The updated
                version will be indicated by an updated “Revised” date and the
                updated version will be effective as soon as it is accessible.
                If we make material changes to this privacy notice, we may
                notify you either by prominently posting a notice of such
                changes or by directly sending you a notification. We encourage
                you to review this privacy notice frequently to be informed of
                how we are protecting your information.
              </p>
              <strong>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong>
              <p>
                If you have questions or comments about this notice, you may
                email us at privacy@dollarfontclub.com or by post to:
              </p>
              <p>
                Dollar Font Club <br />
                189 S Orange Ave
                <br />
                1600
                <br />
                Orlando, FL 32801
                <br />
                United States
              </p>
              <p>
                HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                YOU?
              </p>
              <p>
                Based on the applicable laws of your country, you may have the
                right to request access to the personal information we collect
                from you, change that information, or delete it in some
                circumstances. To request to review, update, or delete your
                personal information, please visit: privacy@dollarfontclub.com.
                We will respond to your request within 30 days.
              </p>
							</>
	)
}

export default Privacy;