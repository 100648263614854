import differenceInDays from "date-fns/difference_in_days";

export const getImagesFromFolder = (r) => {
  let images = [];
  r.keys().forEach((item) => {
    images.push(r(item));
  });
  return images;
};

export const getDaysUntilNextMonth = () => {
  const currentDay = new Date().getDate();
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  // 11 because the months start at 0
  const nextMonth = currentMonth + 1 > 11 ? 1 : currentMonth + 1;
  const nextYear = currentMonth + 1 > 11 ? currentYear + 1 : currentYear;

  const nextMonthDate = new Date(nextYear, nextMonth, 1);

  // don't include hours, so we get the total number of whole days
  const daysUntilNextMonth = differenceInDays(
    nextMonthDate,
    new Date(currentYear, currentMonth, currentDay),
  );

  return daysUntilNextMonth;
};

export const getCurrency = (number, showCents = undefined) => {
  // if we don't specify whether or not to show cents, it will default to whether or not the number has decimals. If it does, then show cents. Otherwise, don't.
  const shouldShowCents =
    typeof showCents === "undefined" ? !Number.isInteger(number) : showCents;

  return Number(number).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    // whether to show cents ".00" at the end
    minimumFractionDigits: shouldShowCents ? 2 : 0,
    maximumFractionDigits: shouldShowCents ? 2 : 0,
  });
};
