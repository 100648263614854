import React, { useState } from "react";
import styled from "styled-components";

import { useAuth } from "../../../context/AuthContext";

import Collections from "./Collections";
import CollectionsModal from "./CollectionsModal";
import User from "./User";

import { respondTo } from "../../../styles/styleHelpers";

const UserSection = ({ setTeamOpen }) => {
  const {
    user,
    isPremium,
    isTeamMember,
    changeName,
    changeSubscription,
  } = useAuth();

  const [confirmOpen, setConfirmOpen] = useState(null);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubscription = async (subscription) => {
    try {
      setLoading(true);
      setError(null);

      await changeSubscription(subscription);

      setLoading(false);
      setConfirmOpen(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return (
    <SectionContainer>
      <User
        user={user}
        error={error}
        loading={loading}
        handleEdit={changeName}
      />

      {!isTeamMember ? (
        <>
          <Collections
            openDowngrade={() => {
              setConfirmOpen("downgrade");
            }}
            openUpgrade={() => {
              setConfirmOpen("upgrade");
            }}
            onTeamClick={
              isPremium
                ? () => {
                    setTeamOpen(true);

                    // scroll the panel into view
                    const teamPanel = document.getElementById("team-panel");
                    if (teamPanel) {
                      teamPanel.scrollIntoView({ behavior: "smooth" });
                    }
                  }
                : undefined
            }
          />
          {confirmOpen ? (
            <CollectionsModal
              type={confirmOpen}
              submitSubscription={handleSubscription}
              tokensAvailable={user.tokensAvailable}
              loading={loading}
              closeConfirm={() => {
                setConfirmOpen(null);
              }}
            />
          ) : null}
        </>
      ) : null}
    </SectionContainer>
  );
};

const SectionContainer = styled.div`
  border: 3px solid ${(props) => props.theme.colors.gray};
  border-radius: 15px;
  padding: 55px ${(props) => props.theme.space};
  position: relative;

  ${respondTo("large")} {
    padding-left: 60px;
    padding-right: 60px;
    /* padding-top: 80px; */
  }
`;

export default UserSection;
