import styled from "styled-components";

const Input = styled.input`
  position: relative;
  background-color: white;
  border-radius: 9px;
  display: block;
  color: ${(props) => props.theme.colors.black};
  width: 100%;
  font-size: ${(props) => props.theme.fontSize_l};
  padding: 15px;
  outline: none;
  z-index: 1;
`;

export const InputField = styled.div`
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

export default Input;
