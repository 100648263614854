import React from "react";
import styled from "styled-components";
import { rem, rgba } from "polished";
import { respondTo } from "../../../styles/styleHelpers";

const CollectionsModal = ({
  type,
  loading,
  tokensAvailable,
  submitSubscription,
  closeConfirm,
}) => {
  return (
    <>
      {type === "upgrade" ? (
        <ModalContainer>
          <ModalHeader>
            Great decision.
            <br />
            You are about to gain unlimited access to the complete DFC library.*
          </ModalHeader>
          <ModalFootnote>
            * Your account will reflect this membership upgrade.
          </ModalFootnote>
          <Button
            upgrade
            disabled={loading}
            onClick={() => submitSubscription("PREMIUM")}
          >
            Confirm
          </Button>
          <Link onClick={closeConfirm}>Go Back</Link>
        </ModalContainer>
      ) : (
        <ModalContainer>
          <ModalHeader wider>
            Are you certain you want to lose access to the entire DFC library?
            <br />
            Your account will have a credit of only
            <br />
            {tokensAvailable} fonts to redeem*.
          </ModalHeader>
          <ModalFootnote>
            * Based on the length of your account with DFC
          </ModalFootnote>
          <ButtonContainer>
            <ButtonHollow onClick={closeConfirm}>Nevermind</ButtonHollow>
            <Button
              disabled={loading}
              downgrade
              onClick={() => submitSubscription("STARTER")}
            >
              Downgrade
            </Button>
          </ButtonContainer>
        </ModalContainer>
      )}
    </>
  );
};

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;

  &::before {
    content: "";
    background-color: #465a68;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 15px;
    opacity: 0.9;
    z-index: -1;
  }
`;

const ModalHeader = styled.h2`
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  font-weight: bold;
  font-size: ${rem("28px")};

  max-width: ${(props) => (props.wider ? "800px" : "750px")};

  margin-bottom: 40px;

  ${respondTo("medium")} {
    font-size: ${(props) => props.theme.fontSize_xxl};
  }
`;

const ModalFootnote = styled.p`
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize_l};
  margin-bottom: 70px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) =>
    props.downgrade ? props.theme.colors.red : props.theme.colors.green};
  color: ${(props) => props.theme.colors.white};

  font-size: ${rem("21px")};
  font-weight: bold;
  border-radius: 29px;
  padding: 17px 45px;

  transition: 0.3s ease-in-out;

  &:hover,
  &:focus {
    &:not(:disabled) {
      background-color: ${(props) =>
        rgba(
          props.downgrade ? props.theme.colors.red : props.theme.colors.green,
          0.8,
        )};
      transform: scale(0.95);
    }
  }
`;

const ButtonHollow = styled(Button)`
  background-color: transparent;
  border: 2px solid ${(props) => props.theme.colors.yellow};
  color: ${(props) => props.theme.colors.white};

  &:hover,
  &:focus {
    &:not(:disabled) {
      background-color: ${(props) => rgba(props.theme.colors.yellow, 0.5)};
      transform: scale(0.95);
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  & > *:not(:last-child) {
    margin-top: 20px;
  }

  ${respondTo("medium")} {
    flex-direction: row;

    & > *:not(:last-child) {
      margin-top: 0;
      margin-right: 80px;
    }
  }
`;

const Link = styled.button`
  color: ${(props) => props.theme.colors.white};

  font-size: ${rem("17px")};
  font-weight: 500;
  text-decoration-line: underline;

  margin-top: 26px;

  transition: 0.3s ease-in-out;

  &:hover,
  &:focus {
    &:not(:disabled) {
      color: ${(props) => props.theme.colors.gray};
    }
  }
`;

export default CollectionsModal;
