import React, { useState, useRef } from "react";
import styled from "styled-components";
import { rem } from "polished";

import RangeSlider from "../../../components/RangeSlider";
import TextAlign from "./TextAlign";

import { visuallyHidden, respondTo } from "../../../styles/styleHelpers";

const FontPreview = ({ font }) => {
  const defaultFontSize = 0.9;
  const [fontSize, setFontSize] = useState(defaultFontSize);
  const [textAlign, setTextAlign] = useState("center");

  const defaultText = "Insert your text here";
  const preview = useRef(null);

  return (
    <Container>
      <PreviewContainer>
        <Preview
          ref={preview}
          fontFamily={font}
          size={fontSize}
          align={textAlign}
          contentEditable
          suppressContentEditableWarning={true}
          onFocus={(e) => {
            // clear it on initial click
            if (e.target.textContent === defaultText) {
              preview.current.textContent = "";
            }
          }}
          onBlur={(e) => {
            // prevent it from becoming blank
            if (!e.target.textContent) {
              preview.current.textContent = defaultText;
            }
          }}
          onPaste={(e) => {
            // prevent html from being pasted
            e.preventDefault();

            const text = e.clipboardData.getData("text/plain");
            document.execCommand("insertHTML", false, text);
          }}
        >
          {defaultText}
        </Preview>
      </PreviewContainer>

      <Controls aria-label="Font styling controls">
        <RangeContainer>
          <RangeLabel aria-hidden="true" small={1}>
            a
          </RangeLabel>
          <RangeLine>
            <RangeSlider
              name="font-size"
              defaultValue={defaultFontSize}
              min={0.25}
              max={1}
              step={0.05}
              handleChange={(value) => {
                setFontSize(value);
              }}
            />
          </RangeLine>
          <RangeLabel aria-hidden="true">a</RangeLabel>
        </RangeContainer>

        <fieldset>
          <Legend>Text Alignment</Legend>
          <AlignContainer>
            <TextAlign
              direction="left"
              checked={textAlign === "left"}
              onChange={() => {
                setTextAlign("left");
              }}
            />
            <TextAlign
              direction="center"
              checked={textAlign === "center"}
              onChange={() => {
                setTextAlign("center");
              }}
            />
            <TextAlign
              direction="right"
              checked={textAlign === "right"}
              onChange={() => {
                setTextAlign("right");
              }}
            />
          </AlignContainer>
        </fieldset>
      </Controls>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 20px;
`;

const PreviewContainer = styled.div`
  /* font-size: 15vw; */
  font-size: 12vw;
  min-height: 50vh;
  display: flex;
  align-items: center;

  ${respondTo("small")} {
    font-size: 8vw;
  }
`;

const Preview = styled.div.attrs((props) => ({
  style: {
    fontSize: `${props.size}em`,
    textAlign: props.align,
  },
}))`
  font-family: ${(props) => `${props.fontFamily}, ${props.theme.fontFamily}`};

  /* line-height: 77.5%; */
  line-height: 1;
  letter-spacing: 0.01em;

  padding: 40px 0;
  width: 100%;
  outline-color: ${(props) => props.theme.colors.gray};
`;

const Controls = styled.form`
  background-color: ${(props) => props.theme.colors.pumice};
  border-radius: ${(props) => props.theme.borderRadius};

  padding: 27px 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${respondTo("medium")} {
    flex-direction: row;
  }

  ${respondTo("xlarge")} {
    padding-left: 9.5%;
    padding-right: 9.5%;
  }
`;

const RangeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  ${respondTo("medium")} {
    margin-bottom: 0;
  }
`;

const RangeLine = styled.div`
  width: 125px;
  margin: 0 20px;

  ${respondTo("large")} {
    width: 175px;
  }
`;

const RangeLabel = styled.span`
  font-size: ${(props) => (props.small ? rem("21px") : rem("30px"))};
  font-weight: 700;
  line-height: 0;
  color: ${(props) => props.theme.colors.grayDark};
  user-select: none;
`;

const AlignContainer = styled.div`
  display: flex;

  > * {
    margin-right: 3.5vw;

    &:last-child {
      margin-right: 0;
    }
  }

  ${respondTo("xlarge")} {
    > * {
      margin-right: 55px;
    }
  }
`;

const Legend = styled.legend`
  ${visuallyHidden()};
`;

export default FontPreview;
