import React, { useState } from "react";
import styled from "styled-components";
import { PoseGroup } from "react-pose";
import { format } from "date-fns";
import {
  CardNumberElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { useAuth } from "../../../context/AuthContext";

import Accordion from "./Accordion";
import Table from "./Table";
import Button, { ButtonContainer } from "./Button";
import CreditCard from "./CreditCard";
import Panel, { PanelLeft } from "./Panel";
import CardFields from "../../../components/CardFields";
import FormMessage from "../../../components/FormMessage";
import Label from "../../../components/Label";
import Spinner from "../../../components/Spinner";

const PaymentPanel = () => {
  const { user, updateBilling, cancelUserSubscription } = useAuth();

  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [cardUpdate, setCardUpdate] = useState(false);
  const [cardError, setCardError] = useState("");
  const [cardValid, setCardValid] = useState(false);
  const [cardTouched, setCardTouched] = useState(false);
  const [cardLoading, setCardLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet
        return;
      }

      let number = elements.getElement(CardNumberElement);

      // use the user's input in the stripe card element to create a payment method
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: number,
      });

      if (error) {
        setCardError(error.message);
      } else {
        setCardError("");

        const { brand, last4 } = paymentMethod.card;

        setCardLoading(true);

        // update payment info
        await updateBilling(paymentMethod.id, brand, last4);

        setCardLoading(false);
        setCardUpdate(false);
        setCardValid(false);
        setCardTouched(false);
      }
    } catch (err) {
      setCardLoading(false);
      setCardValid(false);
      setCardError(err);
    }
  };

  const handleCancel = () => {
    cancelUserSubscription();
  };

  return user.billing ? (
    <Accordion title="Payment" isOpen={!user.isActive} id="payment">
      <PanelWrapper>
        <PanelInner>
          {!user.hasCancelled ? (
            <PanelLeft>
              <CreditCard
                cardType={
                  cardUpdate || !user.billing.card
                    ? undefined
                    : user.billing.card.brand
                }
                lastFour={
                  user.billing.card ? user.billing.card.last4 : undefined
                }
              >
                {cardUpdate ? (
                  <form onSubmit={handleSubmit} id="update-billing">
                    <Label as="div">Credit Card</Label>
                    <CardFields
                      className="no-track"
                      onBlur={() => {
                        setCardTouched(true);
                      }}
                      onChange={(e) => {
                        setCardError(e.error ? e.error.message : "");

                        setCardValid(e.complete);
                      }}
                    />

                    <PoseGroup>
                      {cardTouched && !cardValid && cardError ? (
                        <FormMessage key="password">{cardError}</FormMessage>
                      ) : null}
                    </PoseGroup>
                  </form>
                ) : null}
              </CreditCard>
              {cardUpdate ? (
                <ButtonContainer>
                  <StyledButton
                    type="button"
                    onClick={() => {
                      setCardUpdate(false);
                    }}
                    inverse
                  >
                    Cancel
                  </StyledButton>

                  <StyledButton
                    type="submit"
                    form="update-billing"
                    disabled={!cardValid || cardLoading}
                  >
                    {cardLoading ? <Spinner /> : "Save"}
                  </StyledButton>
                </ButtonContainer>
              ) : (
                <StyledButton
                  onClick={() => {
                    setCardUpdate(true);
                  }}
                >
                  Update payment method
                </StyledButton>
              )}
            </PanelLeft>
          ) : (
            <PanelLeft>
              <p>
                Your account has been cancelled at your request. You have until
                the end of your payment cycle to use your fonts. After that,
                please contact us to renew your account.
              </p>
            </PanelLeft>
          )}

          <Table>
            <thead>
              <tr>
                <th>Invoice</th>
                <th className="condensed">Date</th>
                <th className="condensed">Amount</th>
                <th className="condensed">Status</th>
              </tr>
            </thead>
            <tbody>
              {user.billing.invoices &&
                user.billing.invoices.map((invoice) => {
                  // multiply by 1000 since it is in seconds since epoch
                  const date = format(
                    new Date(invoice.period_start * 1000),
                    "MM/yyyy",
                  );
                  return (
                    <tr key={invoice.number}>
                      <td>
                        <Link
                          href={invoice.hosted_invoice_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {invoice.number}
                        </Link>
                      </td>
                      <td>{date}</td>
                      <td>${invoice.total / 100}</td>
                      <td>{invoice.status}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </PanelInner>
        {!user.hasCancelled ? (
          <CancelContainer>
            {cancelConfirm ? (
              <p>
                Are you sure you want to cancel your membership? (This cannot be
                undone)
                <br />
                <CancelButton onClick={() => setCancelConfirm(false)}>
                  No, don't cancel
                </CancelButton>
                <CancelButton danger onClick={handleCancel}>
                  Yes, cancel
                </CancelButton>
              </p>
            ) : (
              <CancelButton onClick={() => setCancelConfirm(true)}>
                Cancel my membership
              </CancelButton>
            )}
          </CancelContainer>
        ) : null}
        {}
      </PanelWrapper>
    </Accordion>
  ) : null;
};

const PanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PanelInner = styled(Panel)``;

const StyledButton = styled(Button)`
  border-radius: 15px;
  margin-top: ${(props) => props.theme.space};
`;

const Link = styled.a`
  text-decoration: underline;
  transition: color 200ms ease;
  cursor: pointer;

  &:focus,
  &:hover {
    color: ${(props) => props.theme.colors.green};
  }
`;

const CancelContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 55px;
  text-align: right;
`;

const CancelButton = styled.button`
  text-decoration: underline;
  transition: color 200ms ease;
  cursor: pointer;
  text-align: right;
  margin-left: 15px;

  color: ${(props) =>
    props.danger ? props.theme.colors.negative : props.theme.colors.black};
  font-weight: ${(props) => (props.danger ? "600" : "400")};

  &:focus,
  &:hover {
    color: ${(props) => props.theme.colors.green};
  }
`;

export default PaymentPanel;
