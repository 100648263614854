import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import LogoText from "../../../components/icons/LogoText";

const Logo = ({ closeSidebar }) => {
  return (
    <Container>
      <Link to="/fonts" onClick={closeSidebar ? closeSidebar : undefined}>
        <LogoText />
      </Link>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.pumice};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 35px ${(props) => props.theme.space};
  line-height: 1;

  margin-top: 22px;
`;

export default Logo;
