import React from "react";

const EULA = () => {
  return (
    <>
      <p>
        This End User License Agreement (“Agreement”) is a binding legal
        contract between you (either an individual or a legal entity) and Dollar
        Font Club, LLC (“Licensor”). By installing, accessing or using the
        Dollar Font Club software, web-based interface, content within the
        web-based interface or software, any associated user manuals and other
        documentation provided by Licensor (“Documentation”), any Enhancements
        (as defined below) provided with this Agreement, and the stylized font
        types, font families, that may be synchronized to a computer or
        otherwise used for creating documents provided therein (collectively,
        the “Software”), you (also referred to herein as “Licensee”) will be
        bound by the terms of this Agreement.
      </p>
      <p>
        If you do not agree to the terms of this Agreement, Licensor is not
        willing to license any right to use or access the Software to you. In
        such event, you may not install, access, use, or copy the Software,
        which explicitly includes (but is not limited to) viewing the Software
        on an Internet web browser.
      </p>
      <p>
        This Agreement is written in the English language. We do not guarantee
        the accuracy of any translated versions of this Agreement. To the extent
        any translated versions of this Agreement conflicts with the English
        language version, the English language version of this Agreement shall
        control.
      </p>
      <p>
        APPLICATION ACCESS AND USE LICENSE. The Software is licensed to you, not
        sold to you. Except for the limited license granted in this Agreement,
        Licensor and its licensors retain all right, title and interest in the
        Software, all copies thereof, and all proprietary rights in the
        Software, including copyrights, patents, trademarks and trade secret
        rights.
      </p>
      <p>
        GRANT OF LICENSE. This Agreement grants you the following rights, as
        applicable:
      </p>
      <p>
        Limited License. During the term of this Agreement, Licensor grants you
        a non-exclusive, non-assignable, non-sublicensable, non-transferable,
        limited, revocable license to access and use the Software hosted by
        Licensor as specifically limited by this Agreement, including but not
        limited to the Section entitled Limitation on License. Licensee
        acknowledges and agrees that Licensor may use certain embedded
        technological and software controls to enforce any applicable license
        restrictions.
      </p>
      <p>
        Enhancements. Licensor reserves the right to upgrade, enhance, change or
        modify the Software at any time in its sole discretion (“Enhancements”).
        Any Enhancements made available to you by Licensor, if any, will be
        subject to the terms of this Agreement, except to the extent that
        conflicting or more restrictive provisions are agreed upon in future
        agreements relating to such Enhancements.
      </p>
      <p>
        Third Party Components. The Software and future Enhancements may contain
        certain third party components (“Third Party Components”) which are
        provided to you under terms and conditions which are different from this
        Agreement, or which require Licensor to provide you with certain notices
        or information. Your use of each Third Party Component which contains or
        is accompanied by its own license agreement will be subject to the terms
        and conditions of such other license agreement, and not this Agreement.
        Notwithstanding the foregoing, the following terms and conditions apply
        to all “Third Party Components”: (i) all Third Party Components are
        provided on an “AS IS” basis; (ii) Licensor will not be liable to you or
        indemnify you for any claims related to the Third Party Components; and
        (iii) Licensor will not be liable for any direct, indirect, incidental,
        special, exemplary, punitive or consequential damages with respect to
        the Third Party Components. Your sole and exclusive remedy with regard
        to any defect, claim, or other dispute relating to the Third Party
        Components is to cease use of such components.
      </p>
      <p>
        Intellectual Property Ownership. The Software contains material that is
        protected by United States copyright and intellectual property law, and
        by international treaty provisions. All rights not expressly granted to
        Licensee under this Agreement are expressly reserved by Licensor or its
        licensors, as applicable. Licensee shall not modify, remove or destroy
        any proprietary markings or confidential legends placed upon or
        contained within the Software, the Documentation, or any related
        materials. All copyrights, patents, trade secrets, trademarks, service
        marks, trade names, moral rights and other intellectual property and
        proprietary rights in the Software shall remain the sole and exclusive
        property of Licensor or its licensors, as applicable.
      </p>
      <p>
        Beta Software. Licensor may designate certain Enhancements or new
        releases of the Software as “Beta Software.” Such Beta Software will not
        be ready for use in a production environment. At this early stage of
        development, operation of the Beta Software may be unpredictable and
        lead to erroneous results. You acknowledge and agree that: (i) the Beta
        Software is experimental and has not been fully tested; (ii) the Beta
        Software may not meet your requirements; (iii) the use or operation of
        the Beta Software may not be uninterrupted or error free; (iv) your use
        of the Beta Software is for purposes of evaluating and testing the
        product and providing feedback to Licensor; (v) you shall inform your
        employees, staff members, and other users regarding the nature of the
        Beta Software; and (vi) you will hold all information relating to the
        Beta Software and your use of the Beta Software, including any
        performance measurements and other data relating to the Beta Software,
        in strict confidence and shall not disclose such information to any
        unauthorized third parties. Your use of the Beta Software shall be
        subject to all of the terms and conditions set forth herein relating to
        the Software. You shall promptly report any errors, defects, or other
        deficiencies in the Beta Software to Licensor. NOTWITHSTANDING ANY OTHER
        PROVISION OF THIS AGREEMENT, ALL BETA SOFTWARE IS PROVIDED “AS IS” AND
        “AS AVAILABLE,” WITHOUT WARRANTIES OF ANY KIND. You hereby waive any and
        all claims, now known or later discovered, that you may have against
        Licensor and its suppliers/licensors arising out of your use of the Beta
        Software.
      </p>
      <p>
        TERM. The license will commence on the date you first use the Software
        or accept this Agreement, whichever is earlier (the “Effective Date”)
        and shall remain in effect so long as Licensee has paid in full all
        amounts owed by Licensee to Licensor under the Licensor’s then-current
        rate schedule or subscription plan, which may change from time to time
        as posted on Licensor’s website, and as long as Licensee is in
        compliance with all terms of this Agreement (the “Term”).
      </p>
      <p>
        LIMITATIONS ON LICENSE. The license granted to you in this Agreement is
        restricted as follows:
      </p>
      <p>
        Limitations on Use. Use of the Software is limited to the specific
        individual which has subscribed to the Software (which does not include
        other individuals associated with the same corporate entity) and by the
        level of the subscription as posted on the Licensor’s website. Thus, as
        to an individual, the use is limited to that individual alone. As to a
        business entity, the use is limited to a single individual at that
        business entity alone- if multiple users intend to use the Software at
        the same business entity, each must pay for and subscribe separately.
      </p>
      <p>
        Limitations on Copying and Distribution. You may not copy or distribute
        the Software except to the extent that copying is necessary to use the
        Software for purposes set forth herein. You may make a single copy of
        the Software for backup and archival purposes. You may not bundle,
        embed, or otherwise distributing any Software, or sublicense your access
        to use any part of the Software to any other person or entity.
      </p>
      <p>
        Limitations on Reverse Engineering and Modification. You may not reverse
        engineer, decompile, disassemble, modify or create works derivative of
        the Software. You may not alter or modify any disabling mechanism which
        may be resident in the Software.
      </p>
      <p>
        Sublicense, Rental, and Third-Party Use. You may not assign, sublicense,
        rent, timeshare, loan, lease or otherwise transfer the Software, or
        directly or indirectly permit any third party to use or copy the
        Software.
      </p>
      <p>
        Proprietary Notices. You may not remove any proprietary notices (such as
        copyright or trademark notices) from the Software. You must reproduce
        the copyright and all other proprietary notices displayed on the
        Software on each permitted back-up or archival copy.
      </p>
      <p>
        Use in Accordance with Documentation. All use of the Software shall be
        in accordance with its then current Documentation.
      </p>
      <p>
        Compliance with Applicable Law. You shall be solely responsible for
        ensuring that your use of the Software is in compliance with all
        applicable foreign, federal, state and local laws, and rules and
        regulations.
      </p>
      <strong>LIMITATION OF WARRANTIES.</strong>
      <p>
        Exclusive Remedy. The sole and exclusive remedy of Licensee and the sole
        and exclusive liability of Licensor under this Agreement shall be to
        seek repair or replacement of the non-conforming Software or
        reperformance of the relevant services. In the event that such breach
        cannot be remedied by repair, replacement, or reperformance, or where a
        repair or replacement remedy is not applicable, Licensor shall be liable
        only for Licensee’s direct damages in the aggregate up to the limit
        provided herein.
      </p>
      <p>
        Fees. The license fees payable to Licensor for the rights hereunder are
        as set forth separately with the individual or organization responsible
        for payment of the license fees; such agreement describes the license
        fees and any other fees owed by the person or entity paying the
        applicable license fees on your behalf to enable you to utilize the
        Software. If these license fees are not timely paid to Licensor, then
        Licensor may immediately terminate this Agreement, as well all your
        access to the Software, with or without prior notice to you.
      </p>
      <p>
        Intellectual Property Infringement. In the event the Software as
        delivered by Licensor becomes or, in Licensor’s opinion, is likely to
        become, the subject of a claim of infringement, Licensor may, at its
        option and expense either (a) procure for Licensee the right to continue
        to use the Software as contemplated hereunder, or (b) replace or modify
        the Software or modify its use to make its use hereunder noninfringing,
        without loss of material functionality. If neither option is reasonably
        available to Licensor, then this Agreement may be terminated at the
        option of either party hereto without further obligation or liability.
      </p>
      <strong>TERMINATION.</strong>
      <p>
        Breach of Agreement. Without prejudice to any other rights, Licensor may
        terminate this Agreement immediately, without any notice to you, if you
        fail to comply with any of the terms and conditions of this Agreement.
      </p>
      <p>
        Termination for Convenience. You may terminate this Agreement at any
        time by discontinuing use of the Software, complying with your
        termination obligations set forth below, providing Licensor written
        notice, and returning the Software to Licensor,
      </p>
      <p>
        Licensee’s Termination Obligations. In the event of any expiration or
        termination of this Agreement for any reason, you must remove all copies
        of the Software and all of its components from all of your systems, and
        destroy all related media and Documentation, if any, as well as, at
        Licensor’s request, providing a written certification, under penalty of
        perjury, that you are no longer utilizing the Software and have complied
        with the terms of this Agreement. The license granted to the Software
        will automatically terminate on expiration or termination of this
        Agreement.
      </p>
      <strong>WARRANTY DISCLAIMER.</strong>
      <p>
        THE SOFTWARE IS PROVIDED ON AN “AS AVAILABLE,” “AS IS” BASIS. TO THE
        MAXIMUM EXTENT PERMITTED BY LAW, LICENSOR AND ANY OF ITS LICENSORS
        DISCLAIM ALL WARRANTIES WITH RESPECT TO THE SOFTWARE, INCLUDING, BUT NOT
        LIMITED TO, THE IMPLIED WARRANTIES OF NON-INFRINGEMENT, TITLE,
        MERCHANTABILITY, QUIET ENJOYMENT, QUALITY OF INFORMATION, AND FITNESS
        FOR A PARTICULAR PURPOSE. LICENSOR DOES NOT WARRANT THAT THE SOFTWARE
        WILL MEET YOUR REQUIREMENTS, OR THAT THE OPERATION OF THE SOFTWARE WILL
        BE UNINTERRUPTED OR ERROR-FREE, OR THAT DEFECTS IN THE SOFTWARE WILL BE
        CORRECTED. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY LICENSOR
        SHALL CREATE ANY ADDITIONAL LICENSOR WARRANTIES OR IN ANY WAY INCREASE
        THE SCOPE OF LICENSOR’S OBLIGATIONS HEREUNDER.
      </p>
      <p>
        LICENSOR MAKES NO WARRANTY OF ANY KIND, WHETHER EXPRESS OR IMPLIED, WITH
        REGARD TO ANY HARDWARE, PRODUCTS, SOFTWARE, OR PROGRAMMING OBTAINED BY
        LICENSOR FROM THIRD PARTIES (COLLECTIVELY, THE “THIRD PARTY ITEMS”).
        LICENSOR EXPRESSLY DISCLAIMS ALL WARRANTIES, EXPRESS AND IMPLIED,
        INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
        MERCHANTABILITY, TITLE/NON-INFRINGEMENT, QUALITY OF INFORMATION, QUIET
        ENJOYMENT, AND FITNESS FOR A PARTICULAR PURPOSE WITH REGARD TO THE
        THIRD-PARTY ITEMS. YOU SHOULD CONSULT THE RESPECTIVE
        VENDORS/MANUFACTURERS OF THE THIRD-PARTY ITEMS FOR WARRANTY AND
        PERFORMANCE INFORMATION.
      </p>
      <p>
        THE SOFTWARE MAY BE USED TO ACCESS AND TRANSFER INFORMATION OVER THE
        INTERNET. YOU ACKNOWLEDGE AND AGREE THAT LICENSOR DOES NOT OPERATE OR
        CONTROL THE INTERNET AND THAT: (I) VIRUSES, WORMS, TROJAN HORSES, OR
        OTHER UNDESIRABLE DATA OR SOFTWARE; OR (II) UNAUTHORIZED USERS (E.G.,
        HACKERS) MAY ATTEMPT TO OBTAIN ACCESS TO AND DAMAGE YOUR DATA, WEB
        SITES, COMPUTERS, OR NETWORKS. LICENSOR SHALL NOT BE RESPONSIBLE FOR
        SUCH ACTIVITIES. YOU ARE SOLELY RESPONSIBLE FOR THE SECURITY AND
        INTEGRITY OF YOUR DATA AND SYSTEMS.
      </p>
      <p>
        LIMITATION OF LIABILITY. TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO
        EVENT SHALL LICENSOR OR ITS SUPPLIERS/LICENSORS BE LIABLE TO YOU OR ANY
        THIRD PARTY FOR ANY SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE,
        INDIRECT DAMAGES, OR ANY OTHER DAMAGES, WHICH SHALL INCLUDE, WITHOUT
        LIMITATION, DAMAGES FOR PERSONAL INJURY, LOST PROFITS, LOST DATA AND
        BUSINESS INTERRUPTION, ARISING OUT OF THE USE OR INABILITY TO USE THE
        SOFTWARE, EVEN IF LICENSOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGES (WHETHER SUCH DAMAGES ARISE IN CONTRACT, TORT (INCLUDING
        NEGLIGENCE), OR OTHERWISE). IN ANY CASE, THE ENTIRE LIABILITY OF
        LICENSOR AND ITS SUPPLIERS/LICENSORS UNDER THIS AGREEMENT FOR ALL
        DAMAGES OF EVERY KIND AND TYPE (WHETHER SUCH DAMAGES ARISE IN CONTRACT,
        TORT (INCLUDING NEGLIGENCE), OR OTHERWISE) SHALL BE LIMITED TO THE
        INITIAL LICENSE FEE PAID BY YOU, IF ANY, FOR THE SOFTWARE.
      </p>
      <p>
        Some states do not allow the exclusion of incidental or consequential
        damages, or the limitation on how long an implied warranty lasts, so
        some of the above may not apply to you.
      </p>
      <p>
        COMPLIANCE AND AUDIT. If requested by Licensor, you will submit an
        certification, signed by you, an officer, or an authorized
        representative, at Licensor’s discretion, specifying the number of users
        and number and location of all copies of the Software in Licensee’s
        possession or use. Licensor may also, once annually, upon reasonable
        notice and during regular business hours, gain access to your facilities
        and systems to audit compliance with the license restrictions set forth
        herein.
      </p>
      <p>
        INDEMNITY. Licensee agrees to indemnify, defend and hold harmless
        Licensor and its respective officers, directors, shareholders, agents,
        affiliates, and licensors from and against any and all third party
        claims of any kind (along with attorneys’ fees and litigation costs)
        arising out of, resulting from, or in connection with your breach of
        this Agreement or your use or misuse of the Software, including any
        combination of the Software with any hardware, software, or other
        intellectual property not provided by Licensor.
      </p>
      <p>
        GOVERNING LAW. This Agreement shall not be governed by the 1980 U.N.
        Convention on Contracts for the International Sale of Goods. This
        Agreement shall be construed in accordance with, and governed by, the
        laws of the State of Florida, without regard to the conflict of law
        provisions thereof. Other than disputes brought by Licensor against you
        associated with your infringement of Licensor’s intellectual property,
        such as your unauthorized use of Licensor’s software, which may be
        brought by Licensor in federal court in the Middle District of Florida
        against you at Licensor’s discretion, any dispute, controversy or claim
        arising out of or relating to this Agreement, including the formation,
        interpretation, breach or termination thereof, including whether the
        claims asserted are arbitrable, shall be referred to and finally
        determined by binding arbitration in accordance with the JAMS
        International Arbitration Rules. The Tribunal shall consist of one
        arbitrator. The place of arbitration shall be Florida. The language to
        be used in the arbitral proceedings shall be English. Judgment upon the
        award rendered by the arbitrator may be entered in any court having
        jurisdiction thereof. If the claims are determined to not be arbitrable
        or the parties mutually waive arbitration, any dispute arising out of
        this Agreement shall be submitted exclusively to the competent Courts of
        the State of Florida, without restricting any rights of appeal. EACH
        PARTY ACKNOWLEDGES AND AGREES THAT ANY CONTROVERSY THAT MAY ARISE UNDER
        OR RELATE TO THIS AGREEMENT IS LIKELY TO INVOLVE COMPLICATED AND
        DIFFICULT ISSUES AND, THEREFORE, EACH SUCH PARTY IRREVOCABLY AND
        UNCONDITIONALLY WAIVES ANY RIGHT IT MAY HAVE TO A TRIAL BY JURY IN
        RESPECT OF ANY ACTION ARISING OUT OF OR RELATING TO THIS AGREEMENT. Each
        party to this Agreement certifies and acknowledges that (a) no
        representative of any other party has represented, expressly or
        otherwise, that such other party would not seek to enforce the foregoing
        waiver in the event of any action, (b) such party has considered the
        implications of this waiver, (c) such party makes this waiver
        voluntarily, and (d) such party has been induced to enter into this
        agreement by, among other things, the mutual waivers and certifications
        in this section. In any action to enforce any terms of this Agreement,
        for breach of any provision of this Agreement, or otherwise related to
        this Agreement, the prevailing party shall be entitled to an award of
        their reasonable attorneys’ fees and costs. The parties waive any right
        to assert any claims against the other party as a representative or
        member in any class or representative action, except where such waiver
        is prohibited by law or deemed by a court of law to be against public
        policy. To the extent either party is permitted by law or court of law
        to proceed with a class or representative action against the other, the
        parties agree that: (i) the prevailing party shall not be entitled to
        recover attorneys’ fees or costs associated with pursuing the class or
        representative action (not withstanding any other provision in this
        agreement); and (ii) the party who initiates or participates as a member
        of the class shall not submit a claim or otherwise participate in any
        recovery secured through the class or representative action.
      </p>
      <p>
        GENERAL. This Agreement constitutes the entire understanding and
        agreement between Licensor and you with respect to the transactions
        contemplated in this Agreement and supersedes all prior or
        contemporaneous oral or written communications with respect to the
        subject matter of this Agreement, all of which are merged in this
        Agreement. In particular, if you are a current licensee of the Software,
        this Agreement shall supersede your existing license agreement and that
        agreement shall be of no further force or effect. This Agreement shall
        not be modified, amended or in any way altered except by an instrument
        in writing signed by authorized representatives of both parties. In the
        event that any provision of this Agreement is found invalid or
        unenforceable pursuant to judicial decree, the remainder of this
        Agreement shall remain valid and enforceable according to its terms. Any
        failure by Licensor to strictly enforce any provision of this Agreement
        will not operate as a waiver of that provision or any subsequent breach
        of that provision. Neither party shall assign this Agreement without the
        written consent of the other party, which consent shall not be
        unreasonably withheld. Notwithstanding the foregoing, Licensor may
        assign this Agreement, without the other party’s consent, to any
        Affiliate or subsidiary, or in the event of a merger, acquisition, or
        sale of all or substantially all of its assets. “Affiliate” means any
        entity that directly, or indirectly through one or more intermediaries,
        controls, or is controlled by, or is under common control with the
        party. Neither party will be liable for any failure or delay in
        performance under this Agreement which is due to any event beyond the
        reasonable control of such party, including without limitation, fire,
        explosion, unavailability of utilities or raw materials, unavailability
        of components, labor difficulties, war, riot, act of God, export control
        regulation, laws, judgments or government instructions. Licensor may
        assign any of its rights or obligations hereunder as it deems necessary.
      </p>
      <p>
        IT IS EXPRESSLY UNDERSTOOD AND AGREED THAT IN THE EVENT ANY REMEDY
        HEREUNDER IS DETERMINED TO HAVE FAILED OF ITS ESSENTIAL PURPOSE, ALL
        LIMITATIONS OF LIABILITY AND EXCLUSIONS OF DAMAGES SET FORTH HEREIN
        SHALL REMAIN IN EFFECT.
      </p>
      <p>
        U.S. GOVERNMENT RIGHTS. The Software is commercial computer software as
        described in DFARS 252.227-7014(a)(1) and FAR 2.101. If acquired by or
        on behalf of any the Department of Defense (“DOD”) or any component
        thereof, the U.S. Government acquires this commercial computer software
        or commercial computer software Documentation subject to the terms of
        this Agreement as specified in DFARS 227.7202-3, Rights in Commercial
        Computer Software or Commercial Computer Software Documentation. If
        acquired by or on behalf of any civilian agency, the U.S. Government
        acquires this commercial computer software or commercial computer
        software Documentation subject to the terms of this Agreement as
        specified in FAR 12.212, Computer Software.
      </p>
      <p>
        AUTHORIZATION. By downloading, installing, accessing, or using the
        Software, you indicate that you have the authority to bind yourself and
        your organization to the terms of this Agreement.
      </p>
    </>
  );
};

export default EULA;
