import React from "react";

const Mastercard = () => {
  return (
    <svg
      width="109"
      height="72"
      viewBox="0 0 109 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M68.8811 12.544H40.1274V59.4475H68.8811V12.544Z"
        fill="#FF5F00"
      />
      <path
        d="M43.0892 35.9999C43.0856 31.4835 44.1107 27.0254 46.087 22.9632C48.0632 18.9009 50.9388 15.3407 54.4961 12.5522C50.0902 9.0941 44.7991 6.94369 39.2274 6.34678C33.6557 5.74987 28.0283 6.73054 22.9883 9.17671C17.9483 11.6229 13.699 15.4359 10.7261 20.1799C7.75328 24.9239 6.17676 30.4075 6.17676 36.004C6.17676 41.6006 7.75328 47.0842 10.7261 51.8282C13.699 56.5722 17.9483 60.3852 22.9883 62.8313C28.0283 65.2775 33.6557 66.2582 39.2274 65.6613C44.7991 65.0644 50.0902 62.914 54.4961 59.4558C50.9377 56.6665 48.0614 53.105 46.0852 49.0412C44.1089 44.9774 43.0844 40.5177 43.0892 35.9999Z"
        fill="#EB001B"
      />
      <path
        d="M99.966 54.4849V53.5238H100.381V53.3249H99.3936V53.5238H99.7835V54.4849H99.966ZM101.882 54.4849V53.3249H101.584L101.235 54.1535L100.887 53.3249H100.588V54.4849H100.804V53.6066L101.127 54.3606H101.351L101.675 53.6066V54.4849H101.882Z"
        fill="#F79E1B"
      />
      <path
        d="M102.82 35.9999C102.82 41.5968 101.243 47.0808 98.2694 51.825C95.296 56.5691 91.0461 60.3821 86.0054 62.8279C80.9648 65.2737 75.3368 66.2537 69.7648 65.656C64.1928 65.0582 58.9016 62.9067 54.4961 59.4475C58.052 56.6565 60.9267 53.0955 62.9035 49.0329C64.8802 44.9704 65.9074 40.5126 65.9074 35.9957C65.9074 31.4789 64.8802 27.0211 62.9035 22.9586C60.9267 18.896 58.052 15.3349 54.4961 12.544C58.9016 9.08474 64.1928 6.93329 69.7648 6.33552C75.3368 5.73775 80.9648 6.71779 86.0054 9.16361C91.0461 11.6094 95.296 15.4224 98.2694 20.1665C101.243 24.9107 102.82 30.3947 102.82 35.9916V35.9999Z"
        fill="#F79E1B"
      />
    </svg>
  );
};

export default Mastercard;
