import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { rem } from "polished";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import Button from "./Button";

import ProjectsMenu from "./ProjectsMenu";

import { useAuth } from "../../../context/AuthContext";
import { useFonts } from "../../../context/FontsContext";

const animatedComponents = makeAnimated();

const ProjectSingle = ({ project }) => {
  const { updateProject, deleteProject } = useAuth();

  const [edit, setEdit] = useState(false);

  const { fonts, fontsOwned } = useFonts();

  const [fontsSelected, setFontsSelected] = useState([]);
  const [whitelist, setWhitelist] = useState([]);

  const [usableFonts, setUsableFonts] = useState(fonts);

  const handleFontsChange = (selectedItems) => {
    setFontsSelected(selectedItems);
  };

  const handleWhitelistChange = (e) => {
    setWhitelist(e.target.value);
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    setEdit(false);

    try {
      let fontIds = [];

      if (fontsSelected && fontsSelected.length > 0) {
        fontIds = fontsSelected.map((item) => item.value);
      } else {
        return "Must have at least 1 font selected";
      }

      let whitelistArray = [];

      if (whitelist && whitelist.length > 0) {
        whitelistArray = whitelist.split("\n");
      } else {
        return "Must have at least 1 site whitelisted";
      }

      updateProject(project._id, fontIds, whitelistArray)
        .then(() => {
          setEdit(false);
          setFontsSelected([]);
          setWhitelist([]);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    // Pulls out all the info for the fonts the user owns
    const adjustedFonts = fonts.filter(
      (font) => fontsOwned.indexOf(font._id) > -1,
    );

    setUsableFonts(adjustedFonts);
  }, [fonts, fontsOwned]);

  let fontOptions = [];

  usableFonts.forEach((font) => {
    fontOptions.push({ label: font.name, value: font._id });
  });

  const copyLink = (link) => {
    const el = document.createElement("textarea");
    el.value = link;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  const toggleEdit = () => {
    const newEdit = !edit;
    if (newEdit) {
      const fontOptions = project.fonts.map((font) => {
        return { label: font.name, value: font._id };
      });

      console.log(fontOptions);

      setFontsSelected(fontOptions);

      setWhitelist(project.whitelist.join("\n"));
    }

    setEdit(newEdit);
  };

  return (
    <tr>
      <td>
        <FontsWrapper>
          <ProjectsMenu
            edit={edit}
            handleEdit={toggleEdit}
            handleDelete={() => {
              deleteProject(project._id);
            }}
          />
          {!edit ? (
            <>
              {project.fonts.map((font, index) => (
                <CommaSeparated key={index}>{font.name}</CommaSeparated>
              ))}
            </>
          ) : (
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              options={fontOptions}
              onChange={handleFontsChange}
              value={fontsSelected}
              isMulti
              makeAnimated
            />
          )}
        </FontsWrapper>
      </td>
      <td>
        {!edit ? (
          <>
            {project.whitelist.map((site, index) => (
              <NewlineSeparated key={index}>{site}</NewlineSeparated>
            ))}
          </>
        ) : (
          <ProjectInput
            type="text"
            active={true}
            disabled={false}
            onChange={handleWhitelistChange}
            value={whitelist}
          />
        )}
      </td>
      <td>
        <NoWrap>{project.requests} loads</NoWrap>
      </td>
      <td className="center">
        {!edit ? (
          <CopyButtons>
            <SmallerButton onClick={() => copyLink(project.links.html)}>
              Copy Code
            </SmallerButton>
            <SmallerButton onClick={() => copyLink(project.links.css)}>
              Copy CSS
            </SmallerButton>
          </CopyButtons>
        ) : (
          <form onSubmit={handleUpdate}>
            <SmallerButton>Save</SmallerButton>
          </form>
        )}
      </td>
    </tr>
  );
};

const ProjectInput = styled.textarea`
  margin-bottom: 0px;
  background-color: ${(props) => (props.active ? "white" : "transparent")};
  min-height: 4em;
`;

const FontsWrapper = styled.div`
  position: relative;
  padding-left: ${rem("60px")};
`;

const CommaSeparated = styled.span`
  &:not(:last-child)::after {
    content: ", ";
  }
`;

const NewlineSeparated = styled.div``;

const NoWrap = styled.span`
  white-space: nowrap;
`;

const CopyButtons = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const NoWrapButton = styled(Button)`
  white-space: nowrap;
`;

const SmallerButton = styled(NoWrapButton)`
  font-size: ${(props) => props.theme.fontSize_l};
  font-weight: 500;
  padding: 6px 10px;
  width: ${rem("115px")};
`;

export default ProjectSingle;
