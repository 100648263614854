import styled from "styled-components";
import { rgba } from "polished";

const FormButton = styled.button`
  background-color: ${(props) =>
    props.inverse ? props.theme.colors.gray : props.theme.colors.green};
  color: ${(props) => (props.inverse ? props.theme.colors.black : "white")};
  border-radius: 15px;
  width: ${(props) => (props.small ? "auto" : "100%")};
  font-weight: bold;
  padding: 25px;
  font-size: ${(props) => props.theme.fontSize_xl};
  transition: 0.3s ease-in-out;

  &:disabled {
    background-color: ${(props) => props.theme.colors.grayDark};
    cursor: not-allowed;
  }

  &:hover,
  &:focus {
    &:not(:disabled) {
      background-color: ${(props) =>
        props.inverse
          ? rgba(props.theme.colors.gray, 0.8)
          : rgba(props.theme.colors.green, 0.8)};
      transform: scale(0.95);
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;

  > * {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export default FormButton;
