import styled from "styled-components";
import posed from "react-pose";

const PosedFormMessage = posed.div({
  enter: {
    y: "0%",
    opacity: 1,
    transition: { duration: 200 },
  },
  exit: {
    y: "-100%",
    opacity: 0,
    transition: { duration: 200 },
  },
});

const FormMessage = styled(PosedFormMessage)`
  color: ${(props) => props.theme.colors.negative};
  font-size: ${(props) => props.theme.fontSize_s};
  margin-top: 3px;
`;

export default FormMessage;
