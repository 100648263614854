import React from "react";
import styled from "styled-components";

import { rem } from "polished";

const Characters = ({ font }) => {
  const hideImage = (ev) => {
    ev.target.remove();
  };

  return font ? (
    <ImageContainer>
      <Image
        src={`${process.env.REACT_APP_API_URL}/assets/fonts/${font.slug}/characters.png`}
        onError={hideImage}
      />
    </ImageContainer>
  ) : null;
};

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: ${rem("1328px")};
`;

export default Characters;
