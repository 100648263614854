import React from "react";
import posed from "react-pose";
import styled from "styled-components";

import { colors } from "../../../styles/styles";

const CtaButton = ({ pose, color, children, ...rest }) => {
  return (
    <Button pose={pose} color={color} {...rest}>
      {children}
    </Button>
  );
};

const PosedButton = posed.button({
  static: {
    color: colors.yellow,
    borderColor: colors.yellow,

    // delay 1sec so its in sync with the rest of the sidebar animation
    transition: { duration: 200, delay: 1000 },

    // disable pointer events during the transition since the hover effect will have the wrong color
    applyAtStart: { pointerEvents: "none" },
    applyAtEnd: { pointerEvents: "auto" },
  },
  active: {
    color: colors.green,
    borderColor: colors.green,

    transition: { duration: 200, delay: 1000 },

    applyAtStart: { pointerEvents: "none" },
    applyAtEnd: { pointerEvents: "auto" },
  },
});

const Button = styled(PosedButton)`
  display: block;
  width: 100%;
  font-size: ${(props) => props.theme.fontSize_xl};
  font-weight: ${(props) => props.theme.fontWeight_m};
  line-height: 1;
  text-align: center;
  letter-spacing: 0.01em;
  padding: 20px 10px;
  border-radius: ${(props) => props.theme.borderRadius};

  margin-top: ${(props) => props.theme.space_s};

  color: ${(props) => props.theme.colors[props.color]};

  border-width: 2px;
  border-style: solid;

  transition-property: background-color, color;
  transition-duration: 200ms;
  transition-timing-function: ease;

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors[props.color]};
    /* !important to override the color being applied by the pose */
    color: ${(props) => props.theme.colors.white} !important;
  }
`;

export default CtaButton;
