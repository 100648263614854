import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { format } from "date-fns";

import { respondTo } from "../../../styles/styleHelpers";

import ArrowBtn from "../../../components/ArrowBtn";

const ListItem = ({ font, action, grid, sampleText, disabled, isPremium }) => {
  const fontDate = new Date(font.added);

  const tempMonth = fontDate.getUTCMonth() + 1;
  const month = tempMonth < 10 ? "0" + tempMonth : "" + tempMonth;

  const year = fontDate.getUTCFullYear();

  // if the font is owned by a non-premium user, the action does nothing
  const notInteractive = font.owned && !isPremium;

  return (
    <Item className={grid ? "-grid" : undefined}>
      <ItemAction
        onClick={
          notInteractive
            ? undefined
            : () => {
                action(font._id);
              }
        }
        as={notInteractive ? "div" : undefined}
        aria-label={
          font.owned
            ? isPremium
              ? `Deactivate ${font.name}`
              : "Owned"
            : `Select ${font.name}`
        }
        checkmark={font.owned}
        selected={font.selected}
        disabled={disabled}
        isInteractive={!notInteractive}
      />
      <ItemLink to={`/fonts/${font.slug}`}>
        {/* placeholder element, so the others are flex positioned correctly */}
        {grid ? null : (
          <ItemArrow
            style={{ opacity: 0, visibility: "hidden" }}
            aria-hidden="true"
            as="div"
          />
        )}

        <ItemTitle fontFamily={font.name}>{sampleText || font.name}</ItemTitle>
        <ItemMeta>
          {font.name}&nbsp;&nbsp;-&nbsp;&nbsp;
          {font.serialNumber ? `${font.serialNumber}\xa0\xa0-\xa0\xa0` : ``}
          <time dateTime={`${year}-${month}`}>
            {month}&nbsp;/&nbsp;{year}
          </time>
          {/* <span>10,576 Uses</span> */}
        </ItemMeta>
        <ItemArrow aria-hidden="true" as="div"></ItemArrow>
      </ItemLink>
    </Item>
  );
};

const ItemTitle = styled.span`
  font-family: ${(props) => `${props.fontFamily}, ${props.theme.fontFamily}`};
  /* font-size: 9.1vw; */
  /* font-size: 8vw; */
  font-size: 7.5vw;
  line-height: 77.5%;
  color: ${(props) => props.theme.colors.black};

  text-align: center;
  letter-spacing: 0.01em;
  max-width: calc(100% - 36px - 36px);

  transition: color 200ms ease;

  .-grid & {
    margin-top: 65px;
    max-width: 100%;
  }

  ${respondTo("medium")} {
    .-grid & {
      font-size: 3.3vw;
    }
  }

  ${respondTo("large")} {
    .-grid & {
      font-size: 2.25vw;
    }
  }

  ${respondTo("xlarge")} {
    font-size: 8vw;

    .-grid & {
      font-size: 2.5vw;
    }
  }
`;

const ItemArrow = styled(ArrowBtn)`
  position: relative;
  width: 36px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.pumice};
  border-radius: 50%;
  flex-shrink: 0;

  opacity: 0;
  transition: opacity 200ms ease;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-65%, -50%) rotate(-45deg);
    width: 11px;
    height: 11px;
    border: 2px solid ${(props) => props.theme.colors.blackLight};
    border-top: 0;
    border-left: 0;
  }

  .-grid & {
    order: -1;
    margin-left: auto;
  }
`;

const ItemMeta = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  font-size: ${(props) => props.theme.fontSize_s};
  font-weight: ${(props) => props.theme.fontWeight_m};
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.04em;
  color: ${(props) => props.theme.colors.grayDark};

  opacity: 0;
  transition: opacity 200ms ease;

  .-grid & {
    position: static;
    margin-top: 80px;
  }

  > * {
    margin-right: 95px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const ItemLink = styled(Link)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  max-width: 100%;
  overflow: hidden;
  padding: 60px 0;

  .-grid & {
    justify-content: stretch;
    flex-direction: column;
    flex-grow: 1;
    padding: 22px;
    border: 0 solid ${(props) => props.theme.colors.gray};
    border-bottom-width: 2px;
    /* margin-right: -2px; */
    margin-bottom: -2px;

    ${respondTo("medium")} {
      border-right-width: 2px;
    }
  }

  &:hover,
  &:focus {
    ${ItemTitle} {
      color: ${(props) => props.theme.colors.pumice};
    }

    ${ItemArrow} {
      opacity: 1;
    }

    ${ItemMeta} {
      opacity: 1;
    }
  }
`;

const Item = styled.li`
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;
  max-width: 100%;
  display: flex;

  &.-grid {
    ${respondTo("medium")} {
      flex-basis: ${(1 / 3) * 100}%;
      max-width: ${(1 / 3) * 100}%;

      /* remove the border-right on every 3rd grid item */
      &:nth-of-type(3n + 0) {
        ${ItemLink} {
          border-right: 0;
        }
      }
    }
  }
`;

const ItemAction = styled.button`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 36px;
  height: 36px;
  border: 2px solid ${(props) => props.theme.colors.grayDark};
  border-radius: 50%;
  z-index: 1;

  //pointer-events: ${(props) => (props.isInteractive ? "auto" : "none")};

  transition: border-color 200ms ease, background-color 200ms ease,
    box-shadow 200ms ease;

  .-grid & {
    top: 22px;
    left: 22px;
    transform: none;
  }

  &::before,
  &::after {
    content: "";
    width: 16px;
    height: 2px;
    background-color: ${(props) => props.theme.colors.grayDark};

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    transition: opacity 150ms ease;
  }
  &::after {
    transform: translate(-50%, -50%) rotate(-90deg);
  }

  &:disabled {
    cursor: not-allowed;
    border-color: ${(props) => props.theme.colors.gray};

    &::before,
    &::after {
      background-color: ${(props) => props.theme.colors.gray};
    }
  }

  ${(props) =>
    !props.checkmark
      ? css`
          &:hover,
          &:focus {
            &:not(:disabled) {
              background-color: ${(props) => props.theme.colors.positive};
              border-color: ${(props) => props.theme.colors.positive};

              &::before,
              &::after {
                background-color: ${(props) => props.theme.colors.white};
              }
            }
          }
        `
      : ``}

  ${(props) =>
    props.selected
      ? css`
          border-color: ${(props) => props.theme.colors.warning};

          background-color: ${(props) => props.theme.colors.warning};

          &::before {
            /* background-color: ${(props) => props.theme.colors.positive}; */
            background-color: ${(props) => props.theme.colors.white};
          }
          &::after {
            opacity: 0;
          }

          &:hover, &:focus {
            &:not(:disabled) {
              border-color: ${(props) => props.theme.colors.negative};
              background-color: ${(props) => props.theme.colors.negative};
            }
          }
        `
      : ``}

  ${(props) =>
    props.checkmark
      ? css`
          background-color: ${(props) => props.theme.colors.positive};
          border-color: ${(props) => props.theme.colors.positive};

          &::before {
            opacity: 0;
          }
          &::after {
            width: 15px;
            height: 8px;
            background-color: transparent;
            border: 2px solid ${(props) => props.theme.colors.white};
            border-top: 0;
            border-right: 0;
            transform: translate(-50%, -70%) rotate(-45deg);
          }

          ${(props) =>
            props.isInteractive
              ? css`
                  &:hover,
                  &:focus {
                    &:not(:disabled) {
                      &::after {
                        background-color: transparent;
                      }
                    }
                  }

                  &:focus {
                    &:not(:disabled) {
                      box-shadow: 0 0 4px
                        ${(props) => props.theme.colors.positive};
                    }
                  }

                  &:hover {
                    &:not(:disabled) {
                      background-color: ${(props) =>
                        props.theme.colors.negative};
                      border-color: ${(props) => props.theme.colors.negative};
                      box-shadow: 0 0 4px
                        ${(props) => props.theme.colors.negative};
                    }
                  }
                `
              : ``}
        `
      : ``}
`;

export default ListItem;
