import React from "react";
import styled from "styled-components";
import { rem } from "polished";

import { visuallyHidden, respondTo } from "../../../styles/styleHelpers";
import FilterRadio from "./FilterRadio";

const Filters = ({
  gridView,
  setGridView,
  sortAlpha,
  setSortAlpha,
  display,
  setDisplay,
  sampleText,
  setSampleText,
}) => {
  return (
    <Container>
      <FilterForm aria-label="Font filters and sorting">
        <Segment>
          <Input
            name="sample-text"
            id="sample-text"
            placeholder="Enter Sample Text"
            ariaLabel="Sample Text"
            value={sampleText}
            onChange={(e) => {
              setSampleText(e.target.value);
            }}
            autoComplete="off"
          />
        </Segment>

        <Fieldset>
          <Legend>View</Legend>
          <FieldsetInner>
            <FilterRadio
              name="view"
              id="view-list"
              label="List"
              value="list"
              checked={!gridView}
              onChange={() => {
                setGridView(false);
              }}
            />
            <FilterRadio
              name="view"
              id="view-grid"
              label="Grid"
              value="grid"
              checked={gridView}
              onChange={() => {
                setGridView(true);
              }}
            />
          </FieldsetInner>
        </Fieldset>

        <Fieldset>
          <Legend>Sort</Legend>
          <FieldsetInner>
            <FilterRadio
              name="sort"
              id="sort-recent"
              label="Recent"
              value="recent"
              checked={!sortAlpha}
              onChange={() => {
                setSortAlpha(false);
              }}
            />
            <FilterRadio
              name="sort"
              id="sort-alphabetical"
              label="Alphabetical"
              value="alphabetical"
              checked={sortAlpha}
              onChange={() => {
                setSortAlpha(true);
              }}
            />
          </FieldsetInner>
        </Fieldset>

        <Fieldset>
          <Legend>Display</Legend>
          <FieldsetInner>
            <FilterRadio
              name="display"
              id="display-all"
              label="All"
              value="all"
              checked={display === "all"}
              onChange={() => {
                setDisplay("all");
              }}
            />
            <FilterRadio
              name="display"
              id="display-added"
              label="Added"
              value="added"
              checked={display === "added"}
              onChange={() => {
                setDisplay("added");
              }}
            />
            <FilterRadio
              name="display"
              id="display-not-added"
              label="Not Added"
              value="not-added"
              checked={display === "not-added"}
              onChange={() => {
                setDisplay("not-added");
              }}
            />
          </FieldsetInner>
        </Fieldset>
      </FilterForm>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding-top: ${(props) => props.theme.space};

  ${respondTo("medium")} {
    position: sticky;
    top: ${(props) => props.theme.toggleHeight}px;
    left: 0;
    z-index: 3;
  }
  ${respondTo("large")} {
    top: 0;
  }
`;

const FilterForm = styled.form`
  background-color: ${(props) => props.theme.colors.pumice};
  border-radius: ${(props) => props.theme.borderRadius};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  ${respondTo("medium")} {
    flex-direction: row;
  }

  @media (min-width: 1240px) {
    flex-wrap: nowrap;
  }
`;

const Fieldset = styled.fieldset`
  padding: 15px;

  flex-grow: 1;

  ${respondTo("medium")} {
    border-right: 2px solid ${(props) => props.theme.colors.gray};

    &:last-child {
      border-right-width: 0;
    }
  }

  @media (min-width: 1240px) {
    &:last-child {
      padding-right: 40px;
    }
  }

  ${respondTo("xlarge")} {
    padding: 15px 35px;
  }
`;

const FieldsetInner = styled.div`
  display: flex;

  > * {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const Legend = styled.legend`
  ${visuallyHidden()}
`;

const Segment = styled.div`
  display: flex;
  padding: 15px;

  flex-basis: 100%;
  max-width: 100%;

  ${respondTo("medium")} {
    padding-bottom: 0;
  }

  @media (min-width: 1240px) {
    padding-right: 5px;
    padding-bottom: 15px;
    flex-basis: 25%;
    max-width: 25%;
  }

  ${respondTo("xlarge")} {
    flex-basis: 30%;
    max-width: 30%;
  }
`;

const Input = styled.input`
  background-color: ${(props) => props.theme.colors.white};
  border: 0;
  border-radius: 8px;
  color: ${(props) => props.theme.colors.black};

  font-size: ${(props) => props.theme.fontSize_l};
  font-weight: 600;
  line-height: 1.28;
  flex-grow: 1;
  padding: 0 20px;
  max-width: 100%;
  min-height: 45px;

  ${respondTo("xlarge")} {
    font-size: ${rem("21px")};
  }
`;

export default Filters;
