import React, { useState } from "react";
import differenceInMonths from "date-fns/differenceInMonths";
import { PoseGroup } from "react-pose";
import styled from "styled-components";

import { useAuth } from "../../../context/AuthContext";

import Accordion from "./Accordion";
import Table from "./Table";
import Input, { InputField } from "./Input";
import Button from "./Button";
import Panel, { PanelLeft, PanelRight, PanelMessage } from "./Panel";
import FormMessage from "../../../components/FormMessage";

const ReferralsPanel = () => {
  const { user, invite } = useAuth();

  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [hasCopied, setHasCopied] = useState(false);

  const handleInvite = async (e) => {
    e.preventDefault();

    const emailToInvite = email;

    try {
      setError(null);

      await invite(emailToInvite);

      // if successful, reset the field
      setEmail("");
    } catch (err) {
      // if it fails, set the email back to what it was
      setEmail(emailToInvite);

      if (err.data && err.data.errors) {
        setError(err.data.errors.email[0]);
      }
    }
  };

  const copyLink = () => {
    var copyText = document.querySelector("#copyInput");
    copyText.select();
    document.execCommand("copy");
    setHasCopied(true);
  };

  return (
    <Accordion title="Rewards" id="referrals">
      <Panel>
        <PanelLeft>
          {user.referralId ? (
            <>
              <InviteHeader>Invite By Link</InviteHeader>
              <CopyHeader>Your unique invite url</CopyHeader>
              <CopyContainer>
                <CopyInput
                  id="copyInput"
                  type="text"
                  value={`${process.env.REACT_APP_API_URL}?referrer=${user.referralId}`}
                  readOnly
                />
                <CopyButton onClick={copyLink}>
                  {hasCopied ? `copied!` : `copy`}
                </CopyButton>
              </CopyContainer>
            </>
          ) : null}
          <PanelMessage>
            Every active referral gets you 10% off your membership.
          </PanelMessage>
          <PanelMessage>
            Get 10 friends onto an unlimited membership and get yours for free!
          </PanelMessage>
        </PanelLeft>
        <PanelRight>
          <InviteHeader>Invite By Email</InviteHeader>
          <InviteForm onSubmit={handleInvite}>
            <InviteField>
              <Input
                placeholder="Enter Email"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                disabled={!user.isActive}
              />

              <PoseGroup>
                {error ? <FormMessage key="email">{error}</FormMessage> : null}
              </PoseGroup>
            </InviteField>

            <InviteButton type="submit" disabled={!email || !user.isActive}>
              Send Invite
            </InviteButton>
          </InviteForm>
          <Table>
            <thead>
              <tr>
                <th>Referral Email</th>
                <th className="condensed">Account Age</th>
                <th className="condensed">Status</th>
              </tr>
            </thead>
            <tbody>
              {user.referrals && user.referrals.length ? (
                user.referrals.map((referral) => {
                  const { invitee } = referral;

                  let age;

                  if (invitee) {
                    // amount of months since they joined
                    let months = differenceInMonths(
                      new Date(),
                      new Date(invitee.createdAt),
                    );

                    // round down
                    months = Math.floor(months);

                    // convert to years (rounded down)
                    const years = Math.floor(months / 12);

                    // use months only if less than 12 months
                    age =
                      months < 1
                        ? "< 1 month"
                        : months >= 12
                        ? `${years} year${years > 1 ? "s" : ""}`
                        : `${months} month${months > 1 ? "s" : ""}`;
                  }

                  return (
                    <tr key={referral.email}>
                      <td>{referral.email}</td>
                      <td>{age}</td>
                      <td>
                        {invitee
                          ? invitee.isActive
                            ? "Active"
                            : "Inactive"
                          : "Sent"}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="3">No accepted referrals.</td>
                </tr>
              )}
            </tbody>
          </Table>
        </PanelRight>
      </Panel>
    </Accordion>
  );
};

const InviteHeader = styled.h3`
  font-size: ${(props) => props.theme.fontSize_xl};
  font-weight: 700;
  color: ${(props) => props.theme.colors.grayDark};
  margin-bottom: 44px;
`;

const CopyHeader = styled.h3`
  font-size: ${(props) => props.theme.fontSize_xl};
  font-weight: 700;
  color: ${(props) => props.theme.colors.grayDark};
  margin-bottom: 10px;
`;

const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CopyInput = styled.input`
  width: 100%;
  margin-bottom: 5px;
`;

const CopyButton = styled.button`
  font-weight: 500;
  color: ${(props) => props.theme.colors.green};
  transition: 0.2s;
  text-decoration: underline;

  &:hover {
    color: ${(props) => props.theme.colors.grayDark};
  }
`;

const InviteForm = styled.form`
  display: flex;
  margin-bottom: 30px;
`;

const InviteField = styled(InputField)`
  width: 100%;
  margin-right: 30px;
  margin-bottom: 0;
`;

const InviteButton = styled(Button)`
  min-width: max-content;
  border-radius: 60px;
`;

export default ReferralsPanel;
