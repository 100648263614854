import React from "react";
import styled from "styled-components";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

import { colors, typography } from "../styles/styles";
import { respondTo } from "../styles/styleHelpers";

const CardFields = ({ ...rest }) => {
  const options = {
    style: {
      base: {
        iconColor: colors.grayDark,
        backgroundColor: "#fff",
        color: colors.black,
        fontFamily: typography.fontFamily,
        // fontSize: typography.fontSize,
        ":-webkit-autofill": { color: colors.black },
        "::placeholder": { color: colors.grayDark },
      },
      invalid: {
        iconColor: colors.negative,
        color: colors.negative,
      },
      complete: {
        iconColor: colors.positive,
      },
    },
  };

  return (
    <Container>
      <Wrapper aria-label="Credit card number">
        <CardNumberElement {...rest} options={options} />
      </Wrapper>
      <HalfWrapper aria-label="Credit card expiration date">
        <CardExpiryElement {...rest} options={options} />
      </HalfWrapper>
      <HalfWrapper aria-label="Credit card cvc number">
        <CardCvcElement {...rest} options={options} />
      </HalfWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const Wrapper = styled.label`
  background-color: #fff;
  border-radius: 9px;
  padding: 15px 5px;
  flex-grow: 1;
  flex-basis: 100%;

  ${respondTo("xlarge")} {
    padding: 15px;
    flex-basis: 100%;

    /* &:not(:last-child) {
      margin-bottom: 15px;
    } */
  }
`;

const HalfWrapper = styled(Wrapper)`
  margin-top: 15px;
  flex-basis: calc(50% - 6px);

  &:not(:last-child) {
    margin-right: 12px;
  }

  ${respondTo("xlarge")} {
    margin-top: 15px;
    flex-basis: calc(50% - 6px);
  }
`;

export default CardFields;
