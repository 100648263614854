import React from "react";
import styled from "styled-components";
import { rem } from "polished";

import Accordion from "./Accordion";
import Table from "./Table";
import Panel from "./Panel";

import ProjectSingle from "./ProjectSingle";
import ProjectNew from "./ProjectNew";

import HelpIcon from "../../../components/icons/Help";

import { useAuth } from "../../../context/AuthContext";

const ProjectsPanel = () => {
  const { user } = useAuth();

  return (
    <Accordion title="Projects">
      <Panel>
        <Table>
          <thead>
            <tr>
              <th>
                <b>Font</b>
              </th>
              <th>
                <WhitelistWrapper>
                  Whitelist
                  <HelpWrapper>
                    <HelpButton />
                    <HelpTooltip aria-label="whitelist tips">
                      <HelpList>
                        <li>
                          Insert any sites you are going to be using these fonts
                          on
                        </li>
                        <li>Separate each domain by a new line</li>
                        <li>Asterisk * acts as a wildcard</li>
                        <li>Ex: https://google.com/*</li>
                      </HelpList>
                    </HelpTooltip>
                  </HelpWrapper>
                </WhitelistWrapper>
              </th>
              <th>Usage</th>
              <th className="center condensed">Web Embed Code</th>
            </tr>
          </thead>
          <tbody>
            {user.projects
              ? user.projects.map((project, index) => (
                  <ProjectSingle key={index} project={project} />
                ))
              : null}
            <ProjectNew />
          </tbody>
        </Table>
      </Panel>
    </Accordion>
  );
};

const WhitelistWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const HelpButton = styled(HelpIcon)`
  display: block;
  margin-bottom: 3px;
  cursor: pointer;
`;

const HelpTooltip = styled.div`
  min-width: 260px;
  position: absolute;
  top: 28px;
  left: 0px;
  background-color: ${(props) => props.theme.colors.white};
  border: 2px solid ${(props) => props.theme.colors.pumice};
  border-radius: 15px;
  padding: ${rem("20px")} ${rem("25px")};
  z-index: 2;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
  font-size: ${(props) => props.theme.fontSize_xs};
`;

const HelpList = styled.ul`
  padding-left: 1em;
  list-style: none;
  position: relative;
  margin-bottom: 0;

  li {
    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &::before {
      content: "-";
      position: absolute;
      left: 0;
    }
  }
`;

const HelpWrapper = styled.span`
  position: relative;
  padding-left: 10px;

  &:hover,
  &:focus,
  &:focus-within {
    ${HelpTooltip} {
      opacity: 1;
      pointer-events: auto;
    }
  }
`;

export default ProjectsPanel;
