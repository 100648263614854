import React, { useState } from "react";
import styled from "styled-components";
import { rem } from "polished";

import { useFonts } from "../../../context/FontsContext";
import { respondTo } from "../../../styles/styleHelpers";

const FontSection = () => {
  const [text, setText] = useState("");
  const [count, setCount] = useState(2);
  const { fonts } = useFonts();

  const handleInputChange = (e) => {
    setText(e.target.value);
  };

  return (
    <FontContainer>
      {fonts.map((font, i) => {
        if (i <= count) {
          return (
            <Font key={font.slug}>
              <h3 className="font__title">{font.name}</h3>
              <Input
                type="text"
                value={text}
                onChange={handleInputChange}
                className="font__text -blonde"
                fontFamily={font.name}
                placeholder="The Quick Brown Fox"
                aria-label={`Test the appearance of ${font.name}`}
              />
              <p className="font__info">
                {font.characterSet.length} Glyphs / Display Font / Volume 01
              </p>
            </Font>
          );
        } else {
          return "";
        }
      })}
      {fonts.length > count + 1 && (
        <ViewMore href="#!" onClick={() => setCount(count + 3)}>
          View More
        </ViewMore>
      )}
    </FontContainer>
  );
};

const FontContainer = styled.div`
  color: ${(props) => props.theme.colors.grayDark};
  padding-bottom: 65px;
  width: 85%;
  margin: auto;
  max-width: 2000px;

  ${respondTo("medium")} {
    padding-bottom: 90px;
  }

  ${respondTo("large")} {
    width: 100%;
    padding-bottom: 121px;
    margin-left: 0;
  }

  ${respondTo("xlarge")} {
    padding-bottom: 206px;
  }
`;

const Font = styled.div`
  margin-bottom: 32px;

  .font__title {
    border-bottom: 1px solid ${(props) => props.theme.colors.blackLight};
    font-weight: bold;
    letter-spacing: 0.02em;
    margin-bottom: 0px;
    font-size: ${(props) => props.theme.fontSize_l};
  }

  .font__text {
    border-bottom: 1px solid ${(props) => props.theme.colors.blackLight};
    line-height: 1;
    text-align: center;
    margin-bottom: 0px;
    outline: none;
    width: 100%;
    padding: 29px 0px;

    &.-blonde {
      font-size: ${rem("23px")};
    }

    &.-nomad {
      font-size: ${(props) => props.theme.fontSize};
    }

    &.-husky {
      font-size: ${rem("25px")};
    }
  }

  .font__info {
    border-bottom: 1px solid ${(props) => props.theme.colors.blackLight};
    font-weight: 600;
    letter-spacing: 0.02em;
    margin-bottom: 0px;
    font-size: ${(props) => props.theme.fontSize_xs};
    padding: 6px 0px;
  }

  ${respondTo("medium")} {
    .font__title {
      font-size: ${(props) => props.theme.fontSize_xl};
    }

    .font__text {
      padding: 29px 0px 34px 0px;

      &.-blonde {
        font-size: ${rem("40px")};
      }

      &.-nomad {
        font-size: ${rem("32px")};
      }

      &.-husky {
        font-size: ${rem("40px")};
      }
    }

    .font__info {
      font-size: ${(props) => props.theme.fontSize_s};
      padding: 6px 0px;
    }
  }

  ${respondTo("large")} {
    margin-right: 7%;
  }

  ${respondTo("xlarge")} {
    margin-bottom: 80px;

    .font__title {
      font-size: ${rem("24px")};
      padding-bottom: 10px;
    }

    .font__text {
      padding: 63px 0px 58px 0px;

      &.-blonde {
        font-size: ${rem("96px")};
      }

      &.-nomad {
        font-size: ${rem("65px")};
      }

      &.-husky {
        font-size: ${rem("96px")};
      }
    }

    .font__info {
      padding: 15px 0px;
    }
  }
`;

const Input = styled.input`
  font-family: ${(props) => `${props.fontFamily}, ${props.theme.fontFamily}`};
`;

const ViewMore = styled.a`
  background-color: ${(props) => props.theme.colors.yellow};
  color: ${(props) => props.theme.colors.white};
  transition: 0.3s ease-in-out;
  font-weight: bold;
  border-radius: 8px;
  letter-spacing: 0.02em;
  padding: 10px 44px;
  font-size: ${(props) => props.theme.fontSize_s};
  display: inline-block;
  margin-top: 18px;

  &:hover,
  &:focus {
    background-color: rgba(223, 188, 87, 0.8);
    transform: scale(0.95);
  }

  ${respondTo("xlarge")} {
    margin-top: 0px;
    padding: 14px 55px;
  }
`;

export default FontSection;
