import styled from "styled-components";

import { respondTo } from "../styles/styleHelpers";

const Label = styled.label`
  color: ${(props) => props.theme.colors.grayDark};
  letter-spacing: 0.01em;
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSize_l};
  margin-bottom: 9px;
  line-height: 1.25;
  display: block;

  ${respondTo("xlarge")} {
    margin-bottom: 15px;
  }
`;

export default Label;
