import React from "react";
import styled from "styled-components";
import Swiper from "react-id-swiper";
import "../../../vendor/swiper/swiper.css";
import { respondTo } from "../../../styles/styleHelpers";

const Slider = () => {
  const params = {
    effect: "fade",
    speed: 1500,
    draggable: false,
    loop: true,
    allowTouchMove: false,
    autoplay: {
      delay: 4000,
    },
  };

  const importAll = (r) => {
    let images = [];
    r.keys().forEach((item) => {
      images.push(r(item));
    });
    return images;
  };

  const bannerImages = importAll(
    require.context(
      "../../../images/fonts",
      false,
      /banner-[0-9]\.(png|jpe?g)$/,
    ),
  );

  return (
    <SliderContainer>
      <Swiper {...params}>
        {bannerImages.map((image, index) => (
          <Slide key={index}>
            <SlideImage src={image} alt=""></SlideImage>
          </Slide>
        ))}
      </Swiper>
    </SliderContainer>
  );
};

const SliderContainer = styled.div`
  border-radius: 15px;
`;

const Slide = styled.div`
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
  padding-bottom: 70%;

  ${respondTo("medium")} {
    padding-bottom: 40%;
  }
  ${respondTo("large")} {
    padding-bottom: 35%;
  }
  ${respondTo("xlarge")} {
    padding-bottom: 29.5%;
  }
  ${respondTo("xXlarge")} {
    padding-bottom: 0;
    height: 427px;
  }
`;

const SlideImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default Slider;
