import React from "react";
import { Link, Redirect } from "react-router-dom";
import styled from "styled-components";

import { useFonts } from "../../context/FontsContext";

import ArrowBtn from "../../components/ArrowBtn";
import FontPreview from "./components/FontPreview";
import Characters from "./components/Characters";
import Mosaic from "./components/Mosaic";

import { respondTo } from "../../styles/styleHelpers";

const Font = ({ match }) => {
  const { fonts } = useFonts();

  const fontSlug = match.params.slug;

  const font = fonts.find((thisFont) => thisFont.slug === fontSlug);
  const fontName = font ? font.name : "";

  return fonts.length ? (
    // only render if the fonts have loaded and the desired font exists
    font ? (
      <Container>
        <BackBtn as={Link} to="/fonts" back={1}></BackBtn>

        <FontPreview font={fontName} />

        <Characters font={font} />

        <Mosaic slug={fontSlug} />
      </Container>
    ) : (
      // if the font doesn't exist, redirect to main fonts view
      <Redirect to="/fonts" />
    )
  ) : null;
};

const BackBtn = styled(ArrowBtn)`
  position: sticky;
  top: ${(props) => `${props.theme.toggleHeight + 30}px`};
  left: 0;
  z-index: 3;

  ${respondTo("large")} {
    top: 80px;
    margin-left: 40px;
  }
`;

const Container = styled.div`
  margin-bottom: 50px;

  ${respondTo("xlarge")} {
    margin-bottom: 60px;
  }
`;

export default Font;
