import React from "react";
import styled from "styled-components";
import { rem } from "polished";

const Table = ({ children }) => {
  return (
    <Overflow>
      <TableContainer>{children}</TableContainer>
    </Overflow>
  );
};

const Overflow = styled.div`
  width: 100%;
  /* overflow-x: auto; */
`;

const TableContainer = styled.table`
  /* border-collapse: collapse; */
  border-collapse: separate;
  border: 3px solid ${(props) => props.theme.colors.pumice};
  border-radius: 15px;
  border-spacing: 0;
  /* overflow: hidden; */
  width: 100%;
  margin-bottom: 0;

  td,
  th {
    border: 2px solid ${(props) => props.theme.colors.pumice};
    border-top: 0;
    border-left: 0;
    color: ${(props) => props.theme.colors.grayDark};

    font-size: ${rem("21px")};
    letter-spacing: 0.01em;

    &:last-child {
      border-right: 0;
    }
  }

  thead {
    th,
    td {
      font-weight: ${(props) => props.theme.fontWeight_m};
      background-color: ${(props) => props.theme.colors.gray};
    }

    th {
      &:first-child {
        border-top-left-radius: 13px;
      }

      &:last-child {
        border-top-right-radius: 13px;
      }
    }

    &:last-child {
      td,
      th {
        border-bottom: 0;

        &:first-child {
          border-bottom-left-radius: 13px;
        }

        &:last-child {
          border-bottom-right-radius: 13px;
        }
      }
    }
  }

  tbody {
    th,
    td {
      background-color: ${(props) => props.theme.colors.white};
    }

    tr:last-child {
      td,
      th {
        border-bottom: 0;

        &:first-child {
          border-bottom-left-radius: 13px;
        }

        &:last-child {
          border-bottom-right-radius: 13px;
        }
      }
    }
  }

  th {
    padding: 25px 36px;
    text-align: left;
  }

  td {
    padding: 25px 36px;
  }

  .center {
    text-align: center;

    * {
      margin: auto;
    }
  }

  .condensed {
    width: 1px;
    white-space: nowrap;
  }
`;

export default Table;
