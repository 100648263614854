import React, { useState } from "react";
import styled from "styled-components";
import CloseIcon from "../views/marketing/components/CloseIcon";
import Accordion from "../views/marketing/components/Accordion";
import Input from "./Input";
import { respondTo } from "../styles/styleHelpers";

import { useAuth } from "../context/AuthContext";
import { sendCustomFontQuery } from "../utils/api";

import EULA from "./eula";
import Privacy from "./privacy";

const Modals = ({ modal, closeModal }) => {
  const { user } = useAuth();
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [hasFormSent, setHasFormSent] = useState(false);
  const [hasFormFailed, setHasFormFailed] = useState(false);
  const [accordion, setAccordion] = useState(1);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const didSendSucceed = await sendCustomFontQuery(name, company, email);

    if (didSendSucceed) {
      setHasFormSent(true);
      setHasFormFailed(false);
    } else {
      setHasFormSent(false);
      setHasFormFailed(true);
    }
  };

  return (
    <ModalContainer condensed={user ? true : false}>
      <Modal>
        <Close onClick={() => closeModal()}>
          <CloseIcon />
        </Close>

        {modal === "faqs" && (
          <ModalInner>
            <ModalTitle>You've got questions, we've got facts.</ModalTitle>

            <AccordionContainer>
              <Accordion
                onClick={() => setAccordion(1)}
                active={accordion === 1 ? true : false}
                title="How is DFC different from traditional type foundries?"
              >
                <p>
                  With traditional foundries licensing fonts can become a
                  cumbersome and costly exercise. You have to carefully manage
                  which fonts you need and how those fonts will be used, with
                  different usage coming with different costs.
                </p>
                <p>
                  With DFC we aim to make it as easy as possible. One simple,
                  low cost membership option provides you access to your font
                  collection. No need to worry about assembling a detailed
                  collection of fonts and license types.
                </p>
              </Accordion>
              <Accordion
                onClick={() => setAccordion(2)}
                active={accordion === 2 ? true : false}
                title="How is DFC different from font subscription services like Adobe Fonts?"
              >
                <p>
                  DFC fonts are made by DFC so they’ll always be in the library
                  for you to use. Other platforms like Adobe Fonts or Google
                  Fonts aggregate many of the fonts made available. This means
                  that if a foundry pulls out of the service then you no longer
                  have access to those fonts you’ve been using in your work
                  within your subscription. This is one of the biggest issues
                  we’ve faced in the past with font aggregation services that
                  inspired us to start DFC.
                </p>
              </Accordion>
              <Accordion
                onClick={() => setAccordion(3)}
                active={accordion === 3 ? true : false}
                title="How often does DFC publish fonts?"
              >
                <p>
                  A new font drops on the first of each month. And who knows, we
                  might find some extra motivation to deliver a little extra to
                  our members from time to time.
                </p>
              </Accordion>
              <Accordion
                onClick={() => setAccordion(4)}
                active={accordion === 4 ? true : false}
                title="Where else can I get the fonts offered on DFC?"
              >
                <p>
                  You cannot get these fonts anywhere else. All of the fonts
                  available through DFC are exclusive to DFC members.
                </p>
              </Accordion>
              <Accordion
                onClick={() => setAccordion(5)}
                active={accordion === 5 ? true : false}
                title="Where can I use DFC fonts?"
              >
                <p>
                  All DFC fonts are licensed for desktop and web use. That means
                  your membership allows you to use DFC fonts in design programs
                  or on your website. DFC does not currently support other types
                  of font licenses. If you have additional needs, please contact
                  us and we’ll be happy to help you. In the future we may expand
                  the licenses to include additional uses based on member
                  feedback.
                </p>
              </Accordion>
              <Accordion
                onClick={() => setAccordion(6)}
                active={accordion === 6 ? true : false}
                title="Can others who are not DFC members use fonts that I have access to?"
              >
                <p>
                  DFC wouldn’t be much of a club if so. Your membership allows
                  you to use your DFC fonts. If you have other team members,
                  clients, or anyone else who also needs to access the fonts
                  they need to join DFC themselves. We recommend you send
                  invites by email or your unique referral link to others who
                  need fonts so that you can get yourself some DFC rewards!
                </p>
              </Accordion>
              <Accordion
                onClick={() => setAccordion(7)}
                active={accordion === 7 ? true : false}
                title="How can I get my fonts onto my website?"
              >
                <p>
                  Go to your membership page, then open the projects section.
                  There you can setup web projects to add DFC fonts to your
                  websites. We’ll host your fonts as long as you have an active
                  membership with us. Reminder that you may only host fonts for
                  your own websites. If you are working on a client project,
                  refer your client to setup a DFC account so they can set up
                  their own web project and you can get some DFC Rewards!
                </p>
              </Accordion>
              <Accordion
                onClick={() => setAccordion(8)}
                active={accordion === 8 ? true : false}
                title="Do I have to pay extra to use these fonts on my website?"
              >
                <p>
                  We’ll host your fonts for web use as long as you have an
                  active DFC membership. While web use is at no cost, servers
                  aren’t free. So each membership is limited to 100,000 total
                  downloads across all web projects each month.
                </p>
              </Accordion>
              <Accordion
                onClick={() => setAccordion(9)}
                active={accordion === 9 ? true : false}
                title="Do you offer team accounts?"
              >
                <p>
                  Yes, DFC was born out of a design team so we understand the
                  importance of setting everyone up with great tools. While our
                  team features are limited with our initial beta release, the
                  most important part is ready to go—having one person paying
                  for the whole team!
                </p>
              </Accordion>
              <Accordion
                onClick={() => setAccordion(10)}
                active={accordion === 10 ? true : false}
                title="Do you include fonts from other foundries?"
              >
                <p>
                  At this time, we do not offer any fonts from any other
                  foundries. All fonts available in DFC have been created by the
                  DFC team. In the future we may offer fonts from other
                  foundries, but only if the fonts are made available to our
                  members indefinitely. We’re serious about our commitment to
                  always keeping our fonts in the library. When you’re in the
                  club, there’s no getting out. If you’re a foundry interested
                  in partnering with DFC, contact us here.
                </p>
              </Accordion>
            </AccordionContainer>
          </ModalInner>
        )}

        {modal === "eula" && (
          <ModalInner>
            <ModalTitle>END USER LICENSE AGREEMENT</ModalTitle>

            <ModalContent>
              <EULA />
            </ModalContent>
          </ModalInner>
        )}

        {modal === "privacy" && (
          <ModalInner>
            <ModalTitle>PRIVACY NOTICE</ModalTitle>

            <ModalContent>
              <Privacy />
            </ModalContent>
          </ModalInner>
        )}

        {modal === "custom" && (
          <ModalInner>
            <ModalTitle>Looking for custom fonts?</ModalTitle>

            {hasFormSent ? (
              <ModalContent>
                <p style={{ textAlign: "center" }}>
                  Your information has been received. We will reach out to you
                  soon!
                </p>
              </ModalContent>
            ) : (
              <ModalFlex>
                <ModalContent>
                  <p>
                    We love making fonts and if you’re looking for a font for
                    your brand that’s all your own, made just for you we’d love
                    to help. Simply fill this quick form and we’ll be in touch
                    soon!
                  </p>
                </ModalContent>
                <ModalForm onSubmit={handleSubmit}>
                  {hasFormFailed ? (
                    <FailMessage>
                      There was an issue submitting your request. Please try
                      again.
                    </FailMessage>
                  ) : null}
                  <label>Name</label>
                  <StyledInput
                    type="text"
                    name="name"
                    required
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />

                  <label>Company</label>
                  <StyledInput
                    type="text"
                    name="company"
                    required
                    value={company}
                    onChange={(event) => setCompany(event.target.value)}
                  />

                  <label>Email</label>
                  <StyledInput
                    type="email"
                    name="email"
                    required
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />

                  <button type="submit">Submit</button>
                </ModalForm>
              </ModalFlex>
            )}
          </ModalInner>
        )}
      </Modal>
    </ModalContainer>
  );
};

const ModalContainer = styled.div`
  position: fixed;
  right: 0;
  top: 54px;
  width: 100%;
  height: calc(100vh - 54px);
  background-color: rgba(48, 57, 64, 0.5);
  display: flex;
  align-items: center;

  ${respondTo("large")} {
    top: 0;
    height: 100vh;
    width: calc(100% - ${(props) => (props.condensed ? "325px" : "350px")});
  }

  ${respondTo("xlarge")} {
    width: calc(100% - ${(props) => (props.condensed ? "385px" : "450px")});
  }
`;

const Modal = styled.div`
  background-color: ${(props) => props.theme.colors.gray};
  border-radius: 15px;
  height: auto;
  max-height: 85vh;
  width: 85%;
  position: relative;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;

  ${respondTo("large")} {
    transform: translateY(-50%);
    width: calc(100% - 140px);
    left: 50px;
  }

  ${respondTo("xlarge")} {
    height: 100%;
  }
`;

const ModalInner = styled.div`
  padding: 90px 20px 40px 20px;
  display: flex;
  flex-direction: column;
  height: 100%;

  ${respondTo("medium")} {
    padding: 60px ${(props) => props.theme.space} 40px
      ${(props) => props.theme.space};
  }

  ${respondTo("xlarge")} {
    padding: 78px 100px 120px 100px;
  }
`;

const ModalFlex = styled.div`
  display: flex;
  flex-direction: column;

  ${respondTo("xlarge")} {
    flex-direction: row;
  }
`;

const ModalForm = styled.form`
  width: 100%;
  margin-top: 60px;

  label {
    color: ${(props) => props.theme.colors.grayDark};
    letter-spacing: 0.01em;
    font-weight: 600;
    font-size: ${(props) => props.theme.fontSize};
    margin-bottom: 8px;
    display: block;
  }

  button {
    margin: auto;
    margin-top: 49px;
    background: ${(props) => props.theme.colors.gray};
    box-shadow: ${(props) => props.theme.shadow};
    border-radius: 39px;
    padding: 14px 51px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.grayDark};
    font-size: ${(props) => props.theme.fontSize_xl};
    letter-spacing: 0.01em;
    transition: 0.3s all ease, 0.15s color ease;
    display: block;

    &:hover {
      color: white;
      background-color: ${(props) => props.theme.colors.grayDark};
      box-shadow: inset 4px 8px 21px rgba(48, 57, 64, 0.5);
    }
  }

  ${respondTo("xlarge")} {
    width: 300px;
    flex-shrink: 0;
    margin-left: 86px;
    margin-top: 0px;

    label {
      font-size: ${(props) => props.theme.fontSize_l};
      margin-bottom: 17px;
    }

    button {
      margin: 0px;
      margin-top: 49px;
    }
  }
`;

const FailMessage = styled.p`
  color: ${(props) => props.theme.colors.negative};
`;

const StyledInput = styled(Input)`
  margin-bottom: 20px;
  font-size: ${(props) => props.theme.fontSize};

  &:last-of-type {
    margin-bottom: 0px;
  }

  ${respondTo("xlarge")} {
    font-size: ${(props) => props.theme.fontSize_l};
  }
`;

const AccordionContainer = styled.div`
  overflow: auto;

  /* Scroll Bar */
  /* Track */
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-corner {
    background-color: ${(props) => props.theme.colors.gray};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border: 5px solid ${(props) => props.theme.colors.gray};
  }
`;

const ModalContent = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};
  border-radius: 10px;
  flex-grow: 1;
  letter-spacing: 0.02em;
  padding: 20px 20px 5px 20px;
  font-size: ${(props) => props.theme.fontSize};

  p,
  strong {
    display: block;
    margin-bottom: 15px;
  }

  ${respondTo("xlarge")} {
    padding: 57px 54px 34px 54px;
    font-size: ${(props) => props.theme.fontSize_l};
    line-height: 24px;

    p,
    strong {
      display: block;
      margin-bottom: 20px;
    }
  }
`;

const ModalTitle = styled.h3`
  color: ${(props) => props.theme.colors.grayDark};
  letter-spacing: 0.02em;
  font-size: ${(props) => props.theme.fontSize_l};
  line-height: 1.27;
  margin-bottom: ${(props) => props.theme.space};

  ${respondTo("medium")} {
    font-size: ${(props) => props.theme.fontSize_xl};
  }

  ${respondTo("xlarge")} {
    font-size: ${(props) => props.theme.fontSize_xxl};
    line-height: 48px;
    margin-bottom: 70px;
  }
`;

const Close = styled.div`
  cursor: pointer;
  position: absolute;
  transition: 0.3s ease-in-out;
  top: 10px;
  right: 10px;

  path,
  circle {
    transition: 0.3s ease-in-out;
  }

  &:hover {
    path,
    circle {
      stroke: ${(props) => props.theme.colors.black};
    }
  }

  ${respondTo("xlarge")} {
    top: 20px;
    right: 20px;
  }
`;

export default Modals;
