import styled from "styled-components";

const Link = styled.a`
  font-weight: 500;
  font-size: ${(props) => props.theme.fontSize_l};
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: ${(props) => props.theme.colors.grayDark};
  display: block;
  transition: 0.3s ease-in-out;

  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.blackLight};
  }
`;

export default Link;
