import styled from "styled-components";

const RemoveButton = styled.button`
  border-radius: 50%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.gray};
  transition: 0.2s ease-in-out;
  height: 36px;
  width: 36px;

  &::before {
    content: "";
    display: block;
    background-color: ${(props) => props.theme.colors.grayDark};
    transition: 0.3s ease-in-out;
    height: 2px;
    width: 16px;
  }

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.red};

    &::before {
      background-color: ${(props) => props.theme.colors.white};
    }
  }
`;

export default RemoveButton;
