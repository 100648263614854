import React from "react";
import styled from "styled-components";

const TextAlign = ({ id, name, checked, onChange, value, direction }) => {
  return (
    <>
      <Input
        type="radio"
        id={id || `align-${direction}`}
        name={name || `align`}
        checked={checked}
        onChange={onChange ? onChange : undefined}
        value={value || direction}
        aria-label={direction}
      />
      <Label htmlFor={id || `align-${direction}`} direction={direction}>
        <Bar />
        <Bar />
        <Bar />
      </Label>
    </>
  );
};

const Bar = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${(props) => props.theme.colors.grayDark};
  margin-bottom: 7px;
  transition: background-color 200ms ease;

  &:last-child {
    width: 72.75%;
    margin-bottom: 0;
  }
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  width: 44px;
  cursor: pointer;
  transform: scale(0.8);

  align-items: ${(props) =>
    props.direction === "center"
      ? "center"
      : props.direction === "right"
      ? "flex-end"
      : "flex-start"};

  &:hover {
    ${Bar} {
      background-color: ${(props) => props.theme.colors.white};
    }
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;

  &:checked {
    + ${Label} ${Bar} {
      background-color: ${(props) => props.theme.colors.white};
    }
  }
`;

export default TextAlign;
