import React from "react";
import styled from "styled-components";

import LogoImage from "../../../components/icons/LogoImage";

import { respondTo } from "../../../styles/styleHelpers";

const Mosaic = ({ slug }) => {
  const hideImage = (ev) => {
    ev.target.remove();
  };

  return (
    // Each font will share same array of images

    <Container>
      <Half>
        <ImageContainer>
          <Placeholder>
            <LogoImage />
          </Placeholder>
          <Image
            src={`${process.env.REACT_APP_API_URL}/assets/fonts/${slug}/mosaic-1.png`}
            onError={hideImage}
          />
        </ImageContainer>
      </Half>
      <Half>
        <ImageContainer>
          <Placeholder>
            <LogoImage />
          </Placeholder>
          <Image
            src={`${process.env.REACT_APP_API_URL}/assets/fonts/${slug}/mosaic-2.png`}
            onError={hideImage}
          />
        </ImageContainer>
      </Half>
      <Full>
        <ImageContainer>
          <Placeholder>
            <LogoImage />
          </Placeholder>
          <Image
            src={`${process.env.REACT_APP_API_URL}/assets/fonts/${slug}/mosaic-3.png`}
            onError={hideImage}
          />
        </ImageContainer>
      </Full>
      <Half>
        <ImageContainer>
          <Placeholder>
            <LogoImage />
          </Placeholder>
          <Image
            src={`${process.env.REACT_APP_API_URL}/assets/fonts/${slug}/mosaic-4.png`}
            onError={hideImage}
          />
        </ImageContainer>
      </Half>
      <Half>
        <ImageContainer>
          <Placeholder>
            <LogoImage />
          </Placeholder>
          <Image
            src={`${process.env.REACT_APP_API_URL}/assets/fonts/${slug}/mosaic-5.png`}
            onError={hideImage}
          />
        </ImageContainer>
      </Half>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${respondTo("medium")} {
    margin-right: -${(props) => props.theme.space};
    margin-bottom: -${(props) => props.theme.space};
  }
`;

const GridBlock = styled.div`
  display: flex;
  flex-basis: 100%;
  max-width: 100%;
  padding-bottom: ${(props) => props.theme.space};

  &:last-child {
    padding-bottom: 0;
  }

  ${respondTo("medium")} {
    padding-right: ${(props) => props.theme.space};

    &:last-child {
      padding-bottom: ${(props) => props.theme.space};
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;
  padding-bottom: 100%;
  flex-grow: 1;
  overflow: hidden;
  border-radius: ${(props) => props.theme.borderRadius};
  background-color: ${(props) => props.theme.colors.whiteDark};
`;

const Half = styled(GridBlock)`
  ${ImageContainer} {
    padding-bottom: 91%;
  }
  ${respondTo("medium")} {
    flex-basis: 50%;
    max-width: 50%;
  }
`;

const Full = styled(GridBlock)`
  ${ImageContainer} {
    padding-bottom: 44.6%;
  }
`;

// const TwoThird = styled(GridBlock)`
//   ${respondTo("medium")} {
//     flex-basis: 65%;
//     max-width: 65%;

//     ${ImageContainer} {
//       padding-bottom: 53.3%;
//     }
//   }
// `;

// const OneThird = styled(GridBlock)`
//   ${respondTo("medium")} {
//     flex-basis: 35%;
//     max-width: 35%;

//     ${ImageContainer} {
//       padding-bottom: 99.393%;
//     }
//   }
// `;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Placeholder = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default Mosaic;
