import React from "react";
import styled from "styled-components";

// import Login from "./Login";
import LogoImage from "./icons/LogoImage";
import LogoText from "./icons/LogoText";
import { useAuth } from "../context/AuthContext";

import AuthSidebar from "../views/authSidebar/";
import UserSidebar from "../views/userSidebar/";
import { respondTo } from "../styles/styleHelpers";

const Sidebar = () => {
  const {
    user,
    authenticating,
    params,
    isSidebarOpen,
    setIsSidebarOpen,
    toggleSidebarLogin,
    toggleSidebarSignup,
  } = useAuth();

  return (
    <>
      {user ? (
        <ToggleSidebar
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          aria-label="Toggle Sidebar"
        >
          <ToggleIcon />
          <Logo>
            <LogoImageContainer>
              <LogoImage />
            </LogoImageContainer>
            <LogoTextContainer>
              <LogoText />
            </LogoTextContainer>
          </Logo>
        </ToggleSidebar>
      ) : (
        <HeaderBar>
          <HeaderButton
            onClick={toggleSidebarSignup}
            aria-label="Open Signup Form"
          >
            Join
          </HeaderButton>
          <Logo>
            <LogoImageContainer>
              <LogoImage />
            </LogoImageContainer>
            <LogoTextContainer>
              <LogoText />
            </LogoTextContainer>
          </Logo>
          <HeaderButton
            onClick={toggleSidebarLogin}
            aria-label="Open Login Form"
          >
            Login
          </HeaderButton>
        </HeaderBar>
      )}
      <Container active={isSidebarOpen} condensed={user ? true : false}>
        {/* after initial authentication takes place, display either the login/signup component or the
        user sidebar with the drawer */}
        {!authenticating ? (
          user ? (
            <UserSidebar
              closeSidebar={() => {
                setIsSidebarOpen(false);
              }}
            />
          ) : (
            <AuthSidebar
              closeSidebar={() => {
                setIsSidebarOpen(false);
              }}
              inviteToken={params.invite || undefined}
              referrerToken={params.referrer || undefined}
              teamToken={params.team || undefined}
              resetToken={params.reset || undefined}
            />
          )
        ) : null}
      </Container>
    </>
  );
};

const Container = styled.div`
  position: fixed;
  top: ${(props) => `${props.theme.toggleHeight}px`};
  left: 0;
  bottom: 0;

  flex-shrink: 0;
  flex-basis: ${(props) => (props.condensed ? "325px" : "350px")};

  width: 100%;

  padding: ${(props) => props.theme.space};
  padding-top: 0;
  background-color: ${(props) => props.theme.colors.white};

  transform: ${(props) =>
    props.active ? "translateX(0%)" : "translateX(-100%)"};

  overflow: auto;
  z-index: ${(props) => props.theme.indexes.sidebar};
  transition: transform 300ms ease-in-out;

  ${respondTo("small")} {
    max-width: 380px;
  }

  ${respondTo("large")} {
    position: static;
    z-index: 1;
    padding-top: 0;
    max-width: 100%;
    transform: none;
    transition: none;
  }

  ${respondTo("xlarge")} {
    padding-left: ${(props) => (props.condensed ? "90px" : "90px")};

    flex-basis: ${(props) => (props.condensed ? "385px" : "450px")};
  }
`;

const ToggleSidebar = styled.button`
  position: fixed;
  top: 0;
  width: 100%;
  background-color: ${(props) => props.theme.colors.gray};
  z-index: ${(props) => props.theme.indexes.sidebarToggle};
  cursor: pointer;
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  padding-right: 56px;

  ${respondTo("large")} {
    display: none;
  }
`;

const ToggleIcon = styled.div`
  display: flex;
  height: 2px;
  width: 20px;
  background-color: ${(props) => props.theme.colors.pumiceDark};
  position: relative;
  margin: 0 18px;
  border-radius: 2px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    height: 2px;
    width: 20px;
    background-color: ${(props) => props.theme.colors.pumiceDark};
    border-radius: 2px;
  }

  &::before {
    transform: translateY(-315%);
  }

  &::after {
    transform: translateY(315%);
  }
`;

const HeaderBar = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  background-color: ${(props) => props.theme.colors.gray};
  z-index: ${(props) => props.theme.indexes.sidebarToggle};
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  ${respondTo("large")} {
    display: none;
  }
`;

const HeaderButton = styled.button`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 18px;
  color: ${(props) => props.theme.colors.grayDark};
  cursor: pointer;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  margin: 15px auto;
  padding-right: 8px;
`;

const LogoImageContainer = styled.div`
  background-color: ${(props) => props.theme.colors.gray};
  box-shadow: ${(props) => props.theme.shadow};
  border-radius: 5px;
  height: 24px;
  width: 24px;
  padding: 5px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoTextContainer = styled.div`
  height: 13px;
  display: flex;

  svg {
    height: 100%;
    width: auto;
  }
`;

export default Sidebar;
