import React from "react";
import styled from "styled-components";
import { respondTo } from "../../../styles/styleHelpers";

const SignupSection = ({ openSignup }) => {
  return (
    <SignupContainer>
      <Title>Sign up</Title>

      <Collections>
        {/* // ? hiding the starter member option */}
        {/* <Collection>
          <div>
            <h3 className="collection__title">Starter Member</h3>
            <p className="collection__body">
              Collect a new font every month. Billed annually.
            </p>
          </div>

          <div className="collection__footer">
            <p className="collection__price">
              $12/yr
              <span className="collection__billing">Billed annually.</span>
            </p>
            <button
              onClick={openSignup}
              className="collection__button"
              id="starter-member-btn"
            >
              Get Started
            </button>
          </div>
        </Collection> */}

        <Collection>
          <div>
            <h3 className="collection__title">Single Member</h3>
            <p className="collection__body">
              Get the entire font library, always. Pay for team members too.
            </p>
          </div>

          <div className="collection__footer">
            <p className="collection__price">
              ${process.env.REACT_APP_PREMIUM_PRICE}/mo
              <span className="collection__billing">Billed monthly.</span>
            </p>
            <button
              onClick={openSignup}
              className="collection__button"
              id="unlimited-member-btn"
            >
              {/* Id is for GTM */}
              Get Started
            </button>
          </div>
        </Collection>

        <Collection>
          <div>
            <h3 className="collection__title">Team Accounts</h3>
            <p className="collection__body">
              Improved team accounts coming soon!
            </p>
          </div>
        </Collection>
      </Collections>
    </SignupContainer>
  );
};

const SignupContainer = styled.div`
  color: ${(props) => props.theme.colors.blackLight};
  width: 85%;
  margin: auto;
  margin-bottom: 91px;
  max-width: 2000px;

  ${respondTo("medium")} {
    margin-bottom: 62px;
  }

  ${respondTo("large")} {
    width: 100%;
    margin-left: 0;
  }

  ${respondTo("xlarge")} {
    margin-bottom: 210px;
  }
`;

const Title = styled.h2`
  font-weight: bold;
  color: ${(props) => props.theme.colors.grayDark};
  font-size: ${(props) => props.theme.fontSize_l};
  margin-bottom: 19px;

  ${respondTo("medium")} {
    font-size: ${(props) => props.theme.fontSize_xl};
    margin-bottom: ${(props) => props.theme.space};
  }

  ${respondTo("xlarge")} {
    font-size: ${(props) => props.theme.fontSize_xxl};
    margin-bottom: 40px;
  }
`;

const Collections = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${respondTo("medium")} {
    flex-direction: row-reverse;
  }

  ${respondTo("large")} {
    margin-right: 7%;
  }
`;

const Collection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 15px;
  border: 2px solid ${(props) => props.theme.colors.pumice};
  color: ${(props) => props.theme.colors.blackLight};
  padding: 23px 31px;

  &:first-of-type {
    margin-bottom: 16px;
  }

  .collection__title {
    border-bottom: 3px solid ${(props) => props.theme.colors.blackLight};
    letter-spacing: 0.02em;
    font-size: ${(props) => props.theme.fontSize_l};
    line-height: 23px;
    padding-bottom: 15px;
    margin-bottom: 21px;
  }

  .collection__body {
    font-weight: 600;
    letter-spacing: 0.02em;
    font-size: ${(props) => props.theme.fontSize_xs};
    line-height: 15px;
    margin-bottom: 26px;
  }

  .collection__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .collection__price {
    font-weight: bold;
    letter-spacing: 0.02em;
    margin-bottom: 0px;
    font-size: ${(props) => props.theme.fontSize_xl};
  }

  .collection__billing {
    font-size: ${(props) => props.theme.fontSize_xs};
    letter-spacing: 0.02em;
    margin-bottom: 0px;
    display: block;
    font-weight: 600;
  }

  .collection__button {
    background-color: ${(props) => props.theme.colors.green};
    color: ${(props) => props.theme.colors.white};
    transition: 0.3s ease-in-out;
    font-weight: bold;
    letter-spacing: 0.02em;
    border-radius: 4px;
    font-size: ${(props) => props.theme.fontSize_xs};
    padding: 10px 26px;

    &:hover,
    &:focus {
      background-color: rgba(133, 193, 123, 0.8);
      transform: scale(0.95);
    }
  }

  ${respondTo("medium")} {
    width: calc(50% - 6px);

    &:first-of-type {
      margin-bottom: 0px;
    }
  }

  ${respondTo("large")} {
  }

  ${respondTo("xlarge")} {
    border: 3px solid ${(props) => props.theme.colors.pumice};
    padding: 51px 62px;
    width: calc(50% - 7px);

    .collection__title {
      font-size: 40px;
      line-height: 51px;
      padding-bottom: 24px;
      margin-bottom: 38px;
    }

    .collection__body {
      font-size: ${(props) => props.theme.fontSize_l};
      line-height: 23px;
      margin-bottom: 67px;
    }

    .collection__price {
      font-size: 30px;
    }

    .collection__billing {
      font-size: ${(props) => props.theme.fontSize_l};
    }

    .collection__button {
      font-size: ${(props) => props.theme.fontSize_s};
      padding: 14px 53px;
      border-radius: 8px;
    }
  }

  ${respondTo("xlarge")} {
    padding: 51px 90px;
  }
`;

export default SignupSection;
