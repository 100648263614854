import React from "react";
import styled from "styled-components";
import { rem } from "polished";

const PlanRadio = ({ title, price, description, id = "radio", ...rest }) => (
  <Container>
    <Input type="radio" id={id} {...rest} />
    <Label htmlFor={id}>
      {title ? <LabelTitle>{title}</LabelTitle> : null}
      {price ? <LabelPrice>{price}</LabelPrice> : null}
      {description ? <LabelDescription>{description}</LabelDescription> : null}
    </Label>
  </Container>
);

const Container = styled.div`
  display: inline-flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0%;

  margin-bottom: ${(props) => props.theme.space};

  &:last-child {
    margin-bottom: 0;
  }
`;

const LabelTitle = styled.div`
  color: ${(props) => props.theme.colors.grayDark};
  font-size: ${(props) => props.theme.fontSize_l};
  font-weight: ${(props) => props.theme.fontWeight_m};
  line-height: 1.27;
  letter-spacing: 0.01em;
  transition: color 200ms ease;
`;

const LabelPrice = styled.div`
  color: ${(props) => props.theme.colors.grayDark};
  font-size: ${rem("30px")};
  font-weight: ${(props) => props.theme.fontWeight_m};
  line-height: 1.3;
  transition: color 200ms ease;
`;

const LabelDescription = styled.div`
  color: ${(props) => props.theme.colors.grayDark};
  font-size: ${(props) => props.theme.fontSize_xs};
  /* font-weight: ${(props) => props.theme.fontWeight_m}; */
  line-height: 1.25;
`;

const Label = styled.label`
  padding: 20px;
  border-radius: 9px;

  background-color: ${(props) => props.theme.colors.white};
  border: 3px solid transparent;

  user-select: none;
  cursor: pointer;
  transition: background-color 200ms ease, border-color 200ms ease;

  &:hover {
    background-color: #fff;

    ${LabelTitle}, ${LabelPrice} {
      color: ${(props) => props.theme.colors.blackLight};
    }
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;

  &:checked {
    + ${Label} {
      background-color: #fff;
      border-color: ${(props) => props.theme.colors.green};

      ${LabelTitle}, ${LabelPrice} {
        color: ${(props) => props.theme.colors.blackLight};
      }
    }
  }
`;

export default PlanRadio;
