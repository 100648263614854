import React, { useState } from "react";
import styled from "styled-components";
import { PoseGroup } from "react-pose";

import FormWrapper from "./FormWrapper";
import FormButton from "./FormButton";
import FormAction, { FormActionContainer } from "./FormAction";
import Link from "./Link";
import Input, { InputField } from "../../../components/Input";
import Label from "../../../components/Label";
import FormMessage from "../../../components/FormMessage";
import Spinner from "../../../components/Spinner";

const Login = ({ signUp, handleLogin, hasLoginError, forgot, isLoading }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <>
      <FormWrapper>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin(email, password);
          }}
        >
          <InputField>
            <Label htmlFor="email">Email</Label>
            <Input
              className="no-track"
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value.toLowerCase());
              }}
              required
            />
          </InputField>

          <InputField>
            <Label htmlFor="password">Password</Label>
            <Input
              className="no-track"
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              required
            />
          </InputField>

          <ActionContainer>
            <FormAction type="submit" disabled={isLoading}>
              {isLoading ? <Spinner /> : "Log in"}
            </FormAction>
            <PoseGroup>
              {hasLoginError ? (
                <FormMessage key="error">Incorrect login</FormMessage>
              ) : null}
            </PoseGroup>
          </ActionContainer>

          <Link as="button" onClick={forgot}>
            Forgot password?
          </Link>
        </form>
      </FormWrapper>
      <FormButton onClick={signUp}>Sign up</FormButton>
    </>
  );
};

const ActionContainer = styled(FormActionContainer)`
  margin-bottom: 40px;
`;

export default Login;
