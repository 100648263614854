import React from "react";

const Settings = (props) => (
  <svg viewBox="0 0 7 29" fill="#C9D4CD" xmlns="http://www.w3.org/2000/svg">
    <circle cx="3.5" cy="3.5" r="3.5" />
    <circle cx="3.5" cy="14.5" r="3.5" />
    <circle cx="3.5" cy="25.5" r="3.5" />
  </svg>
);

export default Settings;
