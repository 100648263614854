import React, { useState } from "react";
import styled from "styled-components";
import Swiper from "react-id-swiper";
import "../../../vendor/swiper/swiper.css";
import { respondTo } from "../../../styles/styleHelpers";

const IntroSlider = ({ images }) => {
  const [swiper, setSwiper] = useState(null);

  const goNext = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  const params = {
    effect: "fade",
    speed: 1500,
    draggable: false,
    loop: true,
    allowTouchMove: false,
    autoplay: {
      delay: 5000,
    },
    navigation: {
      nextEl: ".font-slider__prev",
      prevEl: ".font-slider__next",
    },
  };

  return (
    <IntroSliderContainer>
      <Swiper getSwiper={setSwiper} {...params}>
        {images.map((image, index) => (
          <Slide key={index}>
            <SlideImage src={image} alt=""></SlideImage>
          </Slide>
        ))}
      </Swiper>
      {images.length > 1 ? (
        <SwiperNavigation>
          <button
            className="font-slider__button font-slider__prev"
            onClick={goPrev}
          ></button>
          <button
            className="font-slider__button font-slider__next"
            onClick={goNext}
          ></button>
        </SwiperNavigation>
      ) : null}
    </IntroSliderContainer>
  );
};

const IntroSliderContainer = styled.div`
  width: 100%;

  ${respondTo("medium")} {
    width: 209px;
  }

  ${respondTo("large")} {
    margin-right: 7%;
    width: 30%;
  }

  ${respondTo("xlarge")} {
    width: 412px;
  }
`;

const SwiperNavigation = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;

  .font-slider__button {
    position: relative;
    margin-top: 0;
    background-image: none;
    width: 39px;
    height: 39px;
    display: inline-block;
    background-color: ${(props) => props.theme.colors.yellow};
    border-radius: 50%;
    transition: 0.3s ease-in-out;

    &::before {
      position: absolute;
      content: "";
      display: block;
      width: 0px;
      height: 0px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      top: 10px;
      transition: 0.3s ease-in-out;
    }

    &:hover {
      background-color: white;
      box-shadow: ${(props) => props.theme.shadow};
    }
  }

  .font-slider__prev {
    margin-right: 8px;

    &::before {
      border-right: 10px solid white;
      left: 13px;
    }

    &:hover {
      &::before {
        border-right: 10px solid ${(props) => props.theme.colors.yellow};
      }
    }
  }

  .font-slider__next {
    &::before {
      border-left: 10px solid white;
      left: 16px;
    }

    &:hover {
      &::before {
        border-left: 10px solid ${(props) => props.theme.colors.yellow};
      }
    }
  }

  ${respondTo("medium")} {
    justify-content: flex-start;
    margin-top: 14px;
    transform: scale(0.7);
    transform-origin: left;
  }

  ${respondTo("xlarge")} {
    margin-top: ${(props) => props.theme.space};
    transform: scale(1);
  }
`;

const Slide = styled.div`
  position: relative;
  width: 100%;
  height: 0px;
  padding-bottom: 69%;
  overflow: hidden;
  border-radius: 15px;

  ${respondTo("medium")} {
    padding-bottom: 74%;
  }

  ${respondTo("large")} {
    padding-bottom: 75%;
  }

  ${respondTo("xlarge")} {
    padding-bottom: 0px;
    height: 315px;
  }
`;

const SlideImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default IntroSlider;
