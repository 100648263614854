import React from "react";
import styled, { css } from "styled-components";
import { rem, rgba } from "polished";

import { useAuth } from "../../../context/AuthContext";

import Check from "../../../components/icons/Check";
import FormMessage from "../../../components/FormMessage";
import Spinner from "../../../components/Spinner";

import { respondTo } from "../../../styles/styleHelpers";

const Collections = ({
  error,
  loading,
  openUpgrade,
  openDowngrade,
  onTeamClick,
}) => {
  const { user, isPremium } = useAuth();

  return (
    <Container>
      <CollectionsContainer>
        {/* // ? hiding the starter member option */}
        {/* <Collection
          onClick={openDowngrade}
          active={!isPremium}
          aria-label="Change to Starter Member"
          disabled={loading || !isPremium || !user.isActive}
        >
          {user.isActive ? (
            <CheckContainer>
              <Check />
            </CheckContainer>
          ) : null}

          <CollectionInner>
            <Title>Starter Member</Title>

            <Description>
              Add a new font to your collection each month.
              <br />
              Billed annually.
            </Description>

            <Price>$1/mo</Price>
          </CollectionInner>
        </Collection> */}

        <Collection
          //onClick={() => handleSubscription("PREMIUM")}
          onClick={openUpgrade}
          active={isPremium}
          aria-label="Change to Unlimited Member"
          disabled={loading || isPremium || !user.isActive}
        >
          {user.isActive ? (
            <CheckContainer>
              <Check />
            </CheckContainer>
          ) : null}

          <CollectionInner>
            <Title>Unlimited Member</Title>

            <Description>
              Get immediate access to the entire font library.
              <br />
              Billed monthly.
            </Description>
            <Price>${process.env.REACT_APP_PREMIUM_PRICE}/mo per account</Price>
          </CollectionInner>
        </Collection>
      </CollectionsContainer>

      {error ? <FormMessage>{error}</FormMessage> : null}

      {loading ? <Spinner color="green" /> : null}

      {user.isActive ? (
        <Button active={isPremium} onClick={onTeamClick || undefined}>
          {isPremium ? "Add Team Members" : "Get Unlimited for Team Billing"}
        </Button>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 55px;

  ${respondTo("xlarge")} {
    margin-top: 88px;
  }
`;

const CollectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.space};

  ${respondTo("xlarge")} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const CheckContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;

  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 200ms ease, visibility 200ms ease;
`;

const Collection = styled.button`
  border-radius: 15px;
  padding: ${(props) => props.theme.space};
  position: relative;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  background-color: ${(props) =>
    props.active ? props.theme.colors.pumice : props.theme.colors.white};

  border: 3px solid
    ${(props) =>
      props.active ? props.theme.colors.blackLight : props.theme.colors.gray};

  color: ${(props) =>
    props.active ? props.theme.colors.blackLight : props.theme.colors.grayDark};

  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover,
  &:focus {
    &:not(:disabled) {
      border: 3px solid ${(props) => props.theme.colors.blackLight};
    }
  }

  ${respondTo("xlarge")} {
    width: calc(50% - 15px);
    padding: 50px 113px;
    margin-bottom: 0;
  }

  h3 {
    border-bottom: 3px solid
      ${(props) =>
        props.active ? props.theme.colors.blackLight : props.theme.colors.gray};
  }

  /* display the check when active */
  ${(props) =>
    props.active
      ? css`
          ${CheckContainer} {
            opacity: 1;
            visibility: visible;
          }
        `
      : ``}
`;

const CollectionInner = styled.div`
  max-width: 423px;
  margin: auto;
  text-align: center;
`;

const Title = styled.h3`
  font-size: ${(props) => props.theme.fontSize_xxl};
  padding-bottom: 5px;
  line-height: 1.3;

  transition: 0.3s border ease-in-out;

  ${respondTo("xlarge")} {
    font-size: ${rem("40px")};
  }
`;

const Description = styled.p`
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSize_l};
  line-height: 23px;
  margin-bottom: 14px;
`;

const Price = styled.p`
  font-weight: 600;
  font-size: ${rem("30px")};
  line-height: 39px;
  margin-bottom: 0px;
`;

const Button = styled.button`
  background-color: ${(props) =>
    props.active ? props.theme.colors.green : props.theme.colors.pumice};
  color: ${(props) => props.theme.colors.white};
  pointer-events: ${(props) => (props.active ? "all" : "none")};
  padding: 17px 64px;
  font-weight: bold;
  font-size: ${rem("21px")};
  border-radius: 29px;
  transition: 0.3s ease-in-out;
  margin-left: auto;
  margin-right: 0px;
  display: block;

  &:hover,
  &:focus {
    background-color: ${(props) => rgba(props.theme.colors.green, 0.8)};
    transform: scale(0.95);
  }
`;

export default Collections;
