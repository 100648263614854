import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { rem } from "polished";

import { respondTo } from "../../../styles/styleHelpers";

import DownloadMac from "../../../images/download-mac.png";
import DownloadWindows from "../../../images/download-windows.png";

const User = ({ user, handleEdit }) => {
  const [name, setName] = useState("");
  const [edit, setEdit] = useState(false);
  const nameInput = useRef(null);

  useEffect(() => {
    if (user) {
      setName(`${user.name}`);
    }
  }, [user]);

  const toggleEdit = () => {
    // if is currently editing
    if (edit) {
      // perform the edit
      handleEdit(name);

      setEdit(false);
    } else {
      setEdit(true);

      // put focus on the input field
      setTimeout(() => {
        nameInput.current.focus();
      }, 10);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    handleEdit(name);

    setEdit(false);
  };

  return (
    <UserContainer>
      <UserInfo>
        <form onSubmit={handleSubmit}>
          <Name
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            active={edit}
            disabled={!edit}
            ref={nameInput}
          />
        </form>
        {user.isActive ? (
          <UserMessage>
            Member since {new Date(user.createdAt).getFullYear()}
          </UserMessage>
        ) : (
          <UserMessage error>Account Inactive</UserMessage>
        )}
      </UserInfo>

      <Edit onClick={toggleEdit}>{edit ? `Save` : `Edit`}</Edit>
      <DownloadLinks>
        <a
          href={process.env.REACT_APP_API_URL + "/download/mac"}
          target="_blank"
          rel="noopener noreferrer"
          className="download-link"
        >
          <img src={DownloadMac} alt="" />
        </a>
        <a
          href={process.env.REACT_APP_API_URL + "/download/win"}
          target="_blank"
          rel="noopener noreferrer"
          className="download-link"
        >
          <img src={DownloadWindows} alt="" />
        </a>
      </DownloadLinks>
    </UserContainer>
  );
};

const UserContainer = styled.div`
  color: ${(props) => props.theme.colors.grayDark};
  display: flex;
  align-items: flex-start;
`;

const UserInfo = styled.div`
  margin-right: 45px;
`;

const Name = styled.input`
  font-weight: bold;
  font-size: ${rem("30px")};
  line-height: 39px;
  margin-bottom: 0px;
  background-color: ${(props) => (props.active ? "white" : "transparent")};
`;

const Edit = styled.button`
  font-size: ${rem("21px")};
  color: ${(props) => props.theme.colors.grayDark};
  margin-bottom: 0px;
  transition: color 200ms ease;
  cursor: pointer;
  letter-spacing: 0.01em;
  text-decoration-line: underline;

  /* so this doesn't trigger the accordion to close */
  z-index: 2;

  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.blackLight};
  }
`;

const UserMessage = styled.p`
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSize_l};
  margin-bottom: 0px;
  color: ${(props) =>
    props.error ? props.theme.colors.negative : props.theme.colors.primary};
`;

const DownloadLinks = styled.div`
  display: none;

  ${respondTo("large")} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: scale(0.7);
    align-items: center;
    padding: 0 10px;
    margin-top: 0;
    margin-left: auto;
    transform-origin: right top;

    .download-link {
      img {
        display: block;
      }
    }
  }

  ${respondTo("xlarge")} {
    flex-direction: row;
    transform: scale(0.6);
    transform-origin: right center;

    .download-link:first-of-type {
      margin-right: ${(props) => props.theme.space};
    }
  }
`;

export default User;
