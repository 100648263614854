// import { rgba } from "polished";

// Colors
const colors = {
  white: "#FAFBF9",
  whiteDark: "#F4F5F3",

  gray: "#E3E4E0",
  grayDark: "#838B91",

  pumice: "#C9D4CD",
  pumiceDark: "#B3BDB7",

  blackLight: "#465A68",
  black: "#303940",

  green: "#85C17B",
  yellow: "#DFBC57",
  red: "#FF641A",
};

colors.primary = colors.grayDark;
colors.text = colors.black;
colors.positive = colors.green;
colors.warning = colors.yellow;
colors.negative = colors.red;

export { colors };

export const typography = {
  fontSize_xxxl: "48px",
  fontSize_xxl: "38px",
  fontSize_xl: "22px",
  fontSize_l: "18px",
  fontSize: "16px",
  fontSize_s: "14px",
  fontSize_xs: "12px",
  fontSize_xxs: "10px",

  fontWeight_m: 600,

  fontFamily: `"din-2014", -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif`,
};

// Borders
export const borders = {
  borderRadius: "15px",
  shadow: "-5px -10px 30px #fafbf9, 5px 10px 30px rgba(48, 57, 64, 0.2)",
};

// Easings
export const easings = {
  default: "ease-in-out",
};

// Indexes
export const indexes = {
  modal: 30,
  sidebarToggle: 20,
  sidebar: 10,
};

// Breakpoints
export const breakpoints = {
  xxlarge: "1920",
  xlarge: "1600",
  large: "1024",
  medium: "760",
  small: "480",
};

// Misc. Variables
export const toggleHeight = 54;
export const space = "30px";
export const space_s = "25px";

// The Whole Theme
const theme = {
  colors,
  easings,
  indexes,
  toggleHeight,
  space,
  space_s,
  ...borders,
  ...typography,
};

export default theme;
