import React, { useState, useEffect } from "react";
import posed from "react-pose";
import styled from "styled-components";
import { rem } from "polished";

import { respondTo } from "../../../styles/styleHelpers";

const Accordion = ({
  title,
  children,
  handleEdit,
  isEditable = false,
  id = "",
  isOpen = false,
  handleActive,
}) => {
  const [isActive, setIsActive] = useState(isOpen);
  const [name, setName] = useState(title);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setIsActive(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (handleActive) {
      handleActive(isActive);
    }
  }, [isActive]); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleEdit = () => {
    // if is currently editing
    if (isEditing) {
      // perform the edit
      handleEdit(name);

      setIsEditing(false);
    } else {
      setIsEditing(true);

      // put focus on the input field
      setTimeout(() => {
        const thisInput = document.getElementById(`${id}accordion`);
        thisInput.focus();
      }, 100);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    handleEdit(name);

    setIsEditing(false);
  };

  return (
    <AccordionContainer id={id || undefined}>
      <AccordionHeader>
        {isEditable && isActive ? (
          <AccordionInputContainer onSubmit={handleSubmit}>
            <AccordionInput
              type="text"
              id={`${id}accordion`}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              active={isEditing}
              disabled={!isEditing}
            />

            <Edit type="button" onClick={toggleEdit}>
              {isEditing ? "save" : "edit"}
            </Edit>
          </AccordionInputContainer>
        ) : (
          <AccordionTitle>{title}</AccordionTitle>
        )}
        <AccordionTrigger
          aria-label="Toggle accordion content"
          onClick={() => {
            setIsActive(!isActive);
          }}
        />
      </AccordionHeader>

      <AccordionBody pose={isActive ? "open" : "closed"}>
        <AccordionBodyInner>{children}</AccordionBodyInner>
      </AccordionBody>
    </AccordionContainer>
  );
};

const AccordionContainer = styled.div`
  display: block;
  width: 100%;
  transition: 0.3s ease-in-out;
  background-color: ${(props) => props.theme.colors.whiteDark};
  border-radius: 15px;
  margin-top: ${(props) => props.theme.space};
  /*overflow: hidden;*/
`;

const AccordionHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 35px ${(props) => props.theme.space};
  width: 100%;
  cursor: pointer;

  ${respondTo("medium")} {
    padding-left: 44px;
    padding-right: 44px;
  }
  ${respondTo("large")} {
    padding-left: 70px;
  }
`;

const AccordionTitle = styled.h2`
  color: ${(props) => props.theme.colors.grayDark};
  font-weight: bold;
  font-size: ${rem("30px")};
  margin-bottom: 0px;
  line-height: 1.3;
`;

const AccordionTrigger = styled.button`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.pumice};
  cursor: pointer;
  height: 36px;
  width: 36px;
  transition: 0.2s ease-in-out;

  &::before {
    content: "";
    display: block;
    border: 2px solid ${(props) => props.theme.colors.blackLight};
    border-top: 0;
    border-right: 0;
    transform: translateY(-10%) rotate(-45deg);
    width: 10px;
    height: 10px;
    margin-top: -2px;
    transition: 0.3s ease-in-out;
  }

  /* clickable area to cover the whole heading */
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.pumiceDark};

    &::before {
      border-color: ${(props) => props.theme.colors.white};
    }
  }
`;

const AccordionBody = posed.div({
  open: {
    height: "auto",
    transition: {
      duration: 400,
      ease: "easeInOut",
    },
    applyAtStart: { visibility: "visible" },
    applyAtEnd: { overflow: "visible" },
  },
  closed: {
    height: "0px",
    transition: {
      duration: 400,
      ease: "easeInOut",
    },
    applyAtStart: { overflow: "hidden" },
    applyAtEnd: { visibility: "hidden" },
  },
});

const AccordionBodyInner = styled.div`
  padding: 35px ${(props) => props.theme.space};
  padding-top: 0;

  ${respondTo("medium")} {
    padding-left: 44px;
    padding-right: 44px;
    padding-bottom: 60px;
  }
  ${respondTo("large")} {
    padding-left: 70px;
  }
`;

const AccordionInputContainer = styled.form`
  display: flex;
  align-items: baseline;
  max-width: 85%;
`;

const AccordionInput = styled.input`
  color: ${(props) => props.theme.colors.grayDark};
  font-weight: bold;
  font-size: ${rem("30px")};
  line-height: 1.3;
  margin-right: 25px;
  background-color: ${(props) =>
    props.active ? props.theme.colors.white : "transparent"};
  padding: 0;
  border: 0;
  border-radius: 0;
  max-width: 70%;

  /* so this doesn't trigger the accordion to close */
  z-index: 2;
`;

const Edit = styled.button`
  font-size: ${rem("24px")};
  color: ${(props) => props.theme.colors.grayDark};
  margin-bottom: 0px;
  transition: color 200ms ease;
  cursor: pointer;

  /* so this doesn't trigger the accordion to close */
  z-index: 2;

  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.blackLight};
  }
`;

export default Accordion;
