import React, { useState } from "react";
import posed from "react-pose";
import styled from "styled-components";
import { rem } from "polished";

import SlideToggle, {
  SlideToggleContainer,
} from "../../../components/SlideToggle";

import { respondTo } from "../../../styles/styleHelpers";

const Drawer = ({
  items,
  addItem,
  removeItem,
  full,
  isPremium,
  referralGoal,
  success,
  isConfirm,
  daysUntilNextMonth,
  referralCode,
  ...rest
}) => {
  const [email, setEmail] = useState("");
  const [hasCopied, setHasCopied] = useState(false);

  const copyLink = () => {
    var copyText = document.querySelector("#referralLink");
    copyText.select();
    document.execCommand("copy");
    setHasCopied(true);
  };

  return (
    <Container {...rest}>
      <SlideToggleContainer>
        <SlideToggle key="ref">
          {referralCode ? (
            <>
              <ReferralButton type="button" onClick={copyLink}>
                {hasCopied ? `copied!` : `copy referral link`}
              </ReferralButton>
              <ReferralLink
                aria-label="Referral link"
                id="referralLink"
                value={`${process.env.REACT_APP_API_URL}?referrer=${referralCode}`}
                readOnly
              />
            </>
          ) : null}
        </SlideToggle>
        {items.map((item) => (
          <SlideToggle key={item}>
            <DrawerItem
              fontFamily={isPremium ? undefined : item}
              small={isPremium}
            >
              <span>{item}</span>
              <DrawerItemAction
                onClick={
                  removeItem
                    ? () => {
                        removeItem(item);
                      }
                    : undefined
                }
                aria-label={`Deselect ${item}`}
              />
            </DrawerItem>
          </SlideToggle>
        ))}

        {isPremium && !success ? (
          <SlideToggle key="invite">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                addItem(email);
                setEmail("");
              }}
            >
              <DrawerInput
                placeholder={
                  items.length ? "enter another email" : "enter email"
                }
                aria-label="Invitee email"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
              />
            </form>
          </SlideToggle>
        ) : null}

        {isPremium ? (
          success ? (
            <SlideToggle key="success">
              <DrawerMessage>Your invites were sent.</DrawerMessage>
            </SlideToggle>
          ) : !items.length ? (
            <SlideToggle key="refer">
              <DrawerMessage>
                Get 10% off your membership for every unlimited member you refer
                who signs up!
              </DrawerMessage>
            </SlideToggle>
          ) : null
        ) : full ? (
          <SlideToggle key="full">
            <DrawerMessage>
              Your collection is full. Your next font will be available in{" "}
              {daysUntilNextMonth !== 1
                ? `${daysUntilNextMonth} days`
                : `${daysUntilNextMonth} day`}
              .
            </DrawerMessage>
          </SlideToggle>
        ) : !items.length ? (
          <SlideToggle key="empty">
            <DrawerMessage>
              Your drawer is empty. Select fonts to add.
            </DrawerMessage>
          </SlideToggle>
        ) : null}
      </SlideToggleContainer>
    </Container>
  );
};

// only allow the container to overflow once its Expandable parent is open.
// this prevents a janky scrollbar while its being expanded.
// 1300 = 1000ms delay + 300ms duration of the expand animation
const PosedContainer = posed.div({
  closed: {
    visibility: "visible",
    transition: { duration: 1300 },
    applyAtStart: { overflow: "hidden" },
  },
  open: {
    visibility: "visible",
    transition: { duration: 1300 },
    applyAtStart: { overflow: "hidden" },
    applyAtEnd: { overflow: "auto" },
  },
});

const Container = styled(PosedContainer)`
  background: ${(props) => props.theme.colors.white};
  border: 2px solid ${(props) => props.theme.colors.gray};
  border-top: 0;
  border-bottom: 0;
  z-index: 1;
  max-height: 33vh;
  overflow: auto;

  /* Scroll Bar */
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-width: 2px;
  }
`;

const DrawerMessage = styled.p`
  font-size: ${(props) => props.theme.fontSize_l};
  font-weight: 600;
  line-height: 1.27;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${(props) => props.theme.colors.grayDark};
  margin: 0 auto;
  padding: ${(props) => props.theme.space} 0;
  max-width: 80%;
`;

const DrawerItem = styled.div`
  font-family: ${(props) =>
    `${props.fontFamily ? `${props.fontFamily}, ` : ""} ${
      props.theme.fontFamily
    }`};
  font-size: ${(props) => (props.small ? props.theme.fontSize_l : rem("25px"))};
  font-weight: ${(props) => (props.small ? "bold" : "normal")};
  line-height: 77.5%;
  letter-spacing: 0.01em;

  border-bottom: 2px solid ${(props) => props.theme.colors.gray};
  padding: 17.5px 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  ${respondTo("xlarge")} {
    padding-top: 23px;
    padding-bottom: 23px;
  }

  span {
    /* don't let it be longer than 100% minus width of minus button and some margin in between */
    max-width: calc(100% - 24px - 5px);
    line-height: 1.25;
    overflow: hidden;
    text-overflow: ellipsis;

    /* so italic letters don't get cutoff */
    padding-right: 3px;
  }
`;

const DrawerItemAction = styled.button`
  position: relative;
  width: 24px;
  height: 24px;
  border: 2px solid ${(props) => props.theme.colors.grayDark};
  border-radius: 50%;
  margin-left: 5px;
  flex-shrink: 0;

  transition: border-color 200ms ease, background-color 200ms ease;

  &::before {
    content: "";
    width: 10px;
    height: 2px;
    background-color: ${(props) => props.theme.colors.grayDark};

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    transition: background-color 200ms ease;
  }

  &:hover,
  &:focus {
    border-color: ${(props) => props.theme.colors.negative};
    background-color: ${(props) => props.theme.colors.negative};

    &::before {
      background-color: ${(props) => props.theme.colors.white};
    }
  }
`;

const DrawerInput = styled.input`
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize_l};
  line-height: 77.5%;
  letter-spacing: 0.01em;
  padding: 20px;
  width: 100%;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray};
  color: ${(props) => props.theme.colors.black};
`;

const ReferralLink = styled.input`
  position: absolute;
  opacity: 0;
`;

const ReferralButton = styled.button`
  text-align: left;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize_l};
  line-height: 77.5%;
  letter-spacing: 0.01em;
  padding: 20px;
  width: 100%;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray};
  color: ${(props) => props.theme.colors.grayDark};
  text-decoration: underline;

  &:hover {
    color: ${(props) => props.theme.colors.blackLight};
  }
`;

export default Drawer;
