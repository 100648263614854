import styled from "styled-components";
import { rem } from "polished";

import { respondTo } from "../../../styles/styleHelpers";

const Panel = styled.div`
  display: flex;
  flex-direction: column;

  ${respondTo("xlarge")} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const PanelLeft = styled.div`
  margin-bottom: ${(props) => props.theme.space};

  ${respondTo("xlarge")} {
    flex-basis: 60%;
    margin-right: ${(props) => props.theme.space};
    margin-bottom: 0;
  }
`;

export const PanelRight = styled.div`
  width: 100%;
`;

export const PanelMessage = styled.p`
  /* font-weight: bold; */
  letter-spacing: 0.01em;
  color: ${(props) => props.theme.colors.grayDark};
  font-size: ${rem("21px")};
  line-height: 1.5;
  max-width: 370px;

  &:not(:last-child) {
    margin-top: ${(props) => props.theme.space};
    margin-bottom: 30px;

    ${respondTo("xlarge")} {
      margin-top: 63px;
    }
  }
`;

export default Panel;
