import React from "react";
import { useHistory } from "react-router-dom";

import FontSelection from "./components/FontSelection";
import Logo from "./components/Logo";
import Menu from "./components/Menu";
import CtaButton from "./components/CtaButton";
import Expandable from "./components/Expandable";

import { useFonts } from "../../context/FontsContext";
import { useAuth } from "../../context/AuthContext";

const UserSidebar = ({ closeSidebar }) => {
  const history = useHistory();
  const { user, isPremium, logout } = useAuth();
  const {
    isCollecting,
    setIsCollecting,
    fonts,
    fontsSelected,
    setFontsSelected,
    fontsOwned,
    tokens,
    emailsToInvite,
    setEmailsToInvite,
  } = useFonts();

  const handleSignOut = () => {
    logout();

    // close the sidebar (for mobile)
    if (closeSidebar) {
      closeSidebar();
    }
  };

  const deselectFont = (fontName) => {
    const thisFont = fonts.find((font) => font.name === fontName);

    const newFontsSelected = fontsSelected.filter(
      (selectedFont) => selectedFont !== thisFont._id,
    );
    setFontsSelected(newFontsSelected);
  };

  const removeEmail = (email) => {
    const newEmailsToInvite = emailsToInvite.filter(
      (invite) => invite !== email,
    );
    setEmailsToInvite(newEmailsToInvite);
  };

  const addEmail = (email) => {
    // if the email was already selected
    if (emailsToInvite.indexOf(email) === -1) {
      // use Set to prevent duplicates
      const newEmailsToInvite = Array.from(new Set([...emailsToInvite, email]));
      setEmailsToInvite(newEmailsToInvite);
    }
  };

  return (
    <>
      {user.isActive ? (
        <FontSelection
          isCollecting={isCollecting}
          toggleCollecting={() => {
            // if its a starter user and collecting mode is being toggled on and we aren't already on the /fonts route, go to it
            if (
              !isCollecting &&
              !isPremium &&
              history.location.pathname !== "/fonts"
            ) {
              history.push("/fonts");
            }

            setIsCollecting(!isCollecting);
          }}
          tokens={tokens}
          fontsOwned={fontsOwned.length}
          fontsSelected={fontsSelected}
          deselectItem={isPremium ? removeEmail : deselectFont}
          addItem={isPremium ? addEmail : undefined}
          emailsToInvite={emailsToInvite}
          isPremium={isPremium}
          referralCode={user.referralId || undefined}
        />
      ) : null}

      <Expandable pose={!isCollecting ? "open" : "closed"}>
        <Logo closeSidebar={closeSidebar} />
      </Expandable>

      <Expandable pose={!isCollecting ? "open" : "closed"}>
        <Menu
          handleSignOut={handleSignOut}
          isActive={user.isActive}
          closeSidebar={closeSidebar}
        />
      </Expandable>

      <Expandable
        pose={(isPremium && !isCollecting) || !isPremium ? "open" : "closed"}
      >
        <CtaButton
          pose={isCollecting ? "active" : "static"}
          color={isCollecting && !isPremium ? "green" : "yellow"}
          onClick={() => {
            if (user.isActive) {
              if (isPremium) {
                setIsCollecting(true);
              } else {
                history.push("/membership");
              }
            } else {
              history.push("/membership");

              // smooth scroll to the payment panel
              const paymentPanel = document.getElementById("payment");
              if (paymentPanel) {
                paymentPanel.scrollIntoView({ behavior: "smooth" });
              }
            }
          }}
        >
          {user.isActive
            ? !isPremium
              ? "Get all the fonts"
              : "Refer to a Friend"
            : "Reactivate Account"}
        </CtaButton>
      </Expandable>
    </>
  );
};

export default UserSidebar;
