import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { rem } from "polished";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import Button from "./Button";

import { useFonts } from "../../../context/FontsContext";
import { useAuth } from "../../../context/AuthContext";

const animatedComponents = makeAnimated();

const ProjectSingle = () => {
  const { fonts, fontsOwned } = useFonts();
  const { addProject } = useAuth();

  const [fontsSelected, setFontsSelected] = useState([]);
  const [whitelist, setWhitelist] = useState([]);

  const [usableFonts, setUsableFonts] = useState(fonts);

  const handleFontsChange = (selectedItems) => {
    setFontsSelected(selectedItems);
  };

  const handleWhitelistChange = (e) => {
    setWhitelist(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      let fontIds = [];

      if (fontsSelected && fontsSelected.length > 0) {
        fontIds = fontsSelected.map((item) => item.value);
      } else {
        return "Must have at least 1 font selected";
      }

      let whitelistArray = [];

      if (whitelist && whitelist.length > 0) {
        whitelistArray = whitelist.split("\n");
      } else {
        return "Must have at least 1 site whitelisted";
      }

      addProject(fontIds, whitelistArray)
        .then(() => {
          console.log("Should reset");

          setFontsSelected([]);
          setWhitelist([]);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    // Pulls out all the info for the fonts the user owns
    const adjustedFonts = fonts.filter(
      (font) => fontsOwned.indexOf(font._id) > -1,
    );

    setUsableFonts(adjustedFonts);
  }, [fonts, fontsOwned]);

  let fontOptions = [];

  usableFonts.forEach((font) => {
    fontOptions.push({ label: font.name, value: font._id });
  });

  return (
    <tr>
      <td>
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          options={fontOptions}
          onChange={handleFontsChange}
          value={fontsSelected}
          isMulti
          makeAnimated
        />
      </td>
      <td>
        <ProjectInput
          rows="4"
          type="text"
          active={true}
          disabled={false}
          onChange={handleWhitelistChange}
          value={whitelist}
        />
      </td>
      <td></td>
      <td className="center">
        <form onSubmit={handleSubmit}>
          <SmallerButton>+ Add</SmallerButton>
        </form>
      </td>
    </tr>
  );
};

const NoWrapButton = styled(Button)`
  white-space: nowrap;
`;

const ProjectInput = styled.textarea`
  width: 100%;
  min-height: 4em;
  margin-bottom: 0px;
  background-color: ${(props) => (props.active ? "white" : "transparent")};
`;

const SmallerButton = styled(NoWrapButton)`
  font-size: ${(props) => props.theme.fontSize_l};
  font-weight: 500;
  padding: 6px 10px;
  width: ${rem("115px")};
`;

export default ProjectSingle;
