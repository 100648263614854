import React from "react";
import styled from "styled-components";
import Swiper from "react-id-swiper";
import "../../../vendor/swiper/swiper.css";
import { respondTo } from "../../../styles/styleHelpers";

const Hero = ({ images }) => {
  const params = {
    effect: "fade",
    speed: 1500,
    watchSlidesProgress: true,
    allowTouchMove: false,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
  };

  return (
    <HeroContainer>
      <Swiper {...params}>
        {images.map((image, index) => (
          <Slide key={index}>
            <SlideImage
              style={{ backgroundImage: `url(${image})` }}
            ></SlideImage>
          </Slide>
        ))}
      </Swiper>
    </HeroContainer>
  );
};

const HeroContainer = styled.div`
  margin-top: 54px;

  .swiper-pagination {
    position: static;
    text-align: left;
    margin-top: 58px;
    margin-bottom: 123px;
    margin-left: 80px;
    display: none;
  }

  .swiper-pagination-bullet {
    width: 18px;
    height: 2px;
    background-color: ${(props) => props.theme.colors.pumice};
    border-radius: 0px;
    opacity: 1;
    transition: 1s ease-in-out;
  }

  .swiper-pagination-bullet-active {
    width: 110px;
    position: relative;

    &::before {
      position: absolute;
      display: block;
      content: "";
      height: 2px;
      width: 0%;
      left: 0;
      top: 0;
      transition: 0.1s linear;
      animation: bullet 6s;
      animation-delay: 1s;
      background-color: ${(props) => props.theme.colors.grayDark};
    }
  }

  @keyframes bullet {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  ${respondTo("large")} {
    margin-top: 0px;

    .swiper-pagination {
      display: inline-flex;
      position: static;
      text-align: left;
      margin-top: 34px;
      margin-bottom: 26px;
      margin-left: 29px;
    }

    .swiper-pagination-bullet {
      width: 9px;
    }

    .swiper-pagination-bullet-active {
      width: 86px;
    }
  }

  ${respondTo("xlarge")} {
    .swiper-pagination {
      margin-top: 58px;
      margin-bottom: 123px;
      margin-left: 80px;
    }

    .swiper-pagination-bullet {
      width: 18px;
    }

    .swiper-pagination-bullet-active {
      width: 161px;
    }
  }
`;

const Slide = styled.div`
  position: relative;
  height: 0px;
  padding-bottom: 57%;
  overflow: hidden;
  border-radius: 0px 0px 0px 15px;

  ${respondTo("medium")} {
    padding-bottom: 45%;
  }

  ${respondTo("large")} {
    height: 87.5vh;
    min-height: 631px;
    max-height: 857px;
    width: 100%;
    padding-bottom: 0px;
  }

  ${respondTo("xlarge")} {
    height: 78.5vh;
    max-height: none;
  }
`;

const SlideImage = styled.div`
  /* transition: 8s ease-out; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center top;
  background-size: 100% auto;
  background-repeat: repeat;
`;

export default Hero;
