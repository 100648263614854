import React from "react";
import { Route, Redirect } from "react-router-dom";

import { useAuth } from "../context/AuthContext";

const PrivateRoute = ({ render: Component, activeOnly, ...rest }) => {
  const { user, authenticating } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => {
        // logged in
        if (user) {
          // if user is inactive and this route can only be accessed by active members
          if (activeOnly && !user.isActive) {
            // redirect to membership page
            return (
              <Redirect
                to={{
                  pathname: "/membership",
                  state: { from: props.location },
                }}
              />
            );
          } else {
            // user is logged in and may access this page
            return <Component {...props} />;
          }
        } else if (!authenticating) {
          // authentication is complete and there is no logged in user, so redirect to marketing site
          // (must wait until authentication is complete, otherwise everyone would be redirected to marketing site whenever they refresh the page)
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default PrivateRoute;
