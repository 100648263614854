import styled, { keyframes } from "styled-components";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  width: ${(props) => props.theme.space};
  height: ${(props) => props.theme.space};
  border: 3px solid
    ${(props) =>
      props.color ? props.theme.colors[props.color] : props.theme.colors.white};
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-radius: 50%;
  margin: 0 auto;
  animation: ${spin} 600ms linear infinite forwards;
`;

export default Spinner;
