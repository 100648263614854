import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { respondTo } from "../../../styles/styleHelpers";

// TODO: this could be made more accessible

const Accordion = (props) => {
  const { active, title, onClick, children } = props;

  const content = useRef(null);

  const [contentHeight, setContentHeight] = useState("0px");

  useEffect(() => {
    if (content) {
      setContentHeight(`${content.current.scrollHeight}px`);
    }
  }, [content]);

  return (
    <AccordionItem active={active} onClick={onClick ? onClick : ""}>
      <AccordionTitle>{title}</AccordionTitle>

      <AccordionDescription
        ref={content}
        contentHeight={contentHeight}
        active={active}
      >
        {children}
      </AccordionDescription>
    </AccordionItem>
  );
};

const AccordionItem = styled.div`
  padding: 15px;
  margin-bottom: 20px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0px;
  }

  flex-grow: ${(props) => (props.active ? "1" : "")};

  &:hover {
    box-shadow: ${(props) => props.theme.shadow};
  }

  ${respondTo("xlarge")} {
    padding: 15px 54px;
  }
`;

const AccordionTitle = styled.h3`
  font-size: ${(props) => props.theme.fontSize_l};
  line-height: 23px;
  font-weight: bold;
  letter-spacing: 0.02em;
  margin-bottom: 0px;
  color: ${(props) => props.theme.colors.grayDark};

  ${respondTo("xlarge")} {
    font-size: 24px;
    line-height: 31px;
  }
`;

const AccordionDescription = styled.p`
  font-size: ${(props) => props.theme.fontSize_xs};
  line-height: 15px;
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 0px;
  transition: 0.3s ease-in-out;
  overflow: hidden;
  max-height: ${(props) => (props.active ? props.contentHeight : "0px")};
  margin-top: ${(props) => (props.active ? "22px" : "0px")};

  ${respondTo("xlarge")} {
    font-size: ${(props) => props.theme.fontSize_l};
    line-height: 24px;
  }
`;

export default Accordion;
