import React from "react";

const LogoText = () => {
  return (
    <svg
      width="105"
      height="35"
      viewBox="0 0 105 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9333 0C28.0583 0 33.0167 5.0549 33.0167 11.2331V23.7373C33.0167 29.9451 28.0292 34.9704 21.9333 34.9704H13.5042C12.8042 34.9704 12.2792 34.4088 12.2792 33.7584V26.1318C12.2792 25.4814 12.8333 24.9198 13.5042 24.9198H19.5417C20.475 24.9198 21.3208 24.565 21.9625 23.9738C22.05 23.9147 22.1083 23.826 22.1958 23.7373C22.7792 23.087 23.1292 22.2297 23.1292 21.2838V13.6867C23.1292 12.7407 22.7792 11.8834 22.1958 11.2331C22.1083 11.1444 22.05 11.0262 21.9625 10.9966C21.2917 10.4054 20.475 10.0507 19.5417 10.0507H11.0833C10.4417 10.0507 9.91667 10.5828 9.91667 11.2331V33.788C9.91667 34.4383 9.3625 35 8.72083 35H1.19583C0.554167 35 0 34.4383 0 33.788V1.21199C0 0.561655 0.554167 0 1.19583 0H21.9333Z"
        fill="#838B91"
      />
      <path
        d="M45.8791 11.2331C45.8791 11.5878 46.0249 11.8834 46.2874 12.0904C46.4916 12.3269 46.7833 12.5042 47.1333 12.5042H67.8416C68.5416 12.5042 69.0374 13.0068 69.0374 13.7162V21.2838C69.0374 21.9932 68.5416 22.4958 67.8416 22.4958H47.1333C46.4333 22.4958 45.9374 23.0574 45.9083 23.7373V33.788C45.9083 34.4383 45.4124 35 44.7124 35H37.2166C36.5166 35 36.0208 34.4383 36.0208 33.788V11.2331C36.0208 5.02534 40.9499 0 47.1041 0H67.8124C68.5124 0 69.0083 0.561655 69.0083 1.21199V8.86824C69.0083 9.51858 68.5124 10.0802 67.8124 10.0802H47.1041C46.4041 10.0507 45.8791 10.5828 45.8791 11.2331Z"
        fill="#838B91"
      />
      <path
        d="M103.804 0C104.504 0 105 0.561655 105 1.21199V8.86824C105 9.51858 104.504 10.0802 103.804 10.0802H85.4583C84.5833 10.0802 83.7374 10.435 83.0958 11.0262C83.0083 11.0853 82.8916 11.174 82.8041 11.2627C82.2499 11.913 81.8708 12.7703 81.8708 13.7162V21.2838C81.8708 22.2297 82.2791 23.087 82.8041 23.7373C82.8916 23.826 83.0083 23.9443 83.0958 23.9738C83.7374 24.565 84.5833 24.9198 85.4583 24.9198H103.804C104.504 24.9198 105 25.4814 105 26.1318V33.788C105 34.4383 104.504 35 103.804 35H83.0958C76.9416 35 71.9541 29.9451 71.9541 23.7669V11.2331C71.9541 5.02534 76.9416 0 83.0958 0H103.804Z"
        fill="#838B91"
      />
    </svg>
  );
};

export default LogoText;
